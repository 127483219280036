import React from 'react';

export const StripeIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>stripe-s</title>
    <g id="stripe-s" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fill="#6856FF" x="0" y="0" width="24" height="24" rx="3"></rect>
      <path d="M17.2613333,14.786063 C17.2613333,17.7578045 14.9499788,19.4487954 11.5279735,19.4487954 C9.99239157,19.447635 8.47343206,19.1309013 7.06535832,18.5182501 L7.06535832,14.5859457 C8.4461675,15.3363855 10.167176,15.8967138 11.5279735,15.8967138 C12.4485129,15.8967138 13.0588706,15.6565731 13.0588706,14.8961275 C13.0588706,12.9149665 6.7952,13.6554004 6.7952,9.09272658 C6.7952,6.18102027 9.0765369,4.44 12.4184953,4.44 C13.7792928,4.44 15.1400902,4.64011727 16.5108936,5.19043977 L16.5108936,9.07271485 C15.2441063,8.40600932 13.8396766,8.04291287 12.4084895,8.01209331 C11.5479852,8.01209331 10.9676451,8.2622399 10.9676451,8.91262103 C10.9676451,10.7637058 17.2613333,9.8831898 17.2613333,14.7960688 L17.2613333,14.786063 Z" id="Path" fill="#FFFFFF"></path>
    </g>
  </svg>
)
