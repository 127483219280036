import { Box, CircularProgress, Typography } from "@mui/material";
import React from 'react';

import { useStyles } from './styles';

const SummaryBox = ({ summary, info, infoLight, prices, errorMessage, warningMessage }) => {
  const classes = useStyles();

  if (errorMessage) {
    return (
      <Box className={classes.summaryBoxError}>
        <Typography>
          <i>
            {errorMessage.replace('[GraphQL] ', '')}
          </i>
        </Typography>
      </Box>
    )
  }

  return (
    <>
      {warningMessage && (
        <Box className={classes.summaryBoxWarning}>
          {warningMessage}
        </Box>
      )}
      <Box className={classes.summaryBox}>
        {summary && (
          <Typography gutterBottom className={classes.summaryPriceHeadline}>
            <span>
              {summary}
            </span>
          </Typography>
        )}
        {prices.map(price => price && (
          <Typography key={price.title} gutterBottom className={classes.summaryPriceHeadline}>
            <span>
              {price.title}
            </span>
            {price.fetching && (
              <CircularProgress size={20} style={{display: 'block', marginLeft: 'auto'}} />
            )}
            {!price.fetching && (
              <Typography component={'span'} className={classes.summaryPrice} color={price.isRefund ? 'error' : null}>
                {price.amount
                  ? `${price.amount.toFixed(2)
                    .replace('.', ',')} €`
                  : '0,00 €'
                }
                {price.otherDirectionPriceAmount ? (
                  <Box component={'span'} style={{display: 'block', fontWeight: 'normal'}}>
                    {price.otherDirectionTitle}
                    {price.otherDirectionPriceAmount.toFixed(2).replace('.', ',')} €
                  </Box>
                ) : null}
              </Typography>
            )}
          </Typography>
        ))}

        <Typography className={infoLight ? classes.infoLight : ''}>
          <i>
            {info}
          </i>
        </Typography>
      </Box>
    </>
  );
}

SummaryBox.propTypes = {}

export default SummaryBox;
