import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React from 'react';
import DatePicker from "./components/DatePicker";
import Destinations from "./components/Destinations";

const TrainConnectionEdit = ({ ticket, hasReturnConnection, date, setDate, returnDate, setReturnDate, availableDates, availableReturnDates, changeDirection, setChangeDirection }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h2'} gutterBottom>
          <strong>
            Gebuchte Verbindung ändern
          </strong>
        </Typography>
      </Grid>

      {/* TODO: implement station change */}
      <Destinations
        ticket={ticket}
        hasReturnConnection={hasReturnConnection}
        changeDirection={changeDirection}
        setChangeDirection={setChangeDirection}
      />

      <Grid item xs={12}>
        <DatePicker
          minDate={moment()}
          date={date}
          setDate={setDate}
          availableDates={availableDates}
        />
      </Grid>

      {hasReturnConnection && (
        <Grid item xs={12}>
          <DatePicker
            isReturn
            minDate={moment()}
            date={returnDate}
            setDate={setReturnDate}
            availableDates={availableReturnDates}
          />
        </Grid>
      )}
    </>
  );
}

TrainConnectionEdit.propTypes = {}

export default TrainConnectionEdit;
