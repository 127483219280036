import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    flexDirection: 'column',
    padding: '20px 0'
  },
  input: {
    margin: '10px auto'
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  resetLink: {
    display: 'inline-block',
    marginTop: '5px',
    color: theme.palette.primary.main,
    textDecoration: "none"
  }
}));
