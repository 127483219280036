import PropTypes from 'prop-types';
import React from 'react';

import Destination from './Destinations';

const DestinationProvider = ({ origin, destination, departureTime, arrivalTime, isToday }) => (
  <Destination
    origin={origin}
    destination={destination}
    departureTime={departureTime}
    arrivalTime={arrivalTime}
    isToday={isToday}
  />
);

DestinationProvider.propTypes = {
  origin: PropTypes.string,
  destination: PropTypes.string,
  departureTime: PropTypes.string,
  arrivalTime: PropTypes.string,
  isToday: PropTypes.bool
};

export default DestinationProvider;
