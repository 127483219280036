import { TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'

import { useStyles } from './styles'

const UnplacedEntityItem = () => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell component="th" scope="row" colSpan={3} className={classes.cell}>
        <Typography component={'div'} variant={'h4'} className={classes.text} sx={{fontStyle: 'italic'}}>
          Kein Platz zugewiesen
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default UnplacedEntityItem
