import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { CUSTOMER_STATUS_OPTIONS, DASHBOARDAPP_VIEW_TICKET_CONTACT_DATA, LOCALE_MAPPING } from '../../../../constants';
import { usePermissions } from '../../../Login/LoginContext';

import ContactDetails from './ContactDetails';
// import { useStyles } from './styles';

const Contact = ({ firstname, lastname, email, phone, street, zip, city, country, memberships, customerStatus, customerLocale, customerTcsMemberNumber }) => {
  // const classes = useStyles();
  const { canAccess } = usePermissions() || {}

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h3'}>
          {firstname || lastname ? `${firstname} ${lastname}` : <Skeleton />}
        </Typography>
      </Grid>
      {canAccess(DASHBOARDAPP_VIEW_TICKET_CONTACT_DATA) && (
        <ContactDetails
          email={email}
          phone={phone}
          street={street}
          zip={zip}
          city={city}
          country={country}
          customerStatus={CUSTOMER_STATUS_OPTIONS.find(item => item.key === customerStatus)?.title || '-'}
        />
      )}

      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>
          Sprache
        </Typography>
        <Typography variant={'body1'}>
          {LOCALE_MAPPING[customerLocale]}
        </Typography>
      </Grid>

      {memberships?.length > 0 &&
        canAccess(DASHBOARDAPP_VIEW_TICKET_CONTACT_DATA) && (
          <Grid item xs={6}>
            {memberships.map(membership => (
              <div key={membership.ID}>
                <Typography variant={'subtitle1'}>
                  {membership.Title}
                </Typography>
                <Typography variant={'body1'}>
                  {membership.Payload}
                </Typography>
              </div>
            ))}
          </Grid>
        )
      }

      {customerTcsMemberNumber?.length > 0 &&
        canAccess(DASHBOARDAPP_VIEW_TICKET_CONTACT_DATA) && (
          <Grid item xs={6}>
            <Typography variant={'subtitle1'}>
              TCS-Nummer
            </Typography>
            <Typography variant={'body1'}>
              {<div>{customerTcsMemberNumber}</div>}
            </Typography>
          </Grid>
        )
      }
    </Grid>
  );
};

Contact.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
};

export default Contact;
