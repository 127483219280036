import { Undo } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { readMicroactionMaximumPersons, readMicroactionPriceCategoriesQuery } from '../../../../../lib/GraphQLQueries';
import { useStyles } from '../styles';

const Cabin = ({ entityGroup, entityTypes, changeCabin, deleteCabin, maximumTrainCabinPersons, initiallyOpen, isBlocked }) => {
  const [open, setOpen] = useState(!entityGroup.IsExisting || initiallyOpen)
  const classes = useStyles()

  const [priceCategoryResult] = useQuery({
    query: readMicroactionPriceCategoriesQuery,
    variables: {
      TicketNumber: entityGroup.TicketNumber,
      RequestID: entityGroup.BookingCollectionID,
      AdultsAmount: entityGroup.AdultsAmount,
      ChildrenAmount: entityGroup.ChildrenAmount,
      EntityTypeHashID: entityGroup.EntityTypeID,
      IsCabinBooking: entityGroup.CabinBooking === 'Cabin'
    },
    pause: entityGroup.EntityTypeID === ''
      || entityGroup.CabinBooking === ''
      || entityGroup.AdultsAmount === null
      || entityGroup.ChildrenAmount === null
  })

  const [maximumEntityPersonsResult] = useQuery({
    query: readMicroactionMaximumPersons,
    variables: {
      TicketNumber: entityGroup.TicketNumber,
      EntityType: entityGroup.EntityTypeID
    },
    pause: !entityGroup.EntityTypeID
  })

  const maxPersons = useMemo(() => {
    return maximumEntityPersonsResult.data?.readMicroactionMaximumPersons || 0
  }, [maximumEntityPersonsResult.data])

  const bookingTypeOptions = useMemo(() => {
    const type = entityTypes.find(item => item.ID === entityGroup.EntityTypeID)

    return type?.BookingOptions || []
  }, [entityGroup, entityTypes])

  const selectedPriceCategoryExists = useMemo(() => {
    if (entityGroup.CabinBooking === '') {
      return false;
    }

    return entityGroup.PriceCategoryID === ''
      || priceCategoryResult.data?.readMicroactionPriceCategories?.find(item => {
        return parseInt(item.ID, 10) === parseInt(entityGroup.PriceCategoryID, 10)
      }) !== undefined
  }, [entityGroup, priceCategoryResult.data])

  // set initial enity type
  //useEffect(() => {
  //   if (!entityGroup.IsExisting && typeof entityGroup.EntityTypeID === 'undefined') {
  //     changeCabin('EntityTypeID', entityGroup.BookingCollectionID, entityTypes?.length > 0 ? entityTypes[0].ID : '')
  //   }
  // })

  useEffect(() => {
    if (priceCategoryResult.data && !selectedPriceCategoryExists) {
      changeCabin('PriceCategoryID', entityGroup.BookingCollectionID, '')
    }
  }, [changeCabin, selectedPriceCategoryExists, priceCategoryResult.data, entityGroup])

  const totalPersonsArray = useMemo(() => new Array(maximumTrainCabinPersons).fill(1), [maximumTrainCabinPersons])

  if (!priceCategoryResult.data) {
    // return <CircularProgress />
  }

  return (
    <div className={classes.vehicleDivider}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton onClick={() => setOpen(prev => !prev)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span>
              {
                entityGroup.IsExisting
                  ? `Wagen ${entityGroup.WagonNumber} | Abteil ${entityGroup.CabinNumber}`
                  : 'Neues Abteil'
              }
            </span>
            {!entityGroup.IsExisting && (
              <IconButton sx={{marginLeft: 'auto'}} onClick={() => deleteCabin(entityGroup.BookingCollectionID)}>
                <Undo />
              </IconButton>
            )}
          </Box>
        </Grid>
        {open && (
          <>
            <Grid item xs={12} sm={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id={`entitytype-label`}>{'Platzart'}</InputLabel>
                <Select
                  labelId={`entitytype-label`}
                  value={entityGroup.EntityTypeID || ''}
                  disabled={isBlocked && entityGroup.IsExisting}
                  onChange={e => changeCabin('EntityTypeID' , entityGroup.BookingCollectionID, e.target.value)}
                  error={typeof entityGroup.EntityTypeID === 'undefined'}
                >
                  <MenuItem>{'Bitte wählen...'}</MenuItem>
                  {entityTypes.map(entityType => (
                    <MenuItem key={entityType.ID} value={entityType.ID}>{entityType.Title}</MenuItem>
                  ))}

                </Select>
                {entityGroup.IsExisting && (
                  <FormHelperText>Vorher: {entityGroup.Original.EntityType}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id={`adults-label`}>{'Erwachsene'}</InputLabel>
                <Select
                  labelId={`adults-label`}
                  value={entityGroup.AdultsAmount || ''}
                  disabled={isBlocked && entityGroup.IsExisting}
                  onChange={e => changeCabin('AdultsAmount' , entityGroup.BookingCollectionID, e.target.value)}
                  error={entityGroup.AdultsAmount === null || (maximumEntityPersonsResult.data && entityGroup.AdultsAmount + entityGroup.ChildrenAmount > maxPersons)}
                >
                  <MenuItem>{'Bitte wählen...'}</MenuItem>
                  {totalPersonsArray.map((v, i) => (
                    <MenuItem
                      disabled={
                        (i + 1) > maxPersons - entityGroup.ChildrenAmount
                        || (
                          entityGroup.CabinBooking !== 'Cabin' && (
                            i + 1 < entityGroup.Original.AdultsAmount
                          )
                        )
                      }
                      value={i + 1} key={i}
                    >
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
                {entityGroup.IsExisting && (
                  <FormHelperText>Vorher: {entityGroup.Original.AdultsAmount}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id={`children-label`}>{'Kinder'}</InputLabel>
                <Select
                  labelId={`children-label`}
                  value={entityGroup.ChildrenAmount !== null ? entityGroup.ChildrenAmount : ''}
                  disabled={isBlocked && entityGroup.IsExisting}
                  onChange={e => changeCabin('ChildrenAmount' , entityGroup.BookingCollectionID, e.target.value)}
                  error={entityGroup.ChildrenAmount === null || (maximumEntityPersonsResult.data && entityGroup.AdultsAmount + entityGroup.ChildrenAmount > maxPersons)}
                >
                  <MenuItem>{'Bitte wählen...'}</MenuItem>
                  <MenuItem
                    value={0}
                    disabled={Object.keys(entityGroup.Original).length > 0 && (entityGroup.Original.ChildrenAmount !== 0 || entityGroup.CabinBooking === 'Cabin')}
                  >0</MenuItem>
                  {totalPersonsArray.map((v, i) => {
                    if (i + 1 === totalPersonsArray.length) {
                      return null
                    }

                    return (
                      <MenuItem
                        disabled={
                          (i + 1) > maxPersons - entityGroup.AdultsAmount
                          || (
                            entityGroup.CabinBooking !== 'Cabin' && (
                              i + 1 < entityGroup.Original.ChildrenAmount
                            )
                          )
                        }
                        value={i + 1} key={i}
                      >
                        {i + 1}
                      </MenuItem>
                    )
                  })}
                </Select>
                {entityGroup.IsExisting && (
                  <FormHelperText>Vorher: {entityGroup.Original.ChildrenAmount}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {entityGroup.CabinBooking !== 'Cabin'
              && (
                entityGroup.AdultsAmount < entityGroup.Original.AdultsAmount || entityGroup.ChildrenAmount < entityGroup.Original.ChildrenAmount
              )
              && (
                <Grid item xs={12} sm={12}>
                  <Typography color={'error'} fontSize={14} gutterBottom>
                    <strong>Fehler:</strong>
                    {`Die ausgewählte Personenanzahl überschreitet die maximal zulässige Anzahl von ${maxPersons} Einheiten für die gewählte Platzart.`}
                  </Typography>
                </Grid>
              )
            }

            {entityGroup.AdultsAmount + entityGroup.ChildrenAmount > maxPersons && (
              <Grid item xs={12} sm={12}>
                <Typography color={'error'} fontSize={14} gutterBottom>
                  <strong>Fehler:</strong>
                  {`Die ausgewählte Personenanzahl überschreitet die maximal zulässige Anzahl von ${maxPersons} Einheiten für die gewählte Platzart.`}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id={`cabinbooking-label`}>{'Einzelplatz / Abteil'}</InputLabel>
                <Select
                  error={entityGroup.CabinBooking === ''}
                  labelId={`cabinbooking-label`}
                  value={entityGroup.CabinBooking}
                  disabled={isBlocked && entityGroup.IsExisting}
                  onChange={e => changeCabin('CabinBooking' , entityGroup.BookingCollectionID, e.target.value)}
                >
                  {bookingTypeOptions.length > 0 ? (
                    <MenuItem value={''}>{'Bitte wählen...'}</MenuItem>
                  ) : (
                    <MenuItem value={''}>{'Nicht verfügbar'}</MenuItem>
                  )}
                  {bookingTypeOptions.map(option => (
                    <MenuItem key={option.Code} value={option.Code}>{option.Title}</MenuItem>
                  ))}
                </Select>
                {entityGroup.IsExisting && (
                  <FormHelperText>Vorher: {entityGroup.Original.IsCabinBooking ? 'Abteilbuchung' : 'Einzelplatzbuchung'}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              {!priceCategoryResult.fetching && !priceCategoryResult.stale ? (
                <FormControl variant="filled" fullWidth>
                  <InputLabel id={`pricecategory-label`}>{'Preiskategorie'}</InputLabel>
                  <Select
                    error={entityGroup.PriceCategoryID === ''}
                    labelId={`pricecategory-label`}
                    value={entityGroup.PriceCategoryID || ''}
                    disabled={isBlocked && entityGroup.IsExisting}
                    onChange={e => changeCabin('PriceCategoryID', entityGroup.BookingCollectionID, e.target.value)}
                  >
                    {(priceCategoryResult.data?.readMicroactionPriceCategories || []).length > 0 ? (
                      <MenuItem value={''}>{'Bitte wählen...'}</MenuItem>
                    ) : (
                      <MenuItem value={''}>{'Nicht verfügbar'}</MenuItem>
                    )}
                    {(priceCategoryResult.data?.readMicroactionPriceCategories || []).map(priceCategory => (
                      <MenuItem key={priceCategory.ID} value={priceCategory.ID}>
                        {`${priceCategory.Title} | Neuer Preis: ${entityGroup.CabinBooking === 'Cabin' ? priceCategory.Price.Amount : priceCategory.SinglePrice.Amount}€`}
                      </MenuItem>
                    ))}
                  </Select>
                  {entityGroup.IsExisting && (
                    <FormHelperText>Vorher: {entityGroup.Original.PriceCategory}</FormHelperText>
                  )}
                </FormControl>
              ) : (
                <CircularProgress/>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

Cabin.propTypes = {}

export default Cabin;
