import PropTypes from 'prop-types';
import React from 'react';
import FeeValue from './FeeValue';

const FeeValueProvider = ({ fee, isRefund, status }) => (
  <FeeValue
    fee={fee}
    isRefund={isRefund}
    status={status}
  />
);

FeeValueProvider.propTypes = {
  fee: PropTypes.string,
  isRefund: PropTypes.bool,
  status: PropTypes.string
};

export default FeeValueProvider;
