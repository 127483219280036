import React, { useEffect, useState } from 'react';
import { useMutation } from 'urql';

import { resendTicketEmailMutation } from '../../../../lib/GraphQLQueries'
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'
import ResendTicketEmail from './ResendTicketEmail'

const ResendTicketEmailProvider = ({ ticket, reloadTicket }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendAwayConfirmation, setSendAwayConfirmation] = useState(true);
  const [sendReturnConfirmation, setSendReturnConfirmation] = useState(true);

  const [state, resendTicketEmail] = useMutation(resendTicketEmailMutation)
  const { pushSuccess, pushError } = useNotificationPush()

  useEffect(() => {
    if (dialogOpen) {
      reloadTicket()
    }
  }, [dialogOpen, reloadTicket]);

  const confirmResendTicketEmail = () => {
    resendTicketEmail({
      TicketNumber: ticket.TicketNumber,
      SendAwayConfirmation: sendAwayConfirmation,
      SendReturnConfirmation: sendReturnConfirmation
    }).then(res => {
      if (!res.error) {
        pushSuccess('Ticket-Email versendet')
        setDialogOpen(false)
      } else {
        pushError(res.error.message)
      }
    })
  }

  useEffect(() => {
    if (dialogOpen) {
      setSendAwayConfirmation(true)
      setSendReturnConfirmation(true)
    }
  }, [dialogOpen])

  return (
    <ResendTicketEmail
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      sendAwayConfirmation={sendAwayConfirmation}
      setSendAwayConfirmation={setSendAwayConfirmation}
      sendReturnConfirmation={sendReturnConfirmation}
      setSendReturnConfirmation={setSendReturnConfirmation}
      confirmResendTicketEmail={confirmResendTicketEmail}
      emailPending={ticket?.EmailPending || false}
      pendingInvoiceItems={ticket?.PendingInvoiceItems || []}
      fetching={state.fetching}
      hasOppositeDirectionTicket={ticket.OppositeDirectionTicket?.TicketNumber !== undefined || false}
    />
  );
}

ResendTicketEmailProvider.propTypes = {}

export default ResendTicketEmailProvider;
