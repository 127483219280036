import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ListIcon from '@mui/icons-material/List';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box, CircularProgress, Hidden, Typography, Dialog, Button, useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { Suspense, useMemo } from 'react';
import { DASHBOARDAPP_EDIT_ALL_TRAINS, DASHBOARDAPP_VIEW_FEEDBACK, DASHBOARDAPP_VIEW_TRAINOVERVIEW } from '../constants'
import { usePermissions } from "../features/Login/LoginContext";
import { useServiceWorker } from "../features/ServiceWorker";
import { useStyles } from './styles'
import DesktopView from './view/DesktopView'
import MobileView from './view/MobileView'

type PropTypes = {
  children?: React.ReactNode
  logoUrl: string
}

/**
 * Container component for Layout feature
 *
 * @param {*} props
 */
const Layout = ({ logoUrl, children }: PropTypes) => {
  const classes = useStyles();
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  const { canAccess } = usePermissions() || {}
  const isMobile = useMediaQuery(useTheme().breakpoints.down('lg'));

  const navigationList = useMemo(() => ([
    {
      link: '/zuege/',
      title: 'Liste',
      icon: <ListIcon />
    },
    {
      link: '/checkin/',
      title: 'Check-In',
      icon: <CropFreeIcon />
    },
    {
      link: '/suche/',
      title: 'Suchen',
      hideOnDesktop: true,
      icon: <SearchIcon />
    },
  ]), [])

  const subNavigationList = useMemo(() => ([
    {
      link: '/feedback/',
      title: 'Feedback-Statistiken',
      desktopTitle: 'Feedback',
      permission: DASHBOARDAPP_VIEW_FEEDBACK,
      icon: <RateReviewOutlinedIcon />
    },
    {
      link: '/zuguebersicht/',
      title: 'Zug-Übersicht',
      desktopTitle: 'Zug Übersicht',
      permission: DASHBOARDAPP_VIEW_TRAINOVERVIEW,
      icon: <TuneOutlinedIcon />
    },
    {
      link: '/zug-verwaltung/',
      title: 'Zugpersonalverwaltung',
      desktopTitle: 'Verwaltung',
      permission: DASHBOARDAPP_EDIT_ALL_TRAINS,
      icon: <SupervisorAccountOutlinedIcon />
    },
    {
      priority: true,
      link: isMobile ? '/letzte-tickets/' : '/',
      title: 'Letzte Tickets ARZ',
      desktopTitle: 'Tickets',
      permission: DASHBOARDAPP_EDIT_ALL_TRAINS,
      icon: <ConfirmationNumberOutlinedIcon />,
    },
    {
      link: '/export/',
      title: 'Export',
      desktopTitle: 'Export',
      permission: DASHBOARDAPP_EDIT_ALL_TRAINS,
      icon: <FileDownloadOutlinedIcon />,
    },
  ]), [isMobile])

  const Loading = useMemo(() => (
    <Box className={classes.loading}>
      <CircularProgress />
      <Typography>
        Wird geladen...
      </Typography>
    </Box>
  ), [classes])

  const filteredList = useMemo(() => {
    return subNavigationList.filter(item => {
      return canAccess && canAccess(item.permission)
    })
  }, [subNavigationList, canAccess])

  return (
    <>
      {/*{isUpdateAvailable && (*/}
      {/*  <SnackbarContent*/}
      {/*    message={'Eine neue Version ist verfügbar'}*/}
      {/*    action={*/}
      {/*      <Button size={'small'} onClick={updateAssets}>Jetzt aktualisieren</Button>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      {isUpdateAvailable && updateAssets && (
        <Dialog open>
          <Typography sx={{padding: 4}}>
            Die Anwendung wurde aktualisiert! Klicke jetzt auf "Jetzt aktualisieren", um die neusten Funktionen und Fehlerbehebungen zu erhalten.
            Anschließend wird die Seite neu geladen und die Applikation kann weiter wie gewohnt genutzt werden.
          </Typography>
          <Button sx={{borderRadius: 0}} onClick={() => updateAssets && updateAssets()}>Jetzt aktualisieren</Button>
        </Dialog>
      )}
      <Hidden lgUp>
        <MobileView
          classes={classes}
          logoUrl={logoUrl}
          navigationList={navigationList}
          subNavigationList={filteredList}
        >
          <Suspense fallback={Loading}>
            {children}
          </Suspense>
        </MobileView>
      </Hidden>
      <Hidden lgDown>
        <DesktopView
          logoUrl={logoUrl}
          navigationList={[...navigationList, ...filteredList]}
        >
          <Suspense fallback={Loading}>
            {children}
          </Suspense>
        </DesktopView>
      </Hidden>
    </>
  );
};

export default Layout;
