import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  textfield: {
    backgroundColor: '#F2F5F7',
    borderRadius: '5px',
    overflow: 'hidden',
    '&>label': {
      fontSize: 16,
      lineHeight: 22 / 16,
      transform: 'translate(16px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(16px, 6px) scale(0.75)'
      }
    },
    '&>div': {
      borderRadius: '5px',
      backgroundColor: 'transparent',
      paddingRight: theme.spacing(1),
      '&::before': {
        borderColor: 'transparent'
      },
      '&:hover': {
        borderColor: 'transparent',
        '&::before': {
          borderColor: 'transparent'
        },
      },
      '&>input': {
        height: 46,
        paddingTop: 10,
        paddingRight: theme.spacing(2),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2)
      }
    }
  }
}));
