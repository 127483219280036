import PropTypes from 'prop-types';
import React from 'react';
import { useDialog } from '../../../TicketDetails/components/CategoryBox';
import ServiceValues from '../../index';
import { ServiceValuesContextWrapper } from '../../ServiceValuesContext';

const ServiceValuesEditProvider = ({ ticketNumber }) => {
  const { onClose } = useDialog();

  return (<ServiceValuesContextWrapper>
    <ServiceValues
      ticketNumber={ticketNumber}
      allowCancel
      closeDialog={onClose}
    />
  </ServiceValuesContextWrapper>);
};

ServiceValuesEditProvider.propTypes = {
  ticketNumber: PropTypes.string
};

export default ServiceValuesEditProvider;
