import AccessibleIcon from '@mui/icons-material/Accessible';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { useNotificationPush } from '../../features/Snackbar/SnackbarContext'
import {
  moveBookingCollectionMutation,
  readAllAvailableEntities,
  readAvailableEntities, readSwitchableBookingsQuery, switchBookingCollectionMutation
} from '../../lib/GraphQLQueries'

import TicketMove from './TicketMove'

const TicketMoveProvider = ({ ticket, collectionID, handleBack }) => {
  const [switchMode, setSwitchMode] = useState(false)
  const [sameEntity, setSameEntity] = useState(true)
  const [blockCabin, setBlockCabin] = useState(false)
  const [blockReason, setBlockReason] = useState('')
  const [selectedCabin, setSelectedCabin] = useState('')
  const [selectedSwitchCollection, setSelectedSwitchCollection] = useState('')

  const { pushSuccess, pushError } = useNotificationPush()

  const [availableEntities] = useQuery({
    query: sameEntity ? readAvailableEntities : readAllAvailableEntities,
    variables: {
      Ticketnumber: ticket.TicketNumber,
      CollectionID: collectionID
    },
    pause: !ticket.TicketNumber || !collectionID || switchMode,
    requestPolicy: 'network-only'
  })

  const [switchableBookingCollections] = useQuery({
    query: readSwitchableBookingsQuery,
    variables: {
      TicketNumber: ticket.TicketNumber,
      CollectionID: collectionID,
      SameEntity: sameEntity
    },
    pause: !ticket.TicketNumber || !collectionID || !switchMode,
    requestPolicy: 'network-only'
  })

  const [, moveBookingCollection] = useMutation(moveBookingCollectionMutation)
  const [, switchBookingCollection] = useMutation(switchBookingCollectionMutation)

  const currentCabin = useMemo(() => {
    const collection = ticket.EntityGroups.find(group => group.BookingCollectionID === collectionID)

    if (!collection || !collection.Entities) {
      return {
        key: '',
        value: ''
      }
    }

    const lastEntity = collection.Entities[collection.Entities.length - 1]
    const firstEntity = collection.Entities[0]

    let entityString = '';
    if (!sameEntity) {
      entityString = `${collection.EntityType} `
    }
    entityString += firstEntity !== lastEntity ? `${firstEntity} - ${lastEntity}` : firstEntity

    return {
      key: `${collection.WagonID}-${collection.CabinID}`,
      value: `Wagen ${collection.WagonNumber} / Abt. ${collection.CabinNumber} / ${entityString}`
    }
  }, [ticket, collectionID, sameEntity])

  useEffect(() => {
    setSelectedCabin(currentCabin.key)
  }, [currentCabin])

  const cabins = useMemo(() => {
    const { data } = availableEntities;
    const list = currentCabin ? [currentCabin] : []

    if (data && (data.readAvailableEntities || data.readAllAvailableEntities)) {
      const wagons = data.readAvailableEntities || data.readAllAvailableEntities
      wagons.forEach(wagon => {
        wagon.Cabins.forEach(cabin => {
          const firstEntity = cabin.Entities[0].Title
          const lastEntity = cabin.Entities[cabin.Entities.length - 1].Title
          const entityString = firstEntity !== lastEntity ? `${firstEntity} - ${lastEntity}` : firstEntity
          const entityTypeString = !sameEntity ? `${cabin.EntityType} ` : ''
          list.push({
            key: `${wagon.ID}-${cabin.ID}`,
            value: <ListItemText>Wagen {wagon.WagonNumber} / Abt. {cabin.CabinNumber} / {entityTypeString}{entityString}</ListItemText>,
            icon: cabin.Entities.some(entity => entity.BarrierFree) ? (
              <ListItemIcon style={{ minWidth: 34 }}>
                <AccessibleIcon fontSize="small" />
              </ListItemIcon>
            ) : null
          })
        })
      })
    }

    return list.sort((a, b) => {
      if (a.value < b.value) return -1
      if (a.value > b.value) return 1
      return 0
    })
  }, [availableEntities, currentCabin, sameEntity])

  const toggleBlockCabin = () => {
    setBlockCabin(prevState => !prevState)
  }

  const submitMove = () => {
    const selection = selectedCabin.split('-')
    const wagon = selection[0]
    const cabin = selection[1]
    moveBookingCollection({
      "TicketNumber": ticket.TicketNumber,
      "CollectionID": collectionID,
      "WagonID": wagon,
      "CabinID": cabin,
      "BlockCabin": blockCabin,
      "BlockCabinText": blockCabin ? blockReason : '',
      "NewEntityType": !sameEntity
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        if (handleBack) {
          handleBack()
        }
        pushSuccess('Platzwechsel erfolgreich')
      }
    })
  }

  const submitSwitch = useCallback(() => {
    const target = selectedSwitchCollection.split('##')
    switchBookingCollection({
      "TicketNumber": ticket.TicketNumber,
      "CollectionID": collectionID,
      "TargetTicketNumber": target[0],
      "TargetCollectionID": target[1]
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        if (handleBack) {
          handleBack()
        }
        pushSuccess('Tickets erfolgreich getauscht')
      }
    })
  }, [collectionID, handleBack, pushError, pushSuccess, selectedSwitchCollection, switchBookingCollection, ticket.TicketNumber])

  return (
    <>
      <TicketMove
        fetching={availableEntities.fetching}
        sameEntity={sameEntity}
        setSameEntity={setSameEntity}
        ticket={ticket}
        blockCabin={blockCabin}
        toggleBlockCabin={toggleBlockCabin}
        blockReason={blockReason}
        setBlockReason={setBlockReason}
        currentCabin={currentCabin}
        cabins={cabins}
        selectedCabin={selectedCabin}
        setSelectedCabin={setSelectedCabin}
        selectedSwitchCollection={selectedSwitchCollection}
        setSelectedSwitchCollection={setSelectedSwitchCollection}
        submitMove={submitMove}
        submitSwitch={submitSwitch}
        disabled={switchMode ? selectedSwitchCollection === '' : currentCabin.key === selectedCabin}
        switchMode={switchMode}
        setSwitchMode={(value) => {setBlockCabin(false)
          setSwitchMode(value)
        }}
        switchableBookingCollections={switchableBookingCollections.data?.readSwitchableBookings || []}
      />
    </>
  );
}

TicketMoveProvider.propTypes = {
  ticket: PropTypes.object.isRequired,
  handleBack: PropTypes.func
}

export default TicketMoveProvider;
