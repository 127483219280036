import { Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from "urql";
import { DATEPICKER_BACKEND_FORMAT } from "../../../../constants";
import {
  readMicroactionTrainConnectionAvailabilityQuery,
  readMicroactionTrainConnectionPriceQuery,
  trainConnectionMicroactionMutation
} from "../../../../lib/GraphQLQueries";
import { useNotificationPush } from "../../../Snackbar/SnackbarContext";
import { useDialog } from "../CategoryBox";
import Summary from "./components/Summary";
import { useStyles } from './styles';

import TrainConnectionEdit from './TrainConnectionEdit';

const TrainConnectionEditProvider = ({ ticket }) => {
  const classes = useStyles();
  const { onClose } = useDialog()
  const { pushSuccess, pushError } = useNotificationPush()

  const [changeDirection, setChangeDirection] = useState(false)
  const [date, setDate] = useState(null)
  const [returnDate, setReturnDate] = useState(null)
  const [customDiscount, setCustomDiscount] = useState('')
  const [fee, setFee] = useState(90)
  const [changePrice, setChangePrice] = useState(true)

  const [readTrainConnectionsResult] = useQuery({
    query: readMicroactionTrainConnectionAvailabilityQuery,
    variables: {
      DepartureStationID: parseInt(ticket.IsReturn ? ticket.ArrivalStationID : ticket.DepartureStationID, 10),
      ArrivalStationID: parseInt(ticket.IsReturn ? ticket.DepartureStationID : ticket.ArrivalStationID, 10),
      VehiclesEnabled: ticket.VehicleSlots?.length > 0,
      ChangeDirection: changeDirection
    },
    pause: !ticket || !ticket.DepartureStationID || !ticket.ArrivalStationID
  })

  const [readReturnTrainConnectionsResult] = useQuery({
    query: readMicroactionTrainConnectionAvailabilityQuery,
    variables: {
      DepartureStationID: parseInt(ticket.IsReturn ? ticket.DepartureStationID : ticket.ArrivalStationID, 10),
      ArrivalStationID: parseInt(ticket.IsReturn ? ticket.ArrivalStationID : ticket.DepartureStationID, 10),
      VehiclesEnabled: ticket.VehicleSlots?.length > 0
      // leave "ChangeDirection" empty; just allowed for single rides
    },
    pause: !ticket || !ticket.OppositeDirectionTicket || !ticket.DepartureStationID || !ticket.ArrivalStationID
  })

  const inputVars = useMemo(() => ({
    input: {
      TicketNumbers: [
        ticket.TicketNumber,
        ...ticket.OppositeDirectionTicket ? [ticket.OppositeDirectionTicket.TicketNumber] : []
      ],
      AwayDate: date?.format('YYYY-MM-DD') || null,
      ReturnDate: returnDate?.format('YYYY-MM-DD') || null,
      Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
      Coupons: parseInt(customDiscount) > 0 ? [
        {
          "CouponType": "CustomDiscount",
          "Payload": parseInt(customDiscount, 10)
        }] : null,
      ChangePrice: changePrice,
      ChangeDirection: changeDirection
    }
  }), [ticket.TicketNumber, ticket.OppositeDirectionTicket, date, returnDate, fee, customDiscount, changePrice, changeDirection])

  const [readMicroactionTrainConnectionPriceResult] = useQuery({
    query: readMicroactionTrainConnectionPriceQuery,
    variables: inputVars,
    pause: !ticket || !date
  })

  const [microactionState, changeTrainConnections] = useMutation(trainConnectionMicroactionMutation)

  const submitNewConnections = useCallback(() => {
    changeTrainConnections(inputVars).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Änderungen erfolgreich übernommen')
        onClose()
      }
    })
  }, [changeTrainConnections, inputVars, pushError, pushSuccess, onClose])

  useEffect(() => {
    if (!ticket.IsReturn) {
      setDate(moment(ticket.StartDate, DATEPICKER_BACKEND_FORMAT))
    }

    if (ticket.OppositeDirectionTicket && !ticket.OppositeDirectionTicket.IsReturn) {
      setDate(moment(ticket.OppositeDirectionTicket.StartDate, DATEPICKER_BACKEND_FORMAT))
    }
  }, [ticket])

  useEffect(() => {
    if (ticket.IsReturn) {
      setReturnDate(moment(ticket.StartDate, DATEPICKER_BACKEND_FORMAT))
    }

    if (ticket.OppositeDirectionTicket && ticket.OppositeDirectionTicket.IsReturn) {
      setReturnDate(moment(ticket.OppositeDirectionTicket.StartDate, DATEPICKER_BACKEND_FORMAT))
    }
  }, [ticket])

  const availableDates = useMemo(() => {
    return readTrainConnectionsResult.data?.readMicroactionTrainConnectionAvailability
      .map(connection => connection.StartDate)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
  }, [readTrainConnectionsResult.data])

  const availableReturnDates = useMemo(() => {
    return readReturnTrainConnectionsResult.data?.readMicroactionTrainConnectionAvailability
      .map(connection => connection.StartDate)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
  }, [readReturnTrainConnectionsResult.data])

  const hasAwayChanges = useMemo(() => {
    const awayTicketDate = !ticket.IsReturn ? ticket.StartDate : ticket.OppositeDirectionTicket.StartDate
    return date?.format(DATEPICKER_BACKEND_FORMAT) !== awayTicketDate
  }, [date, ticket])

  const hasReturnChanges = useMemo(() => {
    const returnTicketDate = ticket.IsReturn ? ticket.StartDate : ticket.OppositeDirectionTicket?.StartDate
    return !returnTicketDate || returnDate?.format(DATEPICKER_BACKEND_FORMAT) !== returnTicketDate
  }, [returnDate, ticket])

  return (
    <>
      <Grid container spacing={3}>
        <TrainConnectionEdit
          changeDirection={changeDirection}
          setChangeDirection={setChangeDirection}
          hasReturnConnection={ticket.OppositeDirectionTicket}
          ticket={ticket}
          date={date}
          setDate={setDate}
          availableDates={availableDates}
          availableReturnDates={availableReturnDates}
          returnDate={returnDate}
          setReturnDate={setReturnDate}

        />

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={'Individueller Rabatt in EUR'}
            variant={'filled'}
            value={customDiscount}
            onChange={e => setCustomDiscount(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={'Gebühr in EUR'}
            variant={'filled'}
            value={fee}
            onChange={e => setFee(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={changePrice}
                onChange={e => setChangePrice(e.target.checked)}
                color="primary"
              />
            }
            label={'Preis aus neuer Verbindung übernehmen'}
          />
        </Grid>

        <Summary
          totalPrice={readMicroactionTrainConnectionPriceResult.data?.readMicroactionTrainConnectionPrice}
          totalPriceFetching={readMicroactionTrainConnectionPriceResult.fetching || readMicroactionTrainConnectionPriceResult.stale}
          errorMessage={(readMicroactionTrainConnectionPriceResult.fetching || readMicroactionTrainConnectionPriceResult.stale)
            ? false
            : (readMicroactionTrainConnectionPriceResult.error?.message || false)
        }
        />
      </Grid>

      <DialogActions className={classes.dialogActions}>
        <Button variant={'text'} onClick={onClose}>Abbrechen</Button>
        <Button
          onClick={submitNewConnections}
          disabled={
            readMicroactionTrainConnectionPriceResult.error
            || readMicroactionTrainConnectionPriceResult.fetching
            || readMicroactionTrainConnectionPriceResult.stale
            || !(hasAwayChanges || (returnDate && hasReturnChanges))
        }
        >
          {microactionState.fetching ? <CircularProgress /> : 'Bestätigen'}
        </Button>
      </DialogActions>
    </>
  );
}

TrainConnectionEditProvider.propTypes = {}

export default TrainConnectionEditProvider;
