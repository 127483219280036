import { Box, CircularProgress, Typography } from "@mui/material";
import React, { Suspense, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider as URQLProvider } from 'urql';
import LoginContextProvider from "./features/Login/LoginContext";
import OfflineStatus from "./features/OfflineStatus";
import Snackbar from "./features/Snackbar";
import TicketDetailsProvider from "./features/TicketDetails";
import TicketHistoryContextProvider from "./features/TicketHistory/TicketHistoryContext";
import { Websocket } from "./features/Websocket";
import Layout from './layout';
import SingleColumn from "./layout-content/SingleColumn";
import { Theme } from "./layout/components/Theme";
import GraphQLClientSilverstripe from './lib/URQLClientSilverstripe';
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import PasswordResetPage from "./pages/PasswordResetPage";

const TrainPage = React.lazy(() => import(/* webpackChunkName: "TrainPage" */ './pages/TrainPage'));
const CheckinPage = React.lazy(() => import(/* webpackChunkName: "CheckinPage" */ './pages/CheckinPage'));
const FeedbackPage = React.lazy(() => import(/* webpackChunkName: "FeedbackPage" */ './pages/FeedbackPage'));
const SearchPage = React.lazy(() => import(/* webpackChunkName: "SearchPage" */ './pages/SearchPage'));
const TrainConsultingPage = React.lazy(() => import(/* webpackChunkName: "TrainConsultingPage" */ './pages/TrainConsultingPage'));
const TrainOverviewPage = React.lazy(() => import(/* webpackChunkName: "TrainOverviewPage" */ './pages/TrainOverviewPage'));
const TicketOverviewPage = React.lazy(() => import(/* webpackChunkName: "TicketOverviewPage" */ './pages/TicketOverviewPage'));
const NotFoundPage = React.lazy(() => import(/* webpackChunkName: "NotFoundPage" */ './pages/NotFoundPage'));
const DashboardPage = React.lazy(() => import(/* webpackChunkName: "DashboardPage" */ './pages/DashboardPage'));
const ProfilePage = React.lazy(() => import(/* webpackChunkName: "ProfilePage" */ './pages/ProfilePage'));
const ExportPage = React.lazy(() => import(/* webpackChunkName: "ExportPage" */ './pages/ExportPage'));
const IndexPage = React.lazy(() => import(/* webpackChunkName: "IndexPage" */ './pages/IndexPage'));

const ServicesFeature = React.lazy(() => import(/* webpackChunkName: "ServicesFeature" */ './features/Services'));

type PropTypes = {
  logoUrl: string
}

const App = ({ logoUrl }: PropTypes) => {
  const loading = useMemo(() => (
    <SingleColumn fullWidth>
      <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
        <CircularProgress size={24} />
        <Typography variant={'h1'}>
          Wird geladen...
        </Typography>
      </Box>
    </SingleColumn>
  ), [])

  return (
    <BrowserRouter>
      <Snackbar>
        <URQLProvider value={GraphQLClientSilverstripe}>
          <Theme>
            <OfflineStatus>
              <Websocket>
                <LoginContextProvider>
                  <TicketHistoryContextProvider>
                    <TicketDetailsProvider>
                      <Layout logoUrl={logoUrl}>
                          <Suspense fallback={loading}>
                            <Routes>
                              <Route path={'/zuege/*'} element={<TrainPage pageLink={'/zuege/'} />} />
                              <Route path={'/checkin/*'} element={<CheckinPage />} />
                              <Route path={'/suche/*'} element={<SearchPage />} />

                              <Route path={'/ticketuebersicht/'} element={<TicketOverviewPage />} />
                              <Route path={'/feedback/*'} element={<FeedbackPage />} />
                              <Route path={'/zuguebersicht/'} element={<TrainOverviewPage />} />
                              <Route path={'/letzte-tickets/'} element={<DashboardPage />} />
                              <Route path={'/zug-verwaltung/*'} element={<TrainConsultingPage pageLink={'/zug-verwaltung/'} />} />

                              <Route path={'/services/'} element={<ServicesFeature />} />

                              <Route path={'/profil/'} element={<ProfilePage />} />
                              <Route path={'/export/'} element={<ExportPage />} />
                              <Route path={'/password-reset/*'} element={<PasswordResetPage />} />
                              <Route path={'/logout/'} element={<LogoutPage />} />
                              <Route path={'/login/'} element={<LoginPage />} />
                              <Route path={'/'} element={<IndexPage />} />

                              <Route path={'*'} element={<NotFoundPage />} />
                            </Routes>
                          </Suspense>
                      </Layout>
                    </TicketDetailsProvider>
                  </TicketHistoryContextProvider>
                </LoginContextProvider>
              </Websocket>
            </OfflineStatus>
          </Theme>
        </URQLProvider>
      </Snackbar>
    </BrowserRouter>
  );
}

export default App;
