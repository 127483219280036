import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonContent from './layout/ButtonContent';

import MultiCheckInButton from './MultiCheckInButton';

const CheckInButton = ({ ticket, readOnly, alternativeText, checkinType, validateTicket, validateVehicleSlot }) => {
  if (!ticket) {
    return null;
  }

  if (checkinType.includes('vehicle') && ticket.VehicleSlots && ticket.VehicleSlots.length) {
    return (
      <MultiCheckInButton
        readOnly={readOnly}
        ticket={ticket}
        checkinType={checkinType}
        validateTicket={validateTicket}
        validateVehicleSlot={validateVehicleSlot}
        alternativeText={alternativeText}
      />
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} onClick={validateTicket}>
        <ButtonContent
          checkedIn={ticket.Checked}
          readOnly={readOnly}
          alternativeText={alternativeText}
        />
      </Grid>
    </Grid>
  );
};

CheckInButton.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  readOnly: PropTypes.bool
}

export default CheckInButton;
