import { Skeleton, TableCell, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import { useStyles } from './styles'

const EntityItem = ({ wagon, cabin, entityType, entity, space }) => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.cell}>
        <Typography component={'div'} variant={'h4'} className={classes.text}>{entity ? `Wagon ${wagon}` : <Skeleton />}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography component={'div'} variant={'h4'} className={classes.text}>{entity ? `Abt ${cabin}` : <Skeleton />}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography component={'div'} variant={'h4'} className={classes.text}>{entityType && entity ? `${entityType} ${entity}` : <Skeleton />}</Typography>
      </TableCell>
    </TableRow>
  )
}

EntityItem.propTypes = {
  wagon: PropTypes.number,
  cabin: PropTypes.number,
  entityType: PropTypes.string,
  entity: PropTypes.string,
}

export default EntityItem
