import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { lostLoginPasswordMutation } from "../../lib/GraphQLQueries";
import { useNotificationPush } from "../Snackbar/SnackbarContext";
import { useStyles } from "./styles";

export const PasswordResetRequest = () => {
  const [email, setEmail] = useState('')
  const classes = useStyles()
  const { pushSuccess, pushError } = useNotificationPush() || {}
  const navigate = useNavigate()

  const [state, requestResetLink] = useMutation(lostLoginPasswordMutation)
  const submitForm = useCallback((e: React.FormEvent) => {
    e.preventDefault();

    if (state.fetching) {
      return;
    }

    requestResetLink({
      input: {
        Email: email
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Die "Passwort zurücksetzen" E-Mail wurde gesendet, sofern der Benutzer existiert')
        navigate('/login')
      }
    })
  }, [email, requestResetLink, pushSuccess, pushError, navigate, state])

  return (
    <>
      <Link to={'/login'} className={classes.backLink}>
        <ArrowBack />
        Zurück zum Login
      </Link>

      <form onSubmit={submitForm}>
        <Typography variant="h1" component="h1" gutterBottom>Passwort zurücksetzen</Typography>

        <TextField
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value)}
          label={'E-Mail'}
        />

        <Button type={"submit"} sx={{marginTop: 2}} disabled={state.fetching}>
          Email anfordern
        </Button>
      </form>
    </>
  )
}
