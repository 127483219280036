import { ClassNameMap, Grid } from '@mui/material'
import React from 'react'
import UserContextMenu from '../../features/UserContextMenu'

type PropTypes = {
  logoUrl: string
  classes: ClassNameMap
}

const LayoutHeader = ({ logoUrl, classes }: PropTypes) => {
  return (
    <Grid container className={classes.header}>
      <Grid item xs={3} md={2}>
        {logoUrl && <img src={logoUrl} alt={'Logo'} />}
      </Grid>
      <Grid item xs={7}>
        <UserContextMenu />
      </Grid>
    </Grid>
  );
}

export default LayoutHeader
