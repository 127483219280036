import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import {
  addPaybackValueMutation,
  updatePaybackValueMutation,
  validatePaybackMembershipQuery
} from '../../../../lib/GraphQLQueries'
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'
import { useDialog } from '../CategoryBox'
import { PaybackDeleteConfirmDialog } from "./PaybackDeleteConfirmDialog";

import PaybackEdit from './PaybackEdit'

const PaybackEditProvider = ({ ticketNumber, paybackValues }) => {
  const { pushSuccess, pushError } = useNotificationPush()
  const { onClose } = useDialog()
  const [paybackValueID, setPaybackValueID] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [changedPaybackValue, setChangedPaybackValue] = useState({})

  const [updateState, updatePaybackValue] = useMutation(updatePaybackValueMutation)
  const [newState, addPaybackValue] = useMutation(addPaybackValueMutation)

  const [validateMembershipResult] = useQuery({
    query: validatePaybackMembershipQuery,
    variables: {
      Type: changedPaybackValue.Type || null,
      MembershipNumber: changedPaybackValue.MembershipNumber || null
    },
    pause: !changedPaybackValue.Type || !changedPaybackValue.MembershipNumber
  })

  const submitUpdate = useCallback(() => {
    updatePaybackValue({
      PaybackID: paybackValueID,
      MembershipNumber: paybackValues[0]?.MembershipNumber !== changedPaybackValue?.MembershipNumber
          ? changedPaybackValue.MembershipNumber
          : null
    })
      .then(res => {
        if (res.error) {
          pushError(res.error.message)
        } else {
          pushSuccess('Speichern erfolgreich')
          onClose()
        }
      })
  }, [changedPaybackValue.MembershipNumber, onClose, paybackValueID, paybackValues, pushError, pushSuccess, updatePaybackValue])

  const submitNew = useCallback(() => {
    addPaybackValue({
      TicketNumber: ticketNumber,
      MembershipNumber: changedPaybackValue.MembershipNumber,
      Type: changedPaybackValue.Type
    })
      .then(res => {
        if (res.error) {
          pushError(res.error.message)
        } else {
          pushSuccess('Erfolgreich hinzugefügt')
          onClose()
        }
      })
  }, [addPaybackValue, changedPaybackValue.MembershipNumber, changedPaybackValue.Type, onClose, pushError, pushSuccess, ticketNumber])

  useEffect(() => {
    if (paybackValues.length > 0) {
      setPaybackValueID(paybackValues[0].ID)
      setChangedPaybackValue({
        Type: paybackValues[0].Type,
        MembershipNumber: paybackValues[0].MembershipNumber,
      })
    }
  }, [paybackValues])

  const changePaybackValue = useCallback((property, value) => {
    setChangedPaybackValue(prev => ({...prev, [property]: value}))
  }, [])

  return (
    <>
      <PaybackEdit
        isNew={paybackValues.length === 0}
        isChanged={paybackValues[0]?.MembershipNumber !== changedPaybackValue?.MembershipNumber}
        changedPaybackValue={changedPaybackValue}
        changePaybackValue={changePaybackValue}
        fetching={updateState.fetching || newState.fetching}
        disabled={updateState.fetching || newState.fetching || deleteDialogOpen}
        submit={paybackValues.length === 0 ? submitNew : submitUpdate}
        deletePayback={() => setDeleteDialogOpen(true)}
        isValid={validateMembershipResult.data?.validatePaybackMembership || false}
        validationFetching={validateMembershipResult.fetching || validateMembershipResult.stale}
      />

      <PaybackDeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        paybackValueID={paybackValueID}
      />
    </>
  );
}

export default PaybackEditProvider;
