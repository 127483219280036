import { SnackbarProvider as Snackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import SnackbarContext from './SnackbarContext';

const SnackbarProvider = ({ children }) => {
  return (
    <Snackbar maxSnack={10} anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}>
      <SnackbarContext>
        {children}
      </SnackbarContext>
    </Snackbar>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default SnackbarProvider;
