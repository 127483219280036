import DoneIcon from '@mui/icons-material/Done'
import { Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import { useLargeStyles } from './largeStyles'

const ContentCheckedIn = ({ checkedIn, classes, alternativeCheckedInText }) => (
  <Grid container alignItems="center" className={classes.buttonInner}>
    <Grid item className={classes.icon}>
      <DoneIcon />
    </Grid>
    <Grid item>
      <Typography component={'div'} className={classes.title}>{alternativeCheckedInText || 'Check In'}</Typography>
      <Typography component={'div'} className={classes.time}>{checkedIn.format('DD.MM.YYYY, HH:mm')} Uhr</Typography>
    </Grid>
  </Grid>
)

const ContentCheckedInSmall = ({ checkedIn, classes, alternativeCheckedInText }) => (
  <Grid container alignItems="center" className={classes.buttonInnerSmall}>
    <Grid item className={classes.icon}>
      <DoneIcon />
    </Grid>
    <Grid item>
      <Typography component={'div'} className={classes.title}>{alternativeCheckedInText || 'Check In'}</Typography>
      <Typography component={'div'} className={classes.time}>{checkedIn.format('DD.MM.YYYY, HH:mm')} Uhr</Typography>
    </Grid>
  </Grid>
)

const ButtonContent = ({ size = 'large', checkedIn, readOnly, alternativeText, alternativeCheckedInText }) => {
  const classes = useLargeStyles()

  const buttonClasses = [size === 'large' ? classes.button : classes.buttonSmall]
  if (checkedIn && !readOnly) {
    buttonClasses.push(classes.buttonChecked)
  }

  if (readOnly) {
    buttonClasses.push(classes.buttonDisabled)
  }

  if (size === 'small') {
    buttonClasses.push()
  }

  return (
    <Box className={buttonClasses.join(' ')}>
      {checkedIn
        ? (size === 'large'
          ? <ContentCheckedIn checkedIn={checkedIn} classes={classes} alternativeCheckedInText={alternativeCheckedInText} />
          : <ContentCheckedInSmall checkedIn={checkedIn} classes={classes} alternativeCheckedInText={alternativeCheckedInText} />
        ) : alternativeText || 'Check In'}
    </Box>
  )
}

ButtonContent.propTypes = {
  checkedIn: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

export default ButtonContent
