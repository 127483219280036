import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { CURRENT_HOST } from "../../constants";

import { useStyles } from './styles'

const TimeOffsetContext = createContext();
export const useTimeOffset = () => useContext(TimeOffsetContext)

const PWAOfflineStatus = ({ children }) => {
  const classes = useStyles();
  const [isOnline, setOnlineStatus] = useState(true);
  const [timeOffset, setTimeOffset] = useState(0);

  const fetchWithTimeout = async (resource, options) => {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);

    return response;
  }

  useEffect(() => {
    const webPing = setInterval(() => {
      fetchWithTimeout(`${CURRENT_HOST}/alive`, {
        mode: 'no-cors',
      })
        .then(res => {
          res.text().then(data => {
            const serverTime = moment(data).utc()
            setTimeOffset(serverTime.diff(moment(), 'seconds'))
          })
          setOnlineStatus(true)
        })
        .catch(() => setOnlineStatus(false))
    }, 10000)

    return () => clearInterval(webPing)
  }, [isOnline])

  useEffect(() => {
    const setOnline = () => setOnlineStatus(true)
    const setOffline = () => setOnlineStatus(false)

    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    }
  }, []);

  return (
    <>
      {!isOnline && (
        <div className={classes.offline}>
          Offline
        </div>
      )}
      <TimeOffsetContext.Provider value={{ isOnline,timeOffset }}>
        {children}
      </TimeOffsetContext.Provider>
    </>
  )
};

export default PWAOfflineStatus;
