import PropTypes from 'prop-types';
import React from 'react';
import TicketList from './TicketList'

const TicketListProvider = ({ ticketList = [], emptyString }) => {
  return (
    <TicketList
      ticketList={ticketList}
      emptyString={emptyString}
    />
  );
}

TicketListProvider.propTypes = {
  ticketList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  ).isRequired,
  emptyString: PropTypes.string
}

export default TicketListProvider;
