import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const TicketHistoryContext = createContext();
const TicketHistoryUpdateContext = createContext();

export const useTicketHistory = () => useContext(TicketHistoryContext)
export const useTicketHistoryPush = () => useContext(TicketHistoryUpdateContext)

const TicketHistoryContextProvider = ({ children }) => {
  const [ticketHistory, setTicketHistory] = useState(JSON.parse(localStorage.getItem('ticketHistory') || '[]'))

  const pushToHistory = item => {
    setTicketHistory(prev => {
      const nextHistory = [...prev].filter(el => el !== item).slice(0, 5)
      nextHistory.unshift(item)
      localStorage.setItem('ticketHistory', JSON.stringify(nextHistory))
      return nextHistory
    })
  }

  const shiftHistory = () => {
    setTicketHistory(prev => {
      const nextHistory = [...prev]
      nextHistory.shift()
      return nextHistory
    })
  }

  return (
    <TicketHistoryContext.Provider value={ticketHistory}>
        <TicketHistoryUpdateContext.Provider value={{
          pushToHistory,
          shiftHistory
        }}>
          {children}
        </TicketHistoryUpdateContext.Provider>
    </TicketHistoryContext.Provider>
  );
}

TicketHistoryContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default TicketHistoryContextProvider
