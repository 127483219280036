import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from 'react';

const Destinations = ({ ticket, hasReturnConnection, changeDirection, setChangeDirection }) => {
  // TODO: this is a readonly component for now. Implement changing stations later

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          disabled
          label={'Von'}
          variant={'filled'}
          value={changeDirection ? ticket.ArrivalStation : ticket.DepartureStation}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          disabled
          label={'Nach'}
          variant={'filled'}
          value={changeDirection ? ticket.DepartureStation : ticket.ArrivalStation}
        />
      </Grid>

      {!hasReturnConnection && (
        <Grid item xs={12}>
          <FormControlLabel
            label={'Fahrtrichtung wechseln?'}
            control={
              <Checkbox

                checked={changeDirection}
                onChange={e => setChangeDirection(e.target.checked)}
              />
            }
          />
        </Grid>
      )}
    </>
  )
}

Destinations.propTypes = {}

export default Destinations;
