import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { DASHBOARDAPP_MICROACTION_ADDONS } from '../../../../constants'
import {
  addAddOnMicroactionMutation,
  readMicroactionAddOnPriceQuery,
  readMicroactionAddOnsByTicket,
  readTicketAddOns
} from '../../../../lib/GraphQLQueries';
import { usePermissions } from '../../../Login/LoginContext'
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'
import { useTicketNumber } from '../../TicketDetailsContext';
import { useDialog } from '../CategoryBox';

import AddOnsEdit from './AddOnsEdit';

const AddOnsEditProvider = ({ requestNumber }) => {
  const ticketnumber = useTicketNumber()
  const { onClose } = useDialog()
  const { canAccess } = usePermissions() || {}
  const { pushSuccess, pushError } = useNotificationPush()
  const [currentDirection, setCurrentDirection] = useState(null)
  const [hasReturnConnection, setHasReturnConnection] = useState(false)
  const [addedAwayAddOns, setAddedAwayAddOns] = useState({})
  const [addedReturnAddOns, setAddedReturnAddOns] = useState({})
  const [addedCombinedAddOns, setAddedCombinedAddOns] = useState({})
  const [awayAddOns, setAwayAddOns] = useState([])
  const [returnAddOns, setReturnAddOns] = useState([])
  const [combinedAddOns, setCombinedAddOns] = useState([])
  const [customDiscount, setCustomDiscount] = useState('')
  const [fee, setFee] = useState('')
  const [changePrice, setChangePrice] = useState(true)

  const [existingAddOnsResult] = useQuery({
    query: readTicketAddOns,
    variables: {
      Ticketnumber: ticketnumber
    },
    pause: !ticketnumber,
  })

  const [addOnOptionsResult] = useQuery({
    query: readMicroactionAddOnsByTicket,
    variables: {
      TicketNumber: ticketnumber
    }
  })

  const reduceAddOns = useCallback(addOns => {
    return Object.values(addOns).reduce((acc, item) => {
      let newAcc = [...acc]
      const existingItemIndex = acc.findIndex(i => i.Type === item.ID)

      if (existingItemIndex >= 0) {
        newAcc[existingItemIndex].Amount += 1
      } else {
        newAcc = [...newAcc, {Type: item.ID, Amount: 1, RequestNumber: requestNumber}]
      }
      return newAcc
    }, [])
  }, [requestNumber])

  const collectAddOns = useMemo(() => {
    if (existingAddOnsResult.data) {
      const currentTicket = existingAddOnsResult.data?.readOneTicket;
      setCurrentDirection(currentTicket.IsReturn ? 'return' : 'away')

      const awayTicket = currentTicket.IsReturn !== undefined && currentTicket.IsReturn
        ? currentTicket.OppositeDirectionTicket
        : currentTicket

      const returnTicket = currentTicket.IsReturn !== undefined && currentTicket.IsReturn
        ? currentTicket
        : currentTicket.OppositeDirectionTicket

      const result = []

      const awayAddOnsToSubmit = reduceAddOns({ ...addedAwayAddOns, ...addedCombinedAddOns })

      if (awayAddOnsToSubmit.length) {
        result.push({
          TicketNumber: awayTicket.TicketNumber,
          AddOns: awayAddOnsToSubmit
        })
      }

      if (returnTicket) {
        const returnAddOnsToSubmit = reduceAddOns({ ...addedReturnAddOns, ...addedCombinedAddOns })
        if (returnAddOnsToSubmit.length) {
          result.push({
            TicketNumber: returnTicket.TicketNumber,
            AddOns: returnAddOnsToSubmit
          })
        }
      }

      return result
    }
  }, [existingAddOnsResult.data, addedAwayAddOns, addedCombinedAddOns, addedReturnAddOns, reduceAddOns])

  const [newAddOnPrice] = useQuery({
    query: readMicroactionAddOnPriceQuery,
    variables: {
      Tickets: collectAddOns,
      Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
      Coupons: parseInt(customDiscount) > 0 ? [{
        "CouponType": "CustomDiscount",
        "Payload": parseInt(customDiscount, 10)
      }] : null,
      ChangePrice: changePrice,
    }
  })

  const [mutationState, submitNewAddOnsMutation] = useMutation(addAddOnMicroactionMutation)

  const submitNewAddOns = () => {
    submitNewAddOnsMutation({
      Tickets: collectAddOns,
      Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
      Coupons: parseInt(customDiscount) > 0 ? [{
        "CouponType": "CustomDiscount",
        "Payload": parseInt(customDiscount, 10)
      }] : null,
      ChangePrice: changePrice,
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Extras wurden hinzugefügt')
        onClose()
      }
    })
  }

  useEffect(() => {
    let addOns = {
      away: [],
      return: [],
      combined: [],
    }

    if (existingAddOnsResult.data) {
      const currentTicket = existingAddOnsResult.data?.readOneTicket;
      setCurrentDirection(currentTicket.IsReturn ? 'return' : 'away')

      const awayTicket = currentTicket.IsReturn !== undefined && currentTicket.IsReturn
          ? currentTicket.OppositeDirectionTicket
          : currentTicket

      const returnTicket = currentTicket.IsReturn !== undefined && currentTicket.IsReturn
        ? currentTicket
        : currentTicket.OppositeDirectionTicket

      setHasReturnConnection(returnTicket !== null)

      const tickets = [awayTicket]
      if (returnTicket) {
        tickets.push(returnTicket)
      }

      tickets.forEach(ticket => {
        (ticket.AddOns || []).forEach(addOn => {
          if (!addOn || !addOn.AddOnType) {
            return null
          }

          if (addOn.RequestNumber !== requestNumber && (typeof requestNumber !== 'undefined' || addOn.BookingCollectionID !== '0')) {
            return null
          }

          const direction = ticket.IsReturn ? 'return' : 'away'
          const oppositeDirection = !ticket.IsReturn ? 'return' : 'away'

          if (!addOn.AddOnType.GroupJourneys || !returnTicket) {
            addOns = { ...addOns, [direction]: [...addOns[direction], addOn] }
          } else {
            const addOnTypeID = addOn.AddOnTypeID
            const foundIndex = addOns.combined.findIndex(item => item.addOnTypeID === addOnTypeID && !item[direction])
            const foundItem = {...addOns.combined[foundIndex]}
            const found = foundIndex >= 0

            let existingCombined = []
            if (found) {
              existingCombined = addOns.combined.filter((_, index) => index !== foundIndex)
            } else {
              existingCombined = addOns.combined
            }

            addOns = {
              ...addOns,
              combined: [
                ...existingCombined,
                { addOnTypeID: addOnTypeID, [direction]: addOn, [oppositeDirection]: foundItem?.[oppositeDirection] || null }
              ]
            }
          }
        })
      })
    }

    setAwayAddOns(addOns.away)
    setReturnAddOns(addOns.return)
    setCombinedAddOns(addOns.combined)
  }, [existingAddOnsResult.data, requestNumber])

  const addNewAddOn = useCallback((direction, addOnType) => {
    switch (direction) {
      case 'away':
        setAddedAwayAddOns(prev => ({...prev, [Date.now()]: addOnType}))
        break;
      case 'return':
        setAddedReturnAddOns(prev => ({...prev, [Date.now()]: addOnType}))
        break;
      case 'combined':
        setAddedCombinedAddOns(prev => ({...prev, [Date.now()]: addOnType}))
        break;
      default:
    }
  }, [setAddedAwayAddOns])

  const removeNewAddOn = useCallback((direction, indexToRemove) => {
    const filterFunc = prev => Object.keys(prev)
      .reduce((obj, key) => key === indexToRemove
          ? obj
          : ({ ...obj, [key]: prev[key] })
      , {})

    switch (direction) {
      case 'away':
        setAddedAwayAddOns(filterFunc)
        break;
      case 'return':
        setAddedReturnAddOns(filterFunc)
        break;
      case 'combined':
        setAddedCombinedAddOns(filterFunc)
        break;
      default:
    }
  }, [setAddedAwayAddOns])

  if (!canAccess(DASHBOARDAPP_MICROACTION_ADDONS)) {
    return null;
  }

  return (
    <AddOnsEdit
      currentDirection={currentDirection}
      awayAddOns={awayAddOns}
      returnAddOns={returnAddOns}
      combinedAddOns={combinedAddOns}
      addedAwayAddOns={addedAwayAddOns}
      addOnOptions={(addOnOptionsResult.data?.readMicroactionAddOnsByTicket || []).filter(item => item.Binding === (typeof requestNumber === 'undefined' ? 'Ticket' : 'Collection'))}
      addedReturnAddOns={addedReturnAddOns}
      addedCombinedAddOns={addedCombinedAddOns}
      addNewAddOn={addNewAddOn}
      removeNewAddOn={removeNewAddOn}
      submitNewAddOns={submitNewAddOns}
      newAddOnTotalPrice={newAddOnPrice.data?.readMicroactionAddOnPrice?.Price.Amount}
      hasNewItems={collectAddOns?.length}
      hasReturnConnection={hasReturnConnection}
      fetching={mutationState.fetching}
      isTicketAddOns={typeof requestNumber === 'undefined'}
      customDiscount={customDiscount}
      setCustomDiscount={setCustomDiscount}
      fee={fee}
      setFee={setFee}
      changePrice={changePrice}
      setChangePrice={setChangePrice}
    />
  );
}

AddOnsEditProvider.propTypes = {}

export default AddOnsEditProvider;
