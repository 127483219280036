import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import TextField from "@mui/material/TextField";
import React, { useState } from 'react';
import { INVOICE_AMOUNT_LABEL, VEHICLE_TYPES } from '../../../../constants'
import { CHANGE_VEHICLES_INFO } from "../../../../infoText";
import SummaryBox from "../../../SummaryBox";

import { useDialog } from '../CategoryBox';
import Vehicle from './components/Vehicle';
import { useStyles } from './styles';

const VehiclesEdit = ({ fetching,
                        priceErrors,
                        oppositeDirectionVehicles,
                        isReturn,
                        vehicles,
                        changedVehicles,
                        changeVehicle,
                        addVehicle,
                        deleteVehicle,
                        totalPrice,
                        errors,
                        hasErrors,
                        vehiclePrices,
                        hasChanged,
                        submitVehicles,
                        customDiscount,
                        setCustomDiscount,
                        fee,
                        setFee,
                        changePrice,
                        setChangePrice,
                        isBlocked
}) => {
  const classes = useStyles();
  const { onClose } = useDialog();
  const [edited, setEdited] = useState(false);

  return (
    <>
      <Typography variant={'h2'} gutterBottom>
        <strong>
          Buchung anpassen - Fahrzeuge
        </strong>
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={classes.vehicleTop}>
            {Object.keys(changedVehicles).map((vehicleID, index) => (
              <Vehicle
                key={vehicleID}
                index={index}
                vehicleID={vehicleID}
                errors={errors[vehicleID]}
                vehicle={vehicles.find(item => item.ID === vehicleID)}
                changedVehicles={changedVehicles}
                changedVehicle={changedVehicles[vehicleID]}
                changeVehicle={changeVehicle}
                deleteVehicle={deleteVehicle}
                setEdited={setEdited}
                isBlocked={isBlocked}
              />
            ))}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.buttonWrapper}>
            <>
              <ControlPointIcon className={classes.pointer} onClick={addVehicle} />
              <span className={classes.pointer} onClick={addVehicle}>
                {`Fahrzeug hinzufügen`}
              </span>
            </>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={'Individueller Rabatt in EUR'}
                variant={'filled'}
                value={customDiscount}
                onChange={e => setCustomDiscount(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={'Gebühr in EUR'}
                variant={'filled'}
                value={fee}
                onChange={e => setFee(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={changePrice}
                    onChange={e => setChangePrice(e.target.checked)}
                    color="primary"
                  />
                }
                label={'Aktuellen Preis für Änderungen übernehmen'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SummaryBox
            errorMessage={priceErrors}
            prices={[
              ...Object.keys(changedVehicles).map((vehicleID, index) => {
                const price = vehiclePrices.find(item => item.ID === vehicleID)
                const otherDirectionVehicle = oppositeDirectionVehicles.find(item => item.ID === vehicleID)

                let otherPrice;
                if (otherDirectionVehicle) {
                  otherPrice = vehiclePrices.find(item => item.ID === otherDirectionVehicle.ID)?.Price.Amount
                }

                if (!price || !hasChanged[vehicleID]) {
                  return null
                }

                return {
                  title: changedVehicles[vehicleID].IsExisting
                    ? `Änderung ${index + 1}. ${VEHICLE_TYPES[changedVehicles[vehicleID].Type].title}`
                    : `Hinzufügen ${index + 1}. Fahrzeug`,
                  amount: price.Price.Amount,
                  otherDirectionTitle: isReturn ? 'Hinfahrt: ' : 'Rückfahrt: ',
                  otherDirectionPriceAmount: otherPrice
                }
              }),
              {title: INVOICE_AMOUNT_LABEL, amount: totalPrice}
            ]}
            info={CHANGE_VEHICLES_INFO}
          />
        </Grid>
      </Grid>
      <DialogActions className={classes.dialogActions}>
        <Button variant={'text'} onClick={onClose}>Abbrechen</Button>
        <Button disabled={fetching || hasErrors || typeof priceErrors === 'string' || !edited} onClick={submitVehicles}>
          {fetching
            ? <CircularProgress />
            :'Bestätigen'
          }
        </Button>
      </DialogActions>
    </>
  );
}

VehiclesEdit.propTypes = {}

export default VehiclesEdit;
