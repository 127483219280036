import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback } from "react";
import { useStyles } from "./styles";

export const TicketMoveSwitchSelect = ({sameEntity, fetching, switchableBookingCollections, selectedSwitchCollection, setSelectedSwitchCollection}) => {
  const classes = useStyles()

  const combineFirstAndLast = useCallback((array) => {
    if (array.length === 0) {
      return null;
    } else if (array.length === 1) {
      return array[0];
    } else {
      return `${array[0]} - ${array[array.length - 1]}`;
    }
  }, []);

  return fetching ? (
    <CircularProgress />
  ) : (
    <FormControl variant="filled" className={classes.select}>
      <InputLabel id="new-entities-label">Plätze tauschen mit</InputLabel>
      <Select
        labelId="new-entities-label"
        id="new-entities"
        value={selectedSwitchCollection || ''}
        onChange={e => setSelectedSwitchCollection(e.target.value)}
        // className={classes.select}
      >
        {switchableBookingCollections.sort((a, b) => {
          const wagonCompare = a.WagonNumber - b.WagonNumber

          if (wagonCompare === 0) {
            return a.CabinNumber - b.CabinNumber
          }

          return wagonCompare
        }).map(entry => {
          const returnEntry = [
            <MenuItem key={entry.ID} value={`${entry.TicketNumber}##${entry.ID}` || ''}>
              Wagen {entry.WagonNumber} / Abt. {entry.CabinNumber} / {!sameEntity ? entry?.EntityType : ''} {combineFirstAndLast(entry?.Entities || [])}  ({entry.TicketNumber})
            </MenuItem>,
          ];

          return returnEntry
        })}
      </Select>
    </FormControl>
  )
}
