import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../features/Login'

import { useLogin } from '../features/Login/LoginContext'

const LoginPageProvider = () => {
  const { isLoggedIn } = useLogin() || {}
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  return (
    <Dialog open>
      <DialogContent>
        <Login />
      </DialogContent>
    </Dialog>
  );
}

LoginPageProvider.propTypes = {}

export default LoginPageProvider;
