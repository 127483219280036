import { amber } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  discountBox: {
    borderRadius: theme.spacing(2),
    background: amber[200],
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  summaryBoxError: {
    borderRadius: theme.spacing(2),
    background: '#efb4b4',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  summaryBox: {
    borderRadius: theme.spacing(2),
    background: '#EDF3FA',
    padding: theme.spacing(4),
  },
  summaryPriceHeadline: {
    display: 'flex',
    fontWeight: 'bold',
    flexWrap: 'wrap'
  },
  summaryPrice: {
    marginLeft: 'auto',
    fontWeight: 'bold'
  },
  dialogActions: {
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingLeft: 0,
  },
}))
