import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BreakfastType from './BreakfastType';

import { useStyles } from './styles';

const BreakfastItem = ({ component, number, answer, changeItemAnswer, disabled, readOnly }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.item}>
      <Grid item className={classes.category}>
        <Typography component={'span'} variant={'h6'}>{component.Title} {number}</Typography>
      </Grid>
      <Grid container spacing={1}>
        {component.Options.map(option => {
          const checked = answer && answer.OptionID === option.ID
          const change = () => changeItemAnswer({
            answerID: answer && answer.ID,
            optionID: option.ID,
            optionTitle: option.Title
          })
          return (
            <BreakfastType
              key={option.ID}
              title={option.Title}
              checked={checked}
              changeItemAnswer={change}
              disabled={(disabled && !answer) || readOnly}
            />
          )
        })}
      </Grid>
    </Grid >
  );
};

BreakfastItem.propTypes = {
  component: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  answer: PropTypes.object,
  changeItemAnswer: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default BreakfastItem;
