import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Destinations = ({ origin, destination, departureTime, arrivalTime, isToday }) => {

  return (
    <>
      <Grid container spacing={2} sx={{marginBottom: 2}}>
        <Grid item xs={12}>
            {origin
              ? <Typography variant={'h3'}>{origin}</Typography>
              : <Skeleton width={400} />
            }
            <Typography
              color={isToday ? 'textPrimary' : 'error'}
              style={!isToday ? {fontWeight: 'bold'} : null}
            >
              Abfahrt {departureTime || <Skeleton width={200} style={{display: 'inline-block'}} />}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            {destination
              ? <Typography variant={'h3'}>{destination}</Typography>
              : <Skeleton width={400} />
            }
            <Typography>
              Ankunft {arrivalTime || <Skeleton width={200} style={{display: 'inline-block'}} />}
            </Typography>
        </Grid>
      </Grid>
    </>
  );
};

Destinations.propTypes = {
  origin: PropTypes.string,
  destination: PropTypes.string,
  departureTime: PropTypes.string,
  arrivalTime: PropTypes.string,
  isToday: PropTypes.bool
};

export default Destinations;
