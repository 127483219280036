import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { DASHBOARDAPP_MOVE_TICKET_AND_ENTITY, DASHBOARDAPP_SWITCH_TICKET_ENTITIES } from '../../constants'
import { usePermissions } from '../Login/LoginContext'
import SummaryBox from "../SummaryBox";

import { useStyles } from './styles'
import { TicketMoveSelect } from "./TicketMoveSelect";
import { TicketMoveSwitchSelect } from "./TicketMoveSwitchSelect";

const TicketMove = ({
  ticket,
  blockCabin,
  toggleBlockCabin,
  blockReason,
  setBlockReason,
  cabins,
  currentCabin,
  selectedCabin,
  setSelectedCabin,
  selectedSwitchCollection,
  setSelectedSwitchCollection,
  submitMove,
  submitSwitch,
  disabled,
  sameEntity,
  setSameEntity,
  fetching,
  switchMode,
  setSwitchMode,
  switchableBookingCollections
}) => {
  const classes = useStyles()
  const { canAccess } = usePermissions() || {}

  const targetTicketNumber = useMemo(() => {
    const target = selectedSwitchCollection.split('##')
    return target[0] || null
  }, [selectedSwitchCollection])

  return (
    <>
      <Typography component={'div'} variant={'h4'}>Sitzplätze wechseln von</Typography>
      <Typography component={'div'} variant={'h3'} className={classes.current}>{currentCabin.value}</Typography>

      {canAccess(DASHBOARDAPP_SWITCH_TICKET_ENTITIES) && (
        <FormControl className={classes.field} style={{paddingBottom: 0}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={switchMode}
                disabled={!canAccess(DASHBOARDAPP_MOVE_TICKET_AND_ENTITY)}
                onChange={e => setSwitchMode(e.target.checked)}
                name="same-entity"
                color="primary"
              />
            }
            label={'Plätze tauschen?'}
            className={classes.checkbox}
          />
        </FormControl>
      )}

      <FormControl className={classes.field} style={{paddingBottom: 0}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={sameEntity}
              disabled={!canAccess(DASHBOARDAPP_MOVE_TICKET_AND_ENTITY)}
              onChange={e => setSameEntity(e.target.checked)}
              name="same-entity"
              color="primary"
            />
          }
          label={'Platzart beibehalten?'}
          className={classes.checkbox}
        />
      </FormControl>

      <FormControl className={classes.field} style={{paddingBottom: 0}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={blockCabin}
              onChange={toggleBlockCabin}
              disabled={switchMode}
              name="block-cabin"
              color="primary"
            />
          }
          label={'Dieses Abteil blockieren'}
          className={classes.checkbox}
        />
      </FormControl>

      {
        blockCabin && <FormControl className={classes.field}>
          <TextField
            fullWidth
            id="block-reason"
            variant="filled"
            value={blockReason}
            onChange={e => setBlockReason(e.target.value)}
            label={'Grund für Blockade'}
            className={classes.textfield}
          />
        </FormControl>
      }

      { blockCabin && <hr className={classes.spacer} />}

      {switchMode ? (
        <TicketMoveSwitchSelect
          fetching={fetching}
          sameEntity={sameEntity}
          switchableBookingCollections={switchableBookingCollections}
          selectedSwitchCollection={selectedSwitchCollection}
          setSelectedSwitchCollection={setSelectedSwitchCollection}
        />
      ) : (
        <TicketMoveSelect
          fetching={fetching}
          cabins={cabins}
          selectedCabin={selectedCabin}
          setSelectedCabin={setSelectedCabin}
        />
      )}

      {switchMode && targetTicketNumber && (
        <Box sx={{marginBottom: 4}}>
          <SummaryBox info={`Die Platzierung wird mit dem Ticket ${targetTicketNumber} getauscht. Wurden die Plätze den Kunden bereits mitgeteilt, müssen beide Tickets erneut verschickt werden.`} />
        </Box>
      )}

      <Button
        onClick={switchMode ? submitSwitch : submitMove}
        disabled={disabled}
      >
        Bestätigen
      </Button>
    </>
  );
}

TicketMove.propTypes = {
  ticket: PropTypes.object,
  blockCabin: PropTypes.bool.isRequired,
  toggleBlockCabin: PropTypes.func.isRequired,
  blockReason: PropTypes.string.isRequired,
  setBlockReason: PropTypes.func.isRequired,
  cabins: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentCabin: PropTypes.object.isRequired,
  selectedCabin: PropTypes.string.isRequired,
  setSelectedCabin: PropTypes.func.isRequired,
  submitMove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default TicketMove;
