import React from 'react';
import SummaryBox from "./SummaryBox";

const SummaryBoxProvider = ({ summary, info, infoLight = false, prices = [], errorMessage = '', warningMessage = '' }) => {
  return (
    <SummaryBox
      summary={summary}
      info={info}
      infoLight={infoLight}
      prices={prices}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
    />
  );
}

SummaryBoxProvider.propTypes = {}

export default SummaryBoxProvider;
