import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  popup: {
    width: 'calc(100% - 24px)',
    margin: 12,
    borderRadius: theme.spacing(2),
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  banner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 64,
    zIndex: 1000,
    paddingTop: theme.spacing(5 / 2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 19 / 14,
    '& + .MuiGrid-container': {
      paddingTop: 64,
      overflowY: 'auto'
    }
  },
  bannerHigher: {
    height: 90,
    alignItems: "flex-start",
    '& + .MuiGrid-container': {
      paddingTop: 90,
    },
    '@media (min-width:600px)': {
      height: 64,
      '& + .MuiGrid-container': {
        paddingTop: 64,
      },
    }
  },
  bannerActive: {
    background: theme.palette.checked
  },
  bannerCancelled: {
    background: '#d32f2f'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  resendEmailChip: {
    height: '32px',
    padding: `6px 10px`,
    color: '#fff',
    background: 'rgba(255,255,255,0.14)',
  },
  resendEmailChipWarning: {
    color: '#262626',
    background: 'rgba(255,255,255,0.85)',
    '&:hover': {
      // color: '#fff',
      background: 'rgba(255,255,255,0.95)',
    }
  },
  resendEmailIcon: {
    marginRight: theme.spacing(3),
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  editIcon: {
    marginRight: theme.spacing(3),
    cursor: 'pointer',
    marginLeft: 'auto',
    '@media (min-width:600px)': {
      marginLeft: '0',
    }
  },
  main: {
    width: '100%',
    background: '#F2F5F7',
    paddingBottom: theme.spacing(5 / 4),
  },
  boxArea: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: 0
  },
  bottomActions: {
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  link: {
    color: '#005286',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));
