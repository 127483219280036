import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "urql";
import { resetLoginPasswordMutation } from "../../lib/GraphQLQueries";
import { useNotificationPush } from "../Snackbar/SnackbarContext";

export const PasswordResetSetPassword = () => {
  const { resetToken } = useParams();
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const { pushSuccess, pushError } = useNotificationPush() || {}
  const navigate = useNavigate()

  const [state, resetPassword] = useMutation(resetLoginPasswordMutation)
  const submitForm = useCallback((e: React.FormEvent) => {
    e.preventDefault();

    if (state.fetching) {
      return
    }

    if (password === '') {
      pushError('Das neue Passwort darf nicht leer sein')
      return
    }

    if (passwordRepeat === '') {
      pushError('Bitte wiederhole das neue Passwort')
      return
    }

    if (password !== passwordRepeat) {
      pushError('Die eingegebenen Passwörter stimmen nicht überein')
      setPasswordRepeat('')
      return
    }

    resetPassword({
      input: {
        ResetToken: resetToken || '',
        Password: password
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
        setPasswordRepeat('')
      } else {
        pushSuccess('Passwort erfolgreich zurückgesetzt')
        navigate('/login/')
      }
    })
  }, [navigate, resetToken, password, passwordRepeat, pushSuccess, pushError, resetPassword, state])

  return (
    <form onSubmit={submitForm}>
      <Typography variant="h1" component="h1" gutterBottom>Passwort setzen</Typography>

      <TextField
        fullWidth
        type={'password'}
        variant={'filled'}
        value={password}
        onChange={e => setPassword(e.target.value)}
        label={'Neues Passwort'}
        sx={{marginBottom: 2}}
      />

      <TextField
        fullWidth
        type={'password'}
        variant={'filled'}
        value={passwordRepeat}
        onChange={e => setPasswordRepeat(e.target.value)}
        label={'Neues Passwort wiederholen'}
      />

      <Button type={"submit"} sx={{marginTop: 2}} disabled={state.fetching || password === '' || passwordRepeat === ''}>
        Passwort speichern
      </Button>
    </form>
  )
}
