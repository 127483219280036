import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./app/App";
import './index.css';
import { ServiceWorkerProvider } from "./app/features/ServiceWorker";
import reportWebVitals from './reportWebVitals';

const rootNode = document.getElementById('root') as HTMLElement

const root = ReactDOM.createRoot(rootNode);

root.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <App logoUrl={rootNode.getAttribute('data-logo-url') || ''} />
    </ServiceWorkerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
