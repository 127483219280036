import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useClient } from "urql";
import { CURRENT_WEBSOCKET_HOST } from "../../constants";
import { readOneTicketQuery, readOneTrainConnection, readOneTrainConnectionConfig } from "../../lib/GraphQLQueries";
import uuid from 'react-uuid'
import { useTimeOffset } from "../OfflineStatus";

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext)

export const Websocket = ({children}) => {
  const initRef = useRef(false)
  const [hasSocketConnection, setHasSocketConnection] = useState(false)
  const [emailMessageSentAmount, setEmailMessageSentAmount] = useState({})
  const client = useClient()
  const { isOnline } = useTimeOffset()

  useEffect(() => {
    if (!sessionStorage.getItem('socket-id')) {
      sessionStorage.setItem('socket-id', uuid())
    }
  }, [])

  const handleMessage = useCallback((message) => {
    let vars, query = null
    let hasData = null

    if (message.object === 'SW') {
      console.log('SERVICE WORKER UPDATE', message)
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then((registration) => {
            registration.update().then(r => console.log(r));
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    } else if (message.TicketNumber) {
      vars = { Ticketnumber: message.TicketNumber };
      query = readOneTicketQuery
      hasData = (value) => {
        const data = value.readOneTicket
        return Object.keys(data).findIndex(key => key !== 'TicketNumber' && data[key] !== null) >= 0
      }
    } else if (message.TrainConnectionID) {
      vars = { TrainConnectionID: message?.TrainConnectionID }
      query = message.ConfigOnly ? readOneTrainConnectionConfig : readOneTrainConnection
      hasData = (value) => {
        const data = value.readOneTrainConnection
        return Object.keys(data).findIndex(key => key !== 'ID' && data[key] !== null) >= 0
      }
    } else if (message.EmailMessage) {
      if (message.AmountSent) {
        setEmailMessageSentAmount(prev => ({
          ...prev,
          [message.EmailMessage]: message.AmountSent
        }))
      }
    }

    if (query) {
      const queryData = client.readQuery(query, vars)?.data

      if (hasData && hasData(queryData)) {
        // console.log('query', query, vars)
        client.query(query, vars).toPromise()
      } else {
        console.log('Ignored because of emtpy query', message)
      }
    }
  }, [client, setEmailMessageSentAmount])

  const connect = useCallback(() => {
    console.log('Try to connect')
    const ws = new WebSocket(CURRENT_WEBSOCKET_HOST);

    ws.addEventListener('open', () => {
      console.log('connected to ws');
      setHasSocketConnection(true)
    });

    ws.addEventListener('close', () => {
      console.log('disconnected');
      setHasSocketConnection(false)
      setTimeout(() => {
        if (isOnline) {
          connect()
        }
      }, 1000)
    });

    ws.addEventListener("message", event => {
      if (event.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          const result = JSON.parse(reader.result)

          // let stage = 'live'
          // if (window.location.host.includes('staging')) {
          //   stage = 'staging'
          // } else if (window.location.host.includes('test')) {
          //   stage = 'test'
          // } else if (window.location.host.includes('docker:3000')) {
          //   stage = 'dev'
          // }

          // if (result?.Stage === stage) {
          console.log('Recived:', result)
          console.log('Own socket id', sessionStorage.getItem('socket-id'))

          if (result.senderID !== sessionStorage.getItem('socket-id')) {
            handleMessage(result)
          } else {
            console.log('Ignore own message')
          }
          // } else {
          //   console.log('Stage ' + stage + ' ignored')
          // }
        };

        reader.readAsText(event.data);
      } else {
        const result = JSON.parse(event.data)

        if (result.socketID) {
          sessionStorage.setItem('socket-id', result.socketID)
        }
      }
    });

    ws.addEventListener('error', error => {
      ws.close();
      // setTimeout(() => connect(), 1000)
    })
  }, [handleMessage, isOnline])

  useEffect(() => {
    if (!initRef.current && isOnline && navigator.onLine) {
      initRef.current = true
      connect()
    }
  }, [connect, isOnline])

  console.log(hasSocketConnection)

  return (
    <WebSocketContext.Provider value={{
      hasSocketConnection,
      emailMessageSentAmount
    }}>
      {children}
    </WebSocketContext.Provider>
  )
}
