import { red } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  offline: {
    width: '100%',
    boxSizing: 'content-box',
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(-3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    backgroundColor: red[500],
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    overflow: 'hidden'
  },
}));
