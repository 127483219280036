import React from 'react';
import TicketMove from '../../../TicketMove'
import { useDialog } from '../CategoryBox'

const MoveCollection = ({ticket, collectionID, sameEntity}) => {
  const { onClose } = useDialog()

  return (
    <TicketMove
      ticket={ticket}
      collectionID={collectionID}
      sameEntity={sameEntity}
      handleBack={onClose}
    />
  );
}

MoveCollection.propTypes = {}

export default MoveCollection;
