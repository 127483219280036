import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import { useLogin } from '../Login/LoginContext'

const Logout = () => {
  const { setLoggedIn } = useLogin() || {}

  useEffect(() => {
    setLoggedIn(false)
  }, [setLoggedIn])

  return (
    <Backdrop open>
      <CircularProgress color={'primary'} />
    </Backdrop>
  );
}

export default Logout;
