import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import TableCell from "@mui/material/TableCell";
import React, { useMemo } from 'react';
import { RESEND_TICKET_INFO } from "../../../../infoText";
import SummaryBox from "../../../SummaryBox";
import CategoryDialog from "../CategoryBox/CategoryDialog";
import { useStyles } from "./styles";

const ResendTicketEmailDialog = ({ open, setOpen, sendAwayConfirmation, setSendAwayConfirmation, sendReturnConfirmation, setSendReturnConfirmation, confirmResendTicketEmail, pendingInvoiceItems, fetching, hasOppositeDirectionTicket }) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false);
  };

  const buttonLabel = useMemo(() => {
    if (pendingInvoiceItems.length > 0) {
      if (sendAwayConfirmation || (hasOppositeDirectionTicket && sendReturnConfirmation)) {
        return 'Rechnung + E-Mail senden';
      }

      return 'Rechnung senden';
    }

    return 'Bestätigung senden'
  }, [pendingInvoiceItems, sendAwayConfirmation, sendReturnConfirmation, hasOppositeDirectionTicket])

  return (
    <CategoryDialog
      enableEasyClosing
      open={open}
      onClose={handleClose}
      aria-labelledby="resend-ticket-email"
      aria-describedby="resend-ticket-email-description"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h2'}>
            <strong>
              Rechnung / Bestätigung senden
            </strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <SummaryBox
            info={RESEND_TICKET_INFO}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h3'} gutterBottom>Rechnungspositionen</Typography>

          {pendingInvoiceItems && pendingInvoiceItems.length > 0 ? (
            <TableContainer>
              <Table aria-label="collapsible table" size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <Typography fontWeight={'bold'} fontSize={14}>
                        Position
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell} width={80} align={'right'}>
                      <Typography fontWeight={'bold'} fontSize={14}>
                        Preis
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingInvoiceItems.map(item => (
                    <TableRow key={item.ID}>
                      <TableCell className={classes.cell}>
                        <Typography fontSize={14}>
                          {`${item.Title}${item.Description ? ' | ' : ''}${item.Description}`}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell} align={'right'}>
                        <Typography fontSize={14}>
                          {item.Price.Amount.toFixed(2).replace('.', ',')} €
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography fontSize={14}>
              Keine ausstehenden Rechnungspositionen
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h3'} gutterBottom>E-Mail Bestätigungen</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size={'small'}
                  checked={sendAwayConfirmation}
                  onChange={event => setSendAwayConfirmation(event.target.checked)}
                />
              }
              label="E-Mail Bestätigung Hinfahrt senden"
            />
            {hasOppositeDirectionTicket && (
              <FormControlLabel
                control={
                  <Checkbox
                    size={'small'}
                    checked={sendReturnConfirmation}
                    onChange={event => setSendReturnConfirmation(event.target.checked)}
                    />
                }
                label="E-Mail Bestätigung Rückfahrt senden"
              />
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <DialogActions>
            <Button onClick={handleClose} variant={'text'} disabled={fetching}>
              Abbrechen
            </Button>
            <Button onClick={confirmResendTicketEmail} disabled={fetching}>
              {fetching ? <CircularProgress /> : buttonLabel}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </CategoryDialog>
  );
}

ResendTicketEmailDialog.propTypes = {}

export default ResendTicketEmailDialog
