import {
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { CUSTOMER_STATUS_OPTIONS, LOCALE_MAPPING } from '../../../../constants'
import SummaryBox from "../../../SummaryBox";

import { useDialog } from '../CategoryBox';
import { useStyles } from './styles';

const PersonalDataEdit = ({ changedCustomer, fetching, submit, changeCustomer, disabled }) => {
  const classes = useStyles();
  const { onClose } = useDialog();

  return (
    <>
      <Typography variant={'h2'} gutterBottom sx={{marginBottom: 4}}>
        <strong>
          Persönliche Daten bearbeiten
        </strong>
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'Vorname'}
            value={changedCustomer.Firstname}
            onChange={e => changeCustomer('Firstname', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'Nachname'}
            value={changedCustomer.Lastname}
            onChange={e => changeCustomer('Lastname', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'Telefonnnummer'}
            value={changedCustomer.Phone}
            onChange={e => changeCustomer('Phone', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'Straße'}
            value={changedCustomer.Street}
            onChange={e => changeCustomer('Street', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'Postleitzahl'}
            value={changedCustomer.Zip}
            onChange={e => changeCustomer('Zip', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'Stadt'}
            value={changedCustomer.City}
            onChange={e => changeCustomer('City', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id={`country-input-label`}>{'Land'}</InputLabel>
            <Select
              labelId={`country-input-label`}
              value={changedCustomer.Country || ''}
              onChange={e => changeCustomer('Country', e.target.value)}
            >
              <MenuItem value={'DE'}>Deutschland</MenuItem>
              <MenuItem value={'AT'}>Österreich</MenuItem>
              <MenuItem value={'IT'}>Italien</MenuItem>
              <MenuItem value={'CH'}>Schweiz</MenuItem>
              <MenuItem value={'DK'}>Dänemark</MenuItem>
              <MenuItem value={'UK'}>Vereinigtes</MenuItem>
              <MenuItem value={'SE'}>Schweden</MenuItem>
              <MenuItem value={'NO'}>Norwegen</MenuItem>
              <MenuItem value={'FI'}>Finnland</MenuItem>
              <MenuItem value={'FR'}>Frankreich</MenuItem>
              <MenuItem value={'ES'}>Spanien</MenuItem>
              <MenuItem value={'LI'}>Liechtenstein</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant={'filled'}
            label={'E-Mail'}
            value={changedCustomer.Email}
            onChange={e => changeCustomer('Email', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id={`languague-input-label`}>{'Sprache'}</InputLabel>
            <Select
              labelId={`country-input-label`}
              value={changedCustomer.CustomerLocale || ''}
              onChange={e => changeCustomer('CustomerLocale', e.target.value)}
            >
              {Object.keys(LOCALE_MAPPING).map(locale => (
                <MenuItem key={locale} value={locale}>{LOCALE_MAPPING[locale]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id={`customer-status-input-label`}>{'Kundenstatus'}</InputLabel>
            <Select
              labelId={`customer-status-input-label`}
              value={changedCustomer.CustomerStatus || ''}
              onChange={e => changeCustomer('CustomerStatus', e.target.value)}
            >
              <MenuItem value={''}>{'Kein Status'}</MenuItem>
              {CUSTOMER_STATUS_OPTIONS.map(option => (
                <MenuItem value={option.key} key={option.key}>{option.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <SummaryBox
            info={
              <strong>
                Änderungen gelten für Hin- und Rückfahrt. Es wird keine Email verschickt.
              </strong>
            }
          />
        </Grid>
      </Grid>

      <DialogActions className={classes.dialogActions}>
        <Button variant={'text'} onClick={onClose}>Abbrechen</Button>
        <Button onClick={submit} disabled={disabled}>
          {fetching
            ? <CircularProgress />
            :'Bestätigen'
          }
        </Button>
      </DialogActions>
    </>
  );
}

PersonalDataEdit.propTypes = {}

export default PersonalDataEdit;
