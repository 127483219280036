import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { PasswordResetRequest } from "../features/PasswordResetRequest";
import { PasswordResetSetPassword } from "../features/PasswordResetSetPassword";

const PasswordResetPage = () => {
  const routes = useRoutes([
    {
      path: '/:resetToken/',
      element: <PasswordResetSetPassword />
    },
    {
      index: true,
      element: <PasswordResetRequest />
    }
  ])

  return (
    <Dialog open fullWidth maxWidth={'sm'}>
      <DialogContent>
        {routes}
      </DialogContent>
    </Dialog>
  );
}

PasswordResetPage.propTypes = {}

export default PasswordResetPage;
