import { EditOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Chip, Grid, IconButton, Paper, Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

const CategoryBox = ({ title, children, boxOpen, toggleBox, toggleDialog, hasDialog, noPadding, smallFont, isBlocked, chipContent, chipIcon }) => {
  const classes = useStyles();

  return (
    <Paper variant={'elevation'} className={noPadding ? classes.boxContentNoPadding : classes.boxContent}>
      <Grid container>
        <Grid item xs={9} display={'flex'} alignItems={'center'}>
          <Typography variant={smallFont ? 'h3' : 'h2'} style={{fontWeight: 'bold'}}>{title}</Typography>
          {chipContent && (
            <Chip
              sx={{marginLeft: 1.5, fontSize: 12, marginTop: '1px'}}
              size={"small"}
              color={'success'}
              label={chipContent}
              icon={chipIcon}
              fontSize={"small"}
              variant={"outlined"}
            />
          )}
        </Grid>
        <Grid item xs={3} style={{textAlign: 'right'}}>
          {hasDialog && (
            <IconButton size={'small'} onClick={toggleDialog} disabled={isBlocked}>
              <EditOutlined />
            </IconButton>
          )}
          {children && (
            <IconButton size={'small'} onClick={toggleBox}>
              {boxOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Grid>
        {boxOpen && (
          <Grid item xs={12} className={classes.container}>
            {children}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

CategoryBox.propTypes = {}

export default CategoryBox;
