import React from 'react';

import Logout from '../features/Logout'

const LogoutPageProvider = () => {
  return <Logout />;
}

LogoutPageProvider.propTypes = {}

export default LogoutPageProvider;
