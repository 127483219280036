import PropTypes from 'prop-types';
import React from 'react';
import TicketDetailsContext from './TicketDetailsContext'

import TicketDetailsPopup from './TicketDetailsPopup';

const TicketDetailsProvider = ({ children }) => {
  return (
    <>
      <TicketDetailsContext>
        {children}
        <TicketDetailsPopup />
      </TicketDetailsContext>
    </>
  );
}

TicketDetailsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default TicketDetailsProvider;
