import { Check } from "@mui/icons-material";
import { Chip, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { BIKE_RACK_LABEL, ROOF_BOX_LABEL, VEHICLE_TYPES } from '../../../../constants';
import VehicleDetail from './components/VehicleDetail';

const Vehicles = ({ vehicles }) => {
  const getRoofLuggageTitle = useCallback(type => {
    switch (type) {
      case 'box': return ROOF_BOX_LABEL;
      case 'rack-empty': return 'Dachträger leer';
      case 'rack-loaded': return 'Dachträger beladen';
      default: return 'Keins';
    }
  }, [])

  return (
    <Grid container spacing={2}>
      {vehicles.map((vehicle, index) => (
        <Grid item xs={12} key={vehicle.ID}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={'flex'} alignItems={'center'}>
              <Typography variant={'h3'}>
                {`${index + 1}. ${VEHICLE_TYPES[vehicle.Type]?.title || 'Fahrzeug'}`}
              </Typography>
              {vehicle.Checked && (
                <Chip
                  sx={{marginLeft: 1.5, fontSize: 12, marginTop: '1px'}}
                  size={"small"}
                  color={'success'}
                  label={`${vehicle.Checked.format('HH:mm')} Uhr`}
                  icon={<Check fontSize={"small"} />}
                  variant={"outlined"}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <VehicleDetail title={'Amtliches Kennzeichen'} value={vehicle.LicensePlate} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Hersteller'} value={vehicle.Manufacturer} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Modell'} value={vehicle.VehicleModel} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Fahrzeuglänge (cm)'} value={vehicle.Length} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Fahrzeughöhe (cm)'} value={vehicle.Height} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Dachbreite (cm)'} value={vehicle.RoofWidth} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Leergewicht (kg)'} value={vehicle.EmptyWeight} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={'Dachgepäck'} value={getRoofLuggageTitle(vehicle.RoofLuggage)} />
            </Grid>
            <Grid item xs={6}>
              <VehicleDetail title={BIKE_RACK_LABEL} value={vehicle.HasBikeRack ? 'JA' : 'NEIN'} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

Vehicles.propTypes = {
  vehicles: PropTypes.array.isRequired
}

export default Vehicles;
