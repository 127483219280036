import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from 'urql';
import { releaseTicketMutation } from '../../../../lib/GraphQLQueries'
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'

import Release from './Release'
import ReleaseDialog from './ReleaseDialog'

const ReleaseProvider = ({ ticket, onClose, disabled }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [markAsReleased] = useState(true);
  const [, releaseTicket] = useMutation(releaseTicketMutation)
  const { pushSuccess, pushError } = useNotificationPush()

  const confirmTicketRelease = () => {
    setDialogOpen(false)
    releaseTicket({
      Ticketnumber: ticket.TicketNumber,
      MarkAsReleased: markAsReleased
    }).then(res => {
      if (!res.error) {
        pushSuccess(markAsReleased ? 'Als "Fahrgast nicht erschienen" markiert' : 'Plätze freigegeben')
      } else {
        pushError(res.error.message)
      }
    })
  }

  return (
    <>
      <Release
        setDialogOpen={setDialogOpen}
        onClose={onClose}
        disabled={disabled}
      />
      <ReleaseDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        confirmTicketRelease={confirmTicketRelease}
        markAsReleased={markAsReleased}
      />
    </>
  );
}

ReleaseProvider.propTypes = {
  ticket: PropTypes.object.isRequired
}

export default ReleaseProvider;
