import { Undo } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

import { useStyles } from '../styles';

const LineItem = ({ itemKey, title, price, removeAction }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.border}>
      <Grid container className={classes.row}>
        <Grid item xs={7} md={8} className={classes.title}>
          <Typography>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={5} md={4} className={classes.price}>
          {removeAction && (
            <IconButton onClick={() => removeAction(itemKey)} size={'small'}>
              <Undo />
            </IconButton>
          )}
          {price !== undefined && (
            <span className={classes.priceString}>{price.toFixed(2).replace('.', ',')} €</span>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

LineItem.propTypes = {}

export default LineItem;
