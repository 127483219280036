import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  date: {
    lineHeight: 20 / 14
  },
  message: {
    fontWeight: 400,
    lineHeight: 20 / 14
  }
}));
