import { Box, Grid, useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react';

type PropTypes = {
  children: React.ReactNode
  fullWidth?: boolean
  maxWidth?: string
}

const SingleColumn = ({ children, fullWidth, maxWidth = 'lg' }: PropTypes) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('lg'));

  return (
    <Grid item flexGrow={1} style={{maxHeight: 'calc(100vh - 65px)', margin: isMobile ? '0' : '45px 0'}}>
      <Box maxWidth={!fullWidth ? maxWidth : (!isMobile ? 'calc(100vw - 128px - 64px)' : '100%')} sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: isMobile ? 0 : 4,
        paddingLeft: isMobile ? 0 : 4,
      }}>
        {children}
      </Box>
    </Grid>
  );
}

export default SingleColumn;
