import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    '&.MuiContainer-root': {
      minHeight: '100vh',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(15)
    }
  },
  header: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
  },
  member: {
    fontWeight: '400 !important'
  }
}));
