import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation, useQuery } from 'urql';
import { changeAddOnAnswerMutation, readAddOnTypeComponents } from '../../../../lib/GraphQLQueries'
import { useTimeOffset } from '../../../OfflineStatus'

import Breakfast from './Breakfast'

const BreakfastProvider = ({ ticket, bookingCollectionID }) => {
  const [addOnComponents] = useQuery({ query: readAddOnTypeComponents })
  const [state, executeAddOnChange] = useMutation(changeAddOnAnswerMutation)
  const { timeOffset } = useTimeOffset()

  const changeAnswer = ({ answerID, addOnID, componentID, optionID, optionTitle }) => {
    executeAddOnChange({
      AddOnAnswerID: answerID,
      AddOnID: addOnID,
      ComponentID: componentID,
      OptionID: optionID,
      OptionTitle: optionTitle,
      Timestamp: moment().add(timeOffset, 'seconds').format('x')
    });
  }

  if (!bookingCollectionID) {
    return null
  }

  return (
    <>
      <Breakfast
        addOns={ticket.AddOns || []}
        addOnAnswers={ticket.AddOnAnswers?.filter(answer => answer.BookingCollectionID === bookingCollectionID) || []}
        addOnComponents={addOnComponents.data ? addOnComponents.data.readAddOnTypeComponents : []}
        changeAnswer={changeAnswer}
        disabled={state.fetching}
        readOnly={!ticket.CanEdit}
      />
    </>
  );
}

BreakfastProvider.propTypes = {
  ticket: PropTypes.object.isRequired,
}

export default BreakfastProvider;
