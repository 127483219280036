import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import ButtonContent from './layout/ButtonContent';

const MultiCheckInButton = ({ ticket, checkinType, checkedIn, readOnly, alternativeText, validateTicket, validateVehicleSlot }) => {
  const [open, setOpen] = useState(false)

  return (
    <Grid container spacing={open ? 2 : 0}>
      {open && checkinType.includes('vehicle') && (
        <>
          <Grid item xs={12}>
            <Typography sx={{fontWeight: 'bold'}}>Fahrzeug-Checkin</Typography>
          </Grid>
          {ticket.VehicleSlots.map(vehicleSlot => (
            <Grid item xs={12} onClick={() => validateVehicleSlot(ticket.IsReturn ? vehicleSlot.ReturnID : vehicleSlot.ID)}>
              <ButtonContent
                size={'small'}
                checkedIn={vehicleSlot.Checked}
                readOnly={readOnly}
                alternativeText={readOnly ? alternativeText : vehicleSlot.LicensePlate}
                alternativeCheckedInText={vehicleSlot.LicensePlate}
              />
            </Grid>
          ))}
        </>
      )}
      {open && checkinType.includes('person') && (
        <>
          <Grid item xs={12}>
            <Typography sx={{fontWeight: 'bold'}}>Zug-Checkin</Typography>
          </Grid>
          <Grid item xs={12} onClick={validateTicket}>
            <ButtonContent
              size={'small'}
              checkedIn={ticket.Checked}
              readOnly={readOnly}
              alternativeText={readOnly ? alternativeText : (alternativeText || 'Personen')}
              alternativeCheckedInText={alternativeText || 'Personen'}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} sx={{marginTop: 2}}>
        <Button
          variant={'text'}
          startIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          onClick={() => setOpen(prev => !prev)}
        >
          Checkin
        </Button>
      </Grid>
    </Grid>
  );
}

MultiCheckInButton.propTypes = {}

export default MultiCheckInButton;
