import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BreakfastItem from './BreakfastItem';

const Breakfast = ({ addOns, addOnAnswers, addOnComponents, changeAnswer, disabled, readOnly }) => {
  const groupedAddOnAnswers = addOnAnswers?.reduce((acc, item) => {
    acc[item.AddOnComponent.ID] = acc[item.AddOnComponent.ID] !== undefined
      ? [...acc[item.AddOnComponent.ID], item]
      : [item]

    return acc
  }, {}) || {}

  return (
    <Grid container>
      {Object.values(groupedAddOnAnswers).map(addOnGroup => {
        return addOnGroup.map((addOnAnswers, i) => {
          const changeItemAnswer = ({ answerID, optionID, optionTitle }) =>
            changeAnswer({ addOnID: addOnAnswers.AddOnID, componentID: addOnAnswers.AddOnComponent.ID, answerID, optionID, optionTitle })

          return (
            <BreakfastItem
              key={`${addOnAnswers.AddOnComponent.ID}${i}`}
              number={i + 1}
              answer={addOnAnswers}
              component={addOnAnswers.AddOnComponent}
              changeItemAnswer={changeItemAnswer}
              disabled={disabled}
              readOnly={readOnly}
            />
          )
        })
      })}
    </Grid>
  )
}

Breakfast.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.object).isRequired,
  addOnAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  addOnComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeAnswer: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default Breakfast;
