import DoneIcon from '@mui/icons-material/Done';
import { Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const BreakfastType = ({ title, checked, changeItemAnswer, disabled }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Chip
        color={checked ? 'primary' : 'default'}
        icon={checked ? <DoneIcon /> : null}
        label={title}
        onClick={changeItemAnswer}
        disabled={disabled}
        clickable
        className={classes.button}
      />
    </Grid>
  );
};

BreakfastType.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  changeItemAnswer: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default BreakfastType;
