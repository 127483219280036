import PropTypes from 'prop-types';
import React, { createContext, useRef, useState } from 'react';

const ServiceValuesContext = createContext({});

const ServiceValuesContextWrapper = ({ children }) => {
  const serviceValuesChecked = useRef([]);
  const serviceValuesCheckable = useRef([]);

  const [serviceValuesTotalPrices, setServiceValuesTotalPrices] = useState({});

  return (
    <ServiceValuesContext.Provider value={
      {
        serviceValuesChecked,
        serviceValuesCheckable,
        serviceValuesTotalPrices,
        setServiceValuesTotalPrices
      }
    }
    >
      {children}
    </ServiceValuesContext.Provider>
  );
};

ServiceValuesContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export {
  ServiceValuesContextWrapper,
  ServiceValuesContext
};
