import { ClassNameMap, Container } from '@mui/material';
import React from 'react';
import { useLogin } from "../../features/Login/LoginContext";
import { NavigationListType } from "../../types/NavigationListType";
import LayoutFooter from '../components/LayoutFooter'
import LayoutHeader from '../components/LayoutHeader'

type PropTypes = {
  children: React.ReactNode
  logoUrl: string
  classes: ClassNameMap
  navigationList: NavigationListType[]
  subNavigationList: NavigationListType[]
}

const MobileView = ({ logoUrl, classes, children, navigationList, subNavigationList }: PropTypes) => {
  const { isLoggedIn } = useLogin() || {}

  if (!isLoggedIn) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <>
      <Container className={classes.wrapper}>
        <LayoutHeader
          logoUrl={logoUrl}
          classes={classes}
        />
        {children}
      </Container>
      <LayoutFooter
        navigationList={navigationList}
        subNavigationList={subNavigationList}
      />
    </>
  );
}

export default MobileView;
