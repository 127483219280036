import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  current: {
    paddingTop: theme.spacing(1)
  },
  field: {
    width: '100%',
    minWidth: 120,
    paddingBottom: theme.spacing(1)
  },
  checkbox: {
    '&>.MuiFormControlLabel-label': {
      fontSize: 16,
      lineHeight: 22 / 16
    }
  },
  textfield: {
    backgroundColor: '#F2F5F7',
    borderRadius: '5px 5px 0 0',
    overflow: 'hidden',
    '&>label': {
      fontSize: 16,
      lineHeight: 22 / 16,
      transform: 'translate(16px, 22px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(16px, 10px) scale(0.75)'
      }
    },
    '&>div': {
      borderRadius: '5px 5px 0 0',
      backgroundColor: 'rgba(0,0,0,0)',
      paddingRight: theme.spacing(1),
      '&::before': {
        borderColor: '#CCCCCC'
      },
      '&>input': {
        height: 54,
        paddingTop: 12,
        paddingRight: theme.spacing(2),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2)
      }
    }
  },
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: 0,
    borderBottom: '1px solid #CCCCCC'
  },
  select: {
    width: '100%',
    minWidth: 120,
    marginBottom: theme.spacing(3),
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#F2F5F7',
    overflow: 'hidden',
    '&>label': {
      fontSize: 16,
      lineHeight: 22 / 16,
      transform: 'translate(16px, 22px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(16px, 8px) scale(0.75)'
      }
    },
    '&>div': {
      borderRadius: '5px 5px 0 0',
      backgroundColor: 'rgba(0,0,0,0)',
      paddingRight: theme.spacing(1),
      '&::before': {
        borderColor: '#CCCCCC'
      },
      '&>div': {
        height: 36,
        paddingTop: 28,
        paddingRight: theme.spacing(2),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2)
      },
      '&>svg': {
        marginTop: theme.spacing(1 / 2)
      },
    }
  }
}));
