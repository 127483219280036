import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';

import { useStyles } from './styles';

const CabinSwitcher = ({ entityGroups, currentCabin, setCurrentCabin }) => {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Tabs
        value={currentCabin}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        indicatorColor="transparent"
        className={classes.tabs}
      >
        {entityGroups.map((entityGroup, index) => (
          <Tab
            disableRipple
            key={entityGroup.BookingCollectionID}
            onClick={() => setCurrentCabin(index)}
            className={classes.tabSelected}
            label={`Wg. ${entityGroup.WagonNumber} / Abt. ${entityGroup.CabinNumber}`}
          />
        ))}
      </Tabs>
    </Box>
  );
}

CabinSwitcher.propTypes = {}

export default CabinSwitcher;
