import { Grid, Skeleton, Typography } from '@mui/material';
import React, { useMemo } from 'react';

const SummaryProvider = ({ ticket }) => {
  const adultsAmount = useMemo(() => {
    return ticket?.EntityGroups?.reduce((acc, item) => {
      return acc += item.AdultsAmount
    }, 0)
  }, [ticket.EntityGroups])

  const childrenAmount = useMemo(() => {
    return ticket?.EntityGroups?.reduce((acc, item) => {
      return acc += item.ChildrenAmount
    }, 0)
  }, [ticket.EntityGroups])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Abteile</Typography>
        {ticket?.EntityGroups === undefined || ticket?.EntityGroups === null
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{ticket.EntityGroups.length}</Typography>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Fahrzeuge</Typography>
        {ticket?.VehicleSlots === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{ticket.VehicleSlots?.length}</Typography>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Erwachsene (Gesamt)</Typography>
        {adultsAmount === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{adultsAmount}</Typography>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Kinder (Gesamt)</Typography>
        {childrenAmount === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{childrenAmount}</Typography>
        }
      </Grid>
    </Grid>
  );
}

SummaryProvider.propTypes = {}

export default SummaryProvider;
