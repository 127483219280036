import { MenuItem } from '@mui/material';
import React from 'react';

const Release = ({ setDialogOpen, onClose, disabled }) => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={() => {
        setDialogOpen(true)
        onClose()
      }}>
      Fahrgast nicht erschienen
    </MenuItem>
  )
}

Release.propTypes = {}

export default Release;
