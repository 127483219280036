import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { useNotificationPush } from '../../features/Snackbar/SnackbarContext';
import { cancelServiceMutation, readOneticketPaymentsQuery, syncCancelStateTicketMutation } from '../../lib/GraphQLQueries';
import ServiceValues from './ServiceValues';
import { ServiceValuesContext } from './ServiceValuesContext';

const ServiceValuesProvider = ({ ticketNumber, allowCancel = false, closeDialog = null }) => {
  const { pushError } = useNotificationPush();

  const {
    serviceValuesChecked,
    serviceValuesCheckable,
    serviceValuesTotalPrices,
    setServiceValuesTotalPrices
  } = useContext(ServiceValuesContext);

  const [refundRate, setRefundRate] = useState(50);

  /*
   * Query for fetching the ticket
   */
  const [ticketPayments, executeReadOneTicket] = useQuery({
    query: readOneticketPaymentsQuery,
    pause: !ticketNumber,
    variables: {
      Ticketnumber: ticketNumber,
      IncludeInactiveEntities: true
    },
    requestPolicy: 'network-only'
  });

  /*
   * Mutation that executes the cancel-state sync
   */
  const [
    syncCancelStateTicketResult,
    executeSyncCancelStateTicketMutation
  ] = useMutation(syncCancelStateTicketMutation);

  /*
   * Mutation that executes the service cancellations
   */
  const [
    cancelServicesMicroaction,
    executeCancelServiceMicroaction
  ] = useMutation(cancelServiceMutation);

  /*
   * React on refund rate changes,
   * reset the current total-price
   */
  useEffect(() => {
    setServiceValuesTotalPrices({});
  }, [refundRate, setServiceValuesTotalPrices]);

  /*
   * Dynamic refundrate based on number of days till start
   */
  useEffect(() => {
    if (!ticketPayments.fetching && !ticketPayments.error && ticketNumber !== '' && ticketPayments.data) {
      const $daysTillStartForthDirection = ticketPayments.data.readOneTicket.IsReturn ? ticketPayments.data.readOneTicket.OppositeDirectionTicket.DaysUntilDeparture : ticketPayments.data.readOneTicket.DaysUntilDeparture;
      let $refundRate = 50;

      if ($daysTillStartForthDirection >= 60) {
        $refundRate = 90;
      } else if ($daysTillStartForthDirection >= 15) {
        $refundRate = 60;
      } else {
        $refundRate = 0;
      }

      setRefundRate($refundRate);
    }
  }, [ticketPayments, ticketNumber]);

  /*
   * Execute the cancellation
   */
  const executeCancellation = () => executeCancelServiceMicroaction({
    CancellationFeePercentage: (100 - refundRate),
    Tickets: Object.keys(serviceValuesTotalPrices).map(ticketId => ({
      TicketNumber: ticketId,
      Services: Object.values(serviceValuesTotalPrices[ticketId]).map(cancelPrice => ({
        ServiceID: cancelPrice.ID,
        Type: cancelPrice.Type
      }))
    })),
    IncludeInactiveEntities: true
  }).then(() => {
    // Reset the total cancel price and discount adjustment and refetch the ticket
    setServiceValuesTotalPrices({});
    serviceValuesChecked.current = [];

    // Close the cancellation view after the cancellation is done
    if (closeDialog !== null && !cancelServicesMicroaction.error) {
      closeDialog();
    }
  });

  /*
   * Execute the cancel-state sync
   */
  const executeSyncCancelState = (ticketNumber) => executeSyncCancelStateTicketMutation({
    TicketNumber: ticketNumber,
  }).then(() => {
    // Reset the total cancel price and discount adjustment and refetch the ticket
    setServiceValuesTotalPrices({});
    serviceValuesChecked.current = [];

    // Refetch ticket state
    executeReadOneTicket();
  });

  if (ticketPayments.error && ticketNumber !== '') {
    pushError(ticketPayments.error.message);
  }

  if (ticketPayments.fetching) {
    return <div><CircularProgress /></div>;
  }

  return (
    <ServiceValues
      allowCancel={allowCancel}
      ticket={ticketPayments.data ? ticketPayments.data.readOneTicket : {}}
      serviceValuesChecked={serviceValuesChecked}
      serviceValuesCheckable={serviceValuesCheckable}
      serviceValuesTotalPricesState={{
        serviceValuesTotalPrices,
        setServiceValuesTotalPrices
      }}
      refundRateState={{
        refundRate,
        setRefundRate
      }}
      executeCancellation={executeCancellation}
      cancellationIsExecuting={cancelServicesMicroaction.fetching}
      executeSyncCancelState={executeSyncCancelState}
      syncCancelStateTicketIsExecuting={syncCancelStateTicketResult.fetching}
      closeDialog={closeDialog}
    />
  );
};

ServiceValuesProvider.propTypes = {
  ticketNumber: PropTypes.string,
  allowCancel: PropTypes.bool,
  closeDialog: PropTypes.func
};

export default ServiceValuesProvider;
