import AlarmIcon from '@mui/icons-material/Alarm';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const WakeUpTime = ({ time, handleTimeChange, disabled }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment} locale={'de'}>
          <FormControl className={classes.formControlHolder} variant={'filled'} fullWidth>
            <MobileTimePicker
              okText={'OK'}
              cancelText={'Zurück'}
              clearable
              clearText={'Löschen'}
              disabled={disabled}
              disableCloseOnSelect={false}
              label={'Weckzeit auswählen'}
              value={time}
              minutesStep={5}
              ampm={false}
              orientation={'portrait'}
              onChange={(newValue) => handleTimeChange(newValue)}
              renderInput={(params) => (
                <TextField
                  variant={'filled'}
                  className={classes.datepicker}
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AlarmIcon />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

WakeUpTime.propTypes = {
  timer: PropTypes.object,
  handleTimeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default WakeUpTime;
