import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  tabs: {
    minHeight: 'auto',
  },
  tabSelected: {
    padding: '9px 18px',
    textTransform: 'none',
    minHeight: 'auto',

    '&.Mui-selected': {
      background: theme.palette.background.default,
      borderRadius: 8,
      color: '#005286',
    }
  }
}));
