import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useQuery } from 'urql';
import { useNotificationPush } from '../../features/Snackbar/SnackbarContext';
import { readOneTicketQuery } from '../../lib/GraphQLQueries';
import { useStyles } from './styles';

import TicketDetails from './TicketDetails';
import { useTicketNumber, useTicketNumberUpdate } from './TicketDetailsContext';

const TicketDetailsPopup = () => {
  const classes = useStyles()
  const ticketNumber = useTicketNumber()
  const updateTicketNumber = useTicketNumberUpdate()
  const { pushError } = useNotificationPush()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const [ticket, reloadTicket] = useQuery({
    query: readOneTicketQuery,
    pause: !ticketNumber,
    variables: {
      Ticketnumber: ticketNumber
    },
  })

  /**
   * close ticket detail view
   */
  const handleBack = () => {
    updateTicketNumber('')
  }

  const switchToRebookedTicket = () => {
    updateTicketNumber(ticket.data?.readOneTicket.RebookedTicket.TicketNumber || '')
  }

  if (ticket.error && ticketNumber !== '') {
    handleBack()
    pushError(ticket.error.message.replace('[GraphQL] ', ''))
  }

  return (
    <Dialog
      fullWidth
      maxWidth={isMobile ? 'sm' : 'md'}
      PaperProps={{ className: classes.popup }}
      open={!ticket.error && ticketNumber !== ''}
      onClose={handleBack}
      aria-labelledby="ticket-details"
    >
      <TicketDetails
        ticket={ticket.data ? ticket.data.readOneTicket : {}}
        handleBack={handleBack}
        switchToRebookedTicket={switchToRebookedTicket}
        reloadTicket={reloadTicket}
      />
    </Dialog>
  );
}

TicketDetailsPopup.propTypes = {}

export default TicketDetailsPopup;
