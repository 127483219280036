import { Close, Search } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import React from "react";

const MetaSearch = ({ searchText, setSearchText, open, onFocus, onClose, inputRef }) => {
  return (
    <>
      <Box sx={{ flexGrow: 0, paddingLeft: '120px', paddingRight: '12px', whiteSpace: 'noWrap', zIndex: 2,}}>
        {open ? (
          <Box onClick={onClose} sx={{cursor: 'pointer'}}>
            <Close />
          </Box>
        ) : (
          <Search />
        )}
      </Box>
      <Box sx={{ flexGrow: 1, paddingRight: '12px', whiteSpace: 'nowrap', zIndex: 2 }}>
        <TextField
          fullWidth
          value={searchText}
          placeholder={'Suche nach Ticketnummer, Name, Email'}
          variant={'standard'}
          InputProps={{ disableUnderline: true, autoComplete: 'off' }}
          onChange={e => setSearchText(e.target.value)}
          onClick={onFocus}
          onFocus={onFocus}
          inputRef={inputRef}
        />
      </Box>
    </>
  )
}

export default MetaSearch
