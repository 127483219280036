import { Typography } from "@mui/material";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CABINREBOOK_OPEN_KEY, VEHICLE_MIRCOACTION_OPEN_KEY } from '../../../../constants'
import { BLOCKED_MICROACTION_HEADLINE, BLOCKED_MICROACTION_INFO } from "../../../../infoText";
import SummaryBox from "../../../SummaryBox";
import { useTicketActions } from '../../TicketDetails'
import CategoryBox from './CategoryBox';

import CategoryDialog from './CategoryDialog';

const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext)

const CategoryBoxProvider = ({
                               children,
                               ticket,
                               title,
                               boxID,
                               dialogContent,
                               initialOpen,
                               initialDialogOpen = false,
                               dialogSize = 'md',
                               ticketActionKey,
                               noPadding,
                               smallFont,
                               chipContent,
                               chipIcon
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const modalPreset = urlParams.get('modal') || ''

  const { ticketActionOptions, setTicketActionOptions } = useTicketActions()

  const [boxOpen, setBoxOpen] = useState(() => initialOpen)
  const [dialogOpen, setDialogOpen] = useState(() => initialDialogOpen)
  const [enableEasyClosing, setEnableEasyClosing] = useState(true)

  const toggleBox = useCallback(() => setBoxOpen(prev => !prev), [setBoxOpen])
  const toggleDialog = useCallback(() => setDialogOpen(prev => !prev), [setDialogOpen])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
    setTicketActionOptions(prev => ({ ...prev, [ticketActionKey]: false }))
  }, [setTicketActionOptions, ticketActionKey])

  const isBlocked = useMemo(() => boxID && ticket?.BlockedMicroactions?.includes(boxID), [ticket, boxID])

  useEffect(() => {
    if (ticketActionOptions[ticketActionKey]) {
      setDialogOpen(true)
    }
  }, [ticketActionKey, ticketActionOptions])

  useEffect(() => {
    switch (modalPreset) {
      case 'vehicle':
        setTicketActionOptions({[VEHICLE_MIRCOACTION_OPEN_KEY]: true})
        break;
      case 'cabin':
        setTicketActionOptions({[CABINREBOOK_OPEN_KEY]: true})
        break;
      default:
        return
    }
  }, [modalPreset, setTicketActionOptions])

  return (
    <>
      <CategoryBox
        smallFont={smallFont}
        title={title}
        boxOpen={boxOpen}
        toggleBox={toggleBox}
        toggleDialog={toggleDialog}
        hasDialog={dialogContent}
        noPadding={noPadding}
        isBlocked={isBlocked}
        chipContent={chipContent}
        chipIcon={chipIcon}
      >
        {children}
      </CategoryBox>
      <CategoryDialog
        enableEasyClosing={enableEasyClosing}
        dialogSize={dialogSize}
        open={dialogOpen}
        onClose={enableEasyClosing ? handleClose : null}
      >
        <DialogContext.Provider value={{
          onClose: () => setDialogOpen(false),
          setEnableEasyClosing: value => setEnableEasyClosing(value)
        }}>
          {isBlocked ? (
            <>
              <Typography variant={'h2'} gutterBottom sx={{marginBottom: 2}}>
                <strong>{BLOCKED_MICROACTION_HEADLINE}</strong>
              </Typography>

              <SummaryBox
                info={BLOCKED_MICROACTION_INFO}
              />
            </>
          ) : dialogContent}
        </DialogContext.Provider>
      </CategoryDialog>
    </>
  );
}

CategoryBoxProvider.propTypes = {}

export default CategoryBoxProvider;
