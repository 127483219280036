import React, { useState } from 'react';
import { useMutation } from 'urql';
import { loginMutation } from '../../lib/GraphQLQueries'
import { CLIENT_ID } from '../../lib/OAuth2';
import { useNotificationPush } from '../Snackbar/SnackbarContext'

import Login from './Login'
import { useLogin } from "./LoginContext";

const LoginProvider = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [, executeLogin] = useMutation(loginMutation)
  const {setLoggedIn} = useLogin() || {}
  const {pushError} = useNotificationPush()

  const submitLogin = async (e: React.FormEvent) => {
    e.preventDefault()

    if (email && password) {
      executeLogin({
        username: email,
        password: password,
        grant_type: 'password',
        client_id: CLIENT_ID
      })
          .then(res => {
            if (res.error) {
              setPassword('')
              pushError(res.error.message)
            } else {
              localStorage.setItem('token', res.data?.login?.token || '');
              localStorage.setItem('refreshToken', res.data?.login?.refresh_token || '');

              const time = Math.round(new Date().getTime() / 1000)
              const expiredTimestamp = time + parseInt(res.data?.login?.expires_in || '', 10)
              localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());

              if (setLoggedIn) {
                setLoggedIn(true)
              }
            }
          })
    } else {
      if (!email) {
        pushError('Bitte gib eine Email ein')
      } else {
        pushError('Bitte gib ein Passwort ein')
      }
    }
  }

  return (
      <>
        <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            submitLogin={submitLogin}
        />
      </>
  );
}

LoginProvider.propTypes = {}

export default LoginProvider;
