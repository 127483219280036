import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { readCancelPriceQuery } from '../../../../lib/GraphQLQueries';
import { useNotificationPush } from '../../../Snackbar/SnackbarContext';
import { ServiceValuesContext } from '../../ServiceValuesContext';
import { objectIsEmpty, shouldBeHandled, statusIsCancelled } from '../../shared';
import ServiceValue from './ServiceValue';

const ServiceValueProvider = (
    {
      direction, ticketNumber, type, data, refundRate, checkAll,
      allowCancel, fetchingState, cancellationIsExecuting
    }
  ) => {
  const [checked, setChecked] = useState(false);
  const [cancelPrice, setCancelPrice] = useState({});
  const { pushError } = useNotificationPush();
  const {
    serviceValuesChecked,
    serviceValuesCheckable,
    serviceValuesTotalPrices,
    setServiceValuesTotalPrices
  } = useContext(ServiceValuesContext);

  const { setFetching } = fetchingState;

  /*
   * Query construct, fetch cancel price
   */
  const [cancelPrices] = useQuery({
    query: readCancelPriceQuery,
    pause: !checked,
    variables: {
      ServiceItems: [
        {
          ID: data.ID,
          Type: type
        }
      ],
      RefundPercentage: refundRate
    }
  });

  /*
   * Handle multidimensional state-object for cancellation
   * Add and substract items on 2nd dimension
   */
  const handleServiceValueTotalPricesObject = (
    previousServiceValuesTotalPrices,
    tTicketNumber,
    action,
    tDirection,
    tCancelPrice
  ) => {
    let newServiceValuesTotalPrices = {};

    const cancelPropertyIndex = `${tDirection}-${tCancelPrice.ID}-${tCancelPrice.Type}`;

    switch (action) {
      case 'add':
        if (typeof previousServiceValuesTotalPrices[tTicketNumber] === 'undefined') {
          newServiceValuesTotalPrices = {
            ...previousServiceValuesTotalPrices,
            ...{ [tTicketNumber]: { [cancelPropertyIndex]: tCancelPrice } }
          };
        } else {
          newServiceValuesTotalPrices = {
            ...previousServiceValuesTotalPrices,
            ...{
              [tTicketNumber]: {
                ...previousServiceValuesTotalPrices[tTicketNumber],
                ...{ [cancelPropertyIndex]: tCancelPrice } }
              }
            };
        }
        break;
      case 'remove':
        if (typeof previousServiceValuesTotalPrices[tTicketNumber] !== 'undefined') {
          if (Object.keys(previousServiceValuesTotalPrices[tTicketNumber]).length === 1) {
            const {
              [tTicketNumber]: removedTicket, ...totalTicketsWithRemovedProp
            } = previousServiceValuesTotalPrices;
            newServiceValuesTotalPrices = totalTicketsWithRemovedProp;
          } else {
            const {
              [cancelPropertyIndex]: removedPrice, ...totalPricesWithRemovedProp
            } = previousServiceValuesTotalPrices[tTicketNumber];

            newServiceValuesTotalPrices = {
              ...previousServiceValuesTotalPrices,
              ...{ [tTicketNumber]: totalPricesWithRemovedProp }
            };
          }
        }
        break;
      default:
        throw new Error('Error handling ServiceValueTotalPrices');
    }

    return newServiceValuesTotalPrices;
  };

  /*
   * Collect the checkable elements
   * Collect checkable services must not cancelled and must be a checkable line-item
   */
  useEffect(() => {
    if (!serviceValuesCheckable.current.includes(`${direction}-${data.ID}-${type}`) && shouldBeHandled(type, data) && !statusIsCancelled(data.PaymentStatus)) {
      serviceValuesCheckable.current = [...serviceValuesCheckable.current, `${direction}-${data.ID}-${type}`];
    }
  }, [data, type, direction, serviceValuesCheckable]);

  /*
   * React on check-all action
   * Check this service if its not already checked and if it is checkable
   */
  useEffect(() => {
    if (checkAll && !checked && shouldBeHandled(type, data) && !statusIsCancelled(data.PaymentStatus)) {
      setChecked(true);
    }
  }, [checkAll, checked, data, type]);

  /*
   * React on refund rate changes
   */
  useEffect(() => {
    if (checked) {
      setCancelPrice({});
    }
  }, [refundRate, checked]);

   /*
   * React on checkbox changes
   */
  useEffect(() => {
    if (checked && !serviceValuesChecked.current.includes(`${direction}-${data.ID}-${type}`)) {
      serviceValuesChecked.current = [...serviceValuesChecked.current, `${direction}-${data.ID}-${type}`];
    } else {
      serviceValuesChecked.current = serviceValuesChecked.current.filter(serviceValue => serviceValue !== `${direction}-${data.ID}-${type}`);
    }

    // Clear price if needed
    if (!checked && !objectIsEmpty(cancelPrice)) {
      setCancelPrice({});

      // Remove the price from the total
      setServiceValuesTotalPrices(
        previousServiceValuesTotalPrices => handleServiceValueTotalPricesObject(previousServiceValuesTotalPrices, ticketNumber, 'remove', direction, { ID: data.ID, Type: type })
      );
    }
  }, [checked, cancelPrice, data, direction, serviceValuesChecked, setServiceValuesTotalPrices, ticketNumber, type]);

  /*
   * React on cancel-price changes (e.g. on uncheck)
   */
  useEffect(() => {
    if (checked && !cancelPrices.fetching && objectIsEmpty(cancelPrice)) {
      if (cancelPrices.error) {
        pushError(cancelPrices.error.message.replace('[GraphQL] ', ''));
      } else if (cancelPrices.data.readCancelPrice) {
        const matchedCancelPrice = cancelPrices.data.readCancelPrice.Items.filter(
          cancelPriceElement =>
            (cancelPriceElement.ID === data.ID && cancelPriceElement.Type === type)
        ).pop();

        matchedCancelPrice.TicketNumber = ticketNumber;
        setCancelPrice(matchedCancelPrice);

        // Add the price to total
        if (typeof serviceValuesTotalPrices[`${direction}-${matchedCancelPrice.ID}-${matchedCancelPrice.Type}`] === 'undefined') {
          setServiceValuesTotalPrices(
            previousServiceValuesTotalPrices => handleServiceValueTotalPricesObject(previousServiceValuesTotalPrices, ticketNumber, 'add', direction, matchedCancelPrice)
          );
        }
      }
    }
  }, [cancelPrices, cancelPrice, checked, data, direction, pushError, serviceValuesTotalPrices, setServiceValuesTotalPrices, ticketNumber, type]);

  /*
   * React on fetching state changes
   */
  useEffect(() => {
    if (checked && cancelPrices.fetching) {
      setFetching(previousFetching => [...previousFetching, `${direction}-${data.ID}-${type}`]);
    } else {
      setFetching(previousFetching => previousFetching.filter(serviceValue => serviceValue !== `${direction}-${data.ID}-${type}`));
    }
  }, [cancelPrices.fetching, checked, data, direction, setFetching, type]);

  // Display this only if it should be considered for handling
  if (!shouldBeHandled(type, data)) {
    return null;
  }

  return (
    <ServiceValue
      key={data.ID}
      type={type}
      data={data}
      cancelPrice={cancelPrice}
      refundRate={refundRate}
      allowCancel={allowCancel}
      checkedState={{
        checked,
        setChecked
      }}
      fetching={cancelPrices.fetching}
      cancellationIsExecuting={cancellationIsExecuting}
    />
  );
};

ServiceValueProvider.propTypes = {
  direction: PropTypes.string,
  ticketNumber: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  refundRate: PropTypes.number,
  allowCancel: PropTypes.bool,
  checkAll: PropTypes.bool,
  fetchingState: PropTypes.object,
  cancellationIsExecuting: PropTypes.bool
};

export default ServiceValueProvider;
