import { FormControl, TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';
import 'moment/locale/de';
import React from 'react';
import { DATEPICKER_BACKEND_FORMAT } from '../../../../../constants';
import { useStyles } from '../styles';

const NexDatePicker = ({ isReturn, availableDates = [], date, minDate, presetFailed, disabled, setDate, presetDate }) => {
  const classes = useStyles();

  return (
      <LocalizationProvider dateAdapter={AdapterMoment} locale={'de'}>
        <FormControl fullWidth className={classes.formControlHolder} variant={'filled'} error={presetFailed}>
          <MobileDatePicker
            okText={''}
            cancelText={''}
            disableCloseOnSelect={false}
            showToolbar={false}
            label={isReturn ? 'Reisedatum Rückfahrt wählen' : 'Reisedatum wählen'}
            value={date}
            disabled={disabled}
            minDate={minDate}
            defaultCalendarMonth={availableDates.length ? moment(availableDates[0]) : null}
            orientation={'portrait'}
            shouldDisableDate={day => availableDates.indexOf(day.format(DATEPICKER_BACKEND_FORMAT)) < 0}
            maxDate={availableDates.length ? moment(availableDates[availableDates.length - 1]) : moment()}
            onChange={(newValue) => setDate(newValue)}
            renderInput={(params) => (
              <TextField variant={'filled'} fullWidth {...params} className={classes.datepicker} />
            )}
          />
        </FormControl>
      </LocalizationProvider>
  );
}

NexDatePicker.propTypes = {}

export default NexDatePicker;
