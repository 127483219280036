import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Box } from '@mui/material';
import React from 'react';

import { useStyles } from '../styles';

const AddButton = ({ addOnOptions, handleAddAddOnClick, anchorRef }) => {
  const classes = useStyles();

  if (!addOnOptions.length) {
    return (
      <span>Hinzufügen von Extras nicht möglich.</span>
    )
  }

  return (
    // {addOnOptions.length > 0 ? (
    <Box className={classes.buttonWrapper}>
      <>
        <ControlPointIcon className={classes.pointer} onClick={handleAddAddOnClick} />
        <span ref={anchorRef} onClick={handleAddAddOnClick} className={classes.pointer}>
                  {`${addOnOptions.length === 1 ? addOnOptions[0].Title : 'Extra'} hinzufügen`}
                </span>
      </>
    </Box>
  );
}

AddButton.propTypes = {}

export default AddButton;
