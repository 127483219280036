import { AppBar, Box, Grid, Toolbar } from '@mui/material';
import React from 'react';
import { useLogin, useMember } from "../../features/Login/LoginContext";
import MetaSearch from "../../features/MetaSearch";
import UserContextMenu from '../../features/UserContextMenu';
import { NavigationListType } from "../../types/NavigationListType";
import LayoutDesktopNav from '../components/LayoutDesktopNav';
import { useStyles } from '../stylesDesktop';

type PropTypes = {
  children: React.ReactNode
  logoUrl: string
  navigationList: NavigationListType[]
}

const DesktopView = ({ logoUrl, children, navigationList }: PropTypes) => {
  const classes = useStyles();
  const { isLoggedIn } = useLogin() || {}
  const { member } = useMember() || {}

  if (!isLoggedIn) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Grid container sx={{flexWrap: 'nowrap', height: '100%', position: 'relative', zIndex: 1, marginTop: member?.IsDebugMember ? 2 : 0}}>
      <AppBar
        position="fixed"
        color={'default'}
        sx={{borderRadius: 0, background: '#fff', marginTop: member?.IsDebugMember ? 2 : 0}}
      >
        <Toolbar>
          <MetaSearch />
          <Box sx={{whiteSpace: 'nowrap'}}>
            <UserContextMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Grid item className={classes.drawer}>
        <LayoutDesktopNav
          logoUrl={logoUrl}
          navigationList={navigationList.filter(item => !item.hideOnDesktop).sort((a, b) => {
            if (a.priority && !b.priority) {
              return -1
            }

            return 0
          })}
        />
      </Grid>
      <Grid item flexGrow={1} style={{paddingTop: 65}}>
        <Grid container height={'100%'} sx={{flexWrap: 'nowrap', maxWidth: 'calc(100vw - 128px)'}}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DesktopView;
