import { Dialog, Divider, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CANCELATION_OPEN_KEY } from '../../constants';
import { useTicketActions } from '../TicketDetails/TicketDetails';
import { QrCode } from "./components/QrCode";
import Release from './components/Release';

const TicketDetailsContextMenuProvider = ({ isToday, ticket, open, handleClose, anchorRef }) => {
  const { setTicketActionOptions } = useTicketActions()
  const [qrDialogOpen, setQrDialogOpen] = useState(false)

  if (!ticket) {
    return null;
  }

  const handleCancelationDialogOpen = () => {
    setTicketActionOptions(prev => ({ ...prev, [CANCELATION_OPEN_KEY]: true }))
    handleClose()
  }

  return (
    <>
      {qrDialogOpen && (
        <Dialog open onClose={() => setQrDialogOpen(false)}>
          <QrCode ticketNumber={ticket.TicketNumber} />
        </Dialog>
      )}

      <Menu
        id="ticket-context-menu"
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Release ticket={ticket} disabled={!isToday || ticket.MarkAsReleased} onClose={handleClose}>Fahrgast nicht erschienen</Release>

        <Divider />

        <MenuItem onClick={() => setQrDialogOpen(true)}>QR-Code anzeigen</MenuItem>

        <MenuItem onClick={handleCancelationDialogOpen}>(Teil-)Storno</MenuItem>
      </Menu>
    </>
  );
}

TicketDetailsContextMenuProvider.propTypes = {
  isToday: PropTypes.bool.isRequired,
  ticket: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorRef: PropTypes.object.isRequired
}

export default TicketDetailsContextMenuProvider;
