import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useCallback } from "react";
import { useMutation } from "urql";
import { deletePaybackValueMutation } from "../../../../lib/GraphQLQueries";
import { useNotificationPush } from "../../../Snackbar/SnackbarContext";

export const PaybackDeleteConfirmDialog = ({ open, onClose, paybackValueID }) => {
  const { pushSuccess, pushError } = useNotificationPush()
  const [deleteState, deletePaybackValue] = useMutation(deletePaybackValueMutation)

  const deletePayback = useCallback(() => {
    deletePaybackValue({
      PaybackID: paybackValueID
    })
      .then(res => {
        if (res.error) {
          pushError(res.error.message)
        } else {
          pushSuccess('Löschen erfolgreich')
          onClose()
        }
      })
  }, [deletePaybackValue, onClose, paybackValueID, pushError, pushSuccess])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant={'h3'}>
          Soll das Payback wirklich gelöscht werden?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          size={'small'}
          disabled={deleteState.fetching}
          onClick={onClose}
          variant={'outlined'}
        >
          Abbrechen
        </Button>
        <Button
          size={'small'}
          disabled={deleteState.fetching}
          onClick={deletePayback}
          color={'error'}
        >
          {deleteState.fetching
            ? <CircularProgress size={26} color={'inherit'} />
            : 'Ja, löschen'
          }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
