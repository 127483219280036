import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 16,
    h1: {
      marginBottom: 6,
      fontWeight: 400,
      fontSize: 32,
      lineHeight: 43 / 32,
      letterSpacing: 0
    },
    h2: {
      fontWeight: 400,
      fontSize: 22,
      lineHeight: 30 / 22,
      letterSpacing: -0.5
    },
    h3: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 25 / 18,
      letterSpacing: 0
    },
    h4: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22 / 16,
      letterSpacing: 0
    },
    h5: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 19 / 14,
      letterSpacing: 0
    },
    h6: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 17 / 12,
      letterSpacing: 0
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 22 / 16,
      letterSpacing: 0,
      color: '#858585'
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22 / 16,
      letterSpacing: 0,
      color: '#262626'
    },
  },
  palette: {
    background: {
      default: '#DCE9F5',
      trainDetails: '#AFCFED'
    },
    primary: {
      main: '#005286',
    },
    checked: '#417505',
  },
  components: {
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        variant: 'contained',
        color: 'primary',
        size: 'large'
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        outlined: {
          borderRadius: 16,
          border: 'none',
          boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: "bold"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 14,
          fontWeight: "bold"
        }
      }
    }
    // MuiPopover: {
    //   TransitionComponent: Zoom
    // }
  }
});

export default theme;
