import PropTypes from 'prop-types';
import React from 'react';

import Contact from './Contact'

const ContactProvider = ({ ticket }) => {

  return (
    <>
      <Contact
        firstname={ticket.Customer?.Firstname}
        lastname={ticket.Customer?.Lastname}
        email={ticket.Customer?.Email}
        phone={ticket.Customer?.Phone}
        street={ticket.Customer?.Street}
        zip={ticket.Customer?.Zip}
        city={ticket.Customer?.City}
        country={ticket.Customer?.Country}
        customerStatus={ticket.Customer?.CustomerStatus}
        customerLocale={ticket.Customer?.CustomerLocale}
        customerTcsMemberNumber={ticket.Customer?.TcsMemberNumber}
        memberships={ticket.Memberships || []}
      />
    </>
  );
}

ContactProvider.propTypes = {
  ticket: PropTypes.object.isRequired,
};

export default ContactProvider;
