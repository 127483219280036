import makeStyles from '@mui/styles/makeStyles';

export const useLargeStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(9),
    borderRadius: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 22 / 16,
    cursor: 'pointer',
    textAlign: 'center'
  },
  buttonSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(7),
    borderRadius: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 22 / 16,
    cursor: 'pointer',
    textAlign: 'center'
  },
  buttonChecked: {
    background: theme.palette.background.paper,
    color: '#757575',
    border: '1px solid',
    borderColor: theme.palette.checked,
  },
  buttonDisabled: {
    background: theme.palette.grey[400],
    cursor: 'inherit'
  },
  buttonInner: {
    height: theme.spacing(9),
    textAlign: 'center'
  },
  buttonInnerSmall: {
    height: theme.spacing(7),
    textAlign: 'center'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    width: 54,
    color: theme.palette.checked
  },
  title: {
    color: theme.palette.checked,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 22 / 16,
    textAlign: 'left'
  },
  time: {
    paddingTop: theme.spacing(1 / 2),
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 19 / 12
  }
}))
