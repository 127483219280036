import { Grid } from "@mui/material";
import React from 'react';
import { INVOICE_AMOUNT_LABEL, REFUND_AMOUNT_LABEL } from "../../../../../constants";
import { CHANGE_TRAIN_CONNECTION_INFO } from "../../../../../infoText";
import SummaryBox from "../../../../SummaryBox";

const Summary = ({ totalPrice, totalPriceFetching, errorMessage }) => {
  return (
    <Grid item xs={12}>
      <SummaryBox
        errorMessage={errorMessage}
        info={CHANGE_TRAIN_CONNECTION_INFO}
        prices={[
          {title: REFUND_AMOUNT_LABEL, amount: totalPrice?.Refund?.Amount, isRefund: true, fetching: totalPriceFetching},
          {title: INVOICE_AMOUNT_LABEL, amount: totalPrice?.Price?.Amount, isRefund: false, fetching: totalPriceFetching},
        ]}
      />
    </Grid>
  );
}

Summary.propTypes = {}

export default Summary;
