import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  cellMeta: {
    border: 'none',
    paddingTop: theme.spacing(1),
  },
  cell: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(1 / 2),
    paddingLeft: 0,
    borderTop: 0,
    borderRight: `1px solid ${theme.palette.background.paper}`,
    borderBottom: 0,
    borderLeft: `1px solid ${theme.palette.background.paper}`,
    '&:first-child': {
      borderLeft: 0,
      '& > div': {
        borderTopLeftRadius: theme.spacing(3 / 2),
        borderBottomLeftRadius: theme.spacing(3 / 2)
      }
    },
    '&:last-child': {
      borderRight: 0,
      '& > div': {
        borderTopRightRadius: theme.spacing(3 / 2),
        borderBottomRightRadius: theme.spacing(3 / 2)
      }
    }
  },
  text: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    background: theme.palette.background.default,
    whiteSpace: 'nowrap'
  }
}))
