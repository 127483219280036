import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { PaybackValue } from "../../../../../gql/graphql";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type PropTypes = {
  paybackValues: PaybackValue[]
  currentReservationPrice?: number
}

const PaybacksProvider = ({ currentReservationPrice, paybackValues }: PropTypes) => {
  const payback = useMemo(() => {
    return paybackValues.length > 0 ? paybackValues[0] : null
  }, [paybackValues])

  if (!payback) {
    return (
      <div>
        Kein Payback vorhanden
      </div>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>Mitgliedsnummer</Typography>
        <Typography variant={'body1'}>{payback.MembershipNumber} ({payback.Type})</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>Entgeld in EUR</Typography>
        <Typography variant={'body1'}>{payback.Amount}</Typography>
        <Typography variant={'body2'}>{currentReservationPrice}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography color={payback.TCSCashback === '--' ? 'error' : 'default'} variant={'subtitle1'}>Payback in CHF</Typography>

        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Typography color={payback.TCSCashback === '--' ? 'error' : 'default'} variant={'body1'}>{payback.TCSCashback}</Typography>
          {payback.Definite && (
            <CheckCircleIcon sx={{fontSize: 20}} color={"success"} />
          )}
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Typography color={payback.RemoteAmount === '--' ? 'error' : 'default'} variant={'subtitle1'}>Entgeld in CHF</Typography>
        <Typography color={payback.RemoteAmount === '--' ? 'error' : 'default'} variant={'body1'}>{payback.RemoteAmount}</Typography>
      </Grid>
    </Grid>
  )
}

export default PaybacksProvider;
