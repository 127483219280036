import { Check, Close } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField, Tooltip,
  Typography
} from '@mui/material'
import IconButton from "@mui/material/IconButton";
import React from 'react'
import { PAYBACK_EXCANGERATE_INFO, PAYBACK_RESUBMIT_INFO, PAYBACK_UPDATE_INFO } from "../../../../infoText";
import SummaryBox from "../../../SummaryBox";

import { useDialog } from '../CategoryBox'
import { useStyles } from './styles'

const PaybackEdit = ({ isValid, validationFetching, isNew, isChanged, changedPaybackValue, fetching, submit, deletePayback, changePaybackValue, disabled }) => {
  const classes = useStyles();
  const { onClose } = useDialog();

  return (
    <>
      <Typography variant={'h2'} gutterBottom sx={{marginBottom: 4}}>
        <strong>
          Payback bearbeiten
        </strong>
      </Typography>

      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth disabled={!isNew || disabled}>
                <InputLabel id={`membershiptype-input-label`}>{'Anbieter wählen...'}</InputLabel>
                <Select
                  labelId={`membershiptype-input-label`}
                  value={changedPaybackValue.Type || ''}
                  onChange={e => changePaybackValue('Type', e.target.value)}
                >
                  <MenuItem value={'TCS'}>TCS</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant={'filled'}
                disabled={disabled}
                label={'Mitgliedschaftsnummer'}
                value={changedPaybackValue.MembershipNumber}
                onChange={e => changePaybackValue('MembershipNumber', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}>
          {validationFetching ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {isValid
                ? <Tooltip title={'Validierung erfolgreich'}><Check color={'success'} /></Tooltip>
                : <Tooltip title={'Mitgliedsnummer ungültig'}><Close color={'error'} /></Tooltip>
              }
            </>
          )}
        </Grid>
        <Grid item xs={1}>
          <IconButton sx={{margin: 'auto 0'}} onClick={deletePayback}>
            <DeleteIcon />
          </IconButton>
        </Grid>

        {!isNew && (
          <Grid item xs={12}>
            <SummaryBox
              summary={isChanged ? PAYBACK_UPDATE_INFO : PAYBACK_RESUBMIT_INFO}
              info={PAYBACK_EXCANGERATE_INFO}
            />
          </Grid>
        )}
      </Grid>

      <DialogActions className={classes.dialogActions}>
        <Button variant={'text'} onClick={onClose}>Abbrechen</Button>
        <Button onClick={submit} disabled={disabled}>
          {fetching
            ? <CircularProgress />
            : (isChanged ? 'Neu übermitteln' : 'Preis aktualisieren')
          }
        </Button>
      </DialogActions>
    </>
  );
}

export default PaybackEdit;
