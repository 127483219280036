import React, { useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid'
import { useMutation, useQuery } from 'urql';
import {
  readMicroactionVehiclePrice,
  readTicketVehicles,
  vehicleMicroactionMutation
} from '../../../../lib/GraphQLQueries';
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'
import { useTicketNumber } from '../../TicketDetailsContext';
import { useDialog } from '../CategoryBox';

import VehiclesEdit from './VehiclesEdit';

const VehiclesEditProvider = ({ticket, boxID}) => {
  const { pushSuccess, pushError } = useNotificationPush()
  const ticketnumber = useTicketNumber()
  const { onClose } = useDialog()
  const [changedVehicles, setChangedVehicles] = useState({})
  const [customDiscount, setCustomDiscount] = useState('')
  const [fee, setFee] = useState('')
  const [changePrice, setChangePrice] = useState(true)
  const [errors, setErrors] = useState({})

  const [existingVehiclesResult] = useQuery({
    query: readTicketVehicles,
    variables: {
      Ticketnumber: ticketnumber
    },
    pause: !ticketnumber,
  })

  const [mutationState, executeVehicleMicroaction] = useMutation(vehicleMicroactionMutation)

  const isBlocked = useMemo(() => boxID && ticket?.BlockedMicroactions?.includes(boxID), [ticket, boxID])

  const submitVehicles = () => executeVehicleMicroaction({
    input: {
      ModifyAllDirections: true,
      TicketNumber: ticketnumber,
      Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
      Coupons: parseInt(customDiscount) > 0 ? [{
        "CouponType": "CustomDiscount",
        "Payload": parseInt(customDiscount, 10)
      }] : null,
      ChangePrice: changePrice,
      Vehicles: Object.values(changedVehicles).map(item => {
        const newItem = {...item, GroupID: item.VehicleGroupID}
        delete newItem['__typename']
        delete newItem['VehicleGroupID']
        delete newItem['Checked']
        return newItem
      })
    }
  }).then(res => {
    if (res.error) {
      pushError(res.error.message)
    } else {
      pushSuccess('Erfolgreich')
      onClose()
    }
  })

  const hasErrors = useMemo(() => {
    return Object.keys(errors).reduce((acc2, vehicleID) => {
      return acc2 || Object.keys(errors[vehicleID]).reduce((acc, itemKey) => {
        if (changedVehicles[vehicleID].Type !== 'Motorcycle') {
          return acc || errors[vehicleID][itemKey] !== true
        } else {
          if (['LicencePlate', 'EmptyWeight'].includes(itemKey)) {
            return acc || errors[vehicleID][itemKey] !== true
          } else {
            return acc
          }
        }
      }, false)
    }, false)
  }, [errors, changedVehicles])

  const hasChanged = useMemo(() => {
    return Object.keys(changedVehicles).reduce((acc, vehicleID) => {
      const vehicle = changedVehicles[vehicleID]
      const originalVehicle = (existingVehiclesResult.data?.readOneTicket.VehicleSlots || []).find(item => item.ID === vehicleID)

      if (originalVehicle === undefined) {
        return {
          ...acc,
          [vehicleID]: true
        }
      } else {
        return {
          ...acc,
          [vehicleID]: Object.keys(vehicle).reduce((acc2, item) => {
            return acc2 || ([
              'Type',
              'Length',
              'RoofWidth',
              'Height',
              'EmptyWeight',
              'LicensePlate',
              'Manufacturer',
              'RoofLuggage',
              'HasBikeRack',
            ].includes(item) && vehicle[item] !== originalVehicle[item])
          }, false)
        }
      }

    }, {})
  }, [changedVehicles, existingVehiclesResult.data])

  const [vehiclePriceResult] = useQuery({
    query: readMicroactionVehiclePrice,
    variables: {
      input: {
        ModifyAllDirections: true,
        TicketNumber: ticketnumber,
        Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
        Coupons: parseInt(customDiscount) > 0 ? [{
          "CouponType": "CustomDiscount",
          "Payload": parseInt(customDiscount, 10)
        }] : null,
        ChangePrice: changePrice,
        Vehicles: Object.values(changedVehicles).map(item => {
          const newItem = {...item, GroupID: item.VehicleGroupID}
          delete newItem['__typename']
          delete newItem['VehicleGroupID']
          delete newItem['Checked']
          return newItem
        })
      }
    },
    pause: !changedVehicles || hasErrors
  })

  useEffect(() => {
    setChangedVehicles(
      (existingVehiclesResult.data?.readOneTicket.VehicleSlots || [])
        .reduce((acc, item) => ({
          ...acc,
          [item.ID]: {
            ...item,
            IsExisting: true
          }
        }), {})
    )
  }, [existingVehiclesResult.data])

  const validationFunctions = useMemo(() => ({
    Length: (changedVehicle) => {
      if (changedVehicle.Length?.length) {
        if (parseInt(changedVehicle.Length, 10).toFixed(0) !== changedVehicle.Length) {
          return 'Die Fahrzeuglänge muss ohne Komma in cm angegeben werden'
        }

        if (parseInt(changedVehicle.Length, 10) > 530) {
          return 'Bei Überschreitung von 530cm bitte den Kundenservice kontaktieren.'
        }
      } else {
        return 'Bitte Fahrzeuglänge angeben'
      }

      return true
    },
    RoofWidth: (vehicle, changedVehicles) => {
      if (vehicle.RoofWidth?.length) {
        if (parseInt(vehicle.RoofWidth, 10).toFixed(0) !== vehicle.RoofWidth) {
          return 'Die Dachbreite muss ohne Komma in cm angegeben werden'
        }

        if (parseInt(vehicle.RoofWidth, 10) > 155) {
          return 'Die maximale Dachbreite beträgt 155cm'
        }
      } else {
        return 'Bitte Dachbreite angeben'
      }

      return true
    },
    Height: (vehicle, changedVehicles) => {
      if (vehicle.Height?.length) {
        if (parseInt(vehicle.Height, 10).toFixed(0) !== vehicle.Height) {
          return 'Die Fahrzeughöhe muss ohne Komma in cm angegeben werden'
        }

        if (parseInt(vehicle.RoofWidth, 10) <= 135 && parseInt(vehicle.Height, 10) > 205) {
          return 'Die maximale Dachbreite beträgt 205cm'
        }

        if (parseInt(vehicle.RoofWidth, 10) > 135 && parseInt(vehicle.Height, 10) > 196) {
          return 'Die maximale Dachbreite beträgt 196cm'
        }
      } else {
        return 'Bitte Fahrzeughöhe angeben'
      }

      return true
    },
    EmptyWeight: (vehicle, changedVehicles) => {
      if (vehicle.EmptyWeight?.length) {
        if (parseInt(vehicle.EmptyWeight, 10).toFixed(0) !== vehicle.EmptyWeight) {
          return 'Die Leergewicht muss ohne Komma in kg angegeben werden'
        }
      } else {
        return 'Bitte Leergewicht angeben'
      }

      return true
    },
    LicensePlate: (vehicle, changedVehicles) => {
      if (vehicle.LicensePlate?.length) {
        const duplicateKey = Object.keys(changedVehicles).find(el => {
          return changedVehicles[el].LicensePlate?.trim()
              .toLowerCase() === vehicle.LicensePlate.trim()
              .toLowerCase()
            && changedVehicles[el].ID !== vehicle.ID
        })

        if (duplicateKey) {
          return 'Das angegebene Fahrzeug existiert bereits'
        }
      } else {
        return 'Bitte Nummernschild angeben'
      }

      return true
    }
  }), [])

  useEffect(() => {
    let localErrors = {}
    Object.keys(changedVehicles).forEach(vehicleID => {
      localErrors = {
        ...localErrors,
        [vehicleID]: {
          Length: validationFunctions['Length'](changedVehicles[vehicleID], changedVehicles),
          RoofWidth: validationFunctions['RoofWidth'](changedVehicles[vehicleID], changedVehicles),
          Height: validationFunctions['Height'](changedVehicles[vehicleID], changedVehicles),
          EmptyWeight: validationFunctions['EmptyWeight'](changedVehicles[vehicleID], changedVehicles),
          LicensePlate: validationFunctions['LicensePlate'](changedVehicles[vehicleID], changedVehicles),
        }
      }
    })

    setErrors(localErrors)
  }, [changedVehicles, validationFunctions])

  const addVehicle = () => {
    const id = uuid()
    setChangedVehicles(prev => ({ ...prev, [`${id}`]: {ID: id, VehicleGroupID: id} }))
  }

  const deleteVehicle = (vehicleID) => {
    setChangedVehicles(prev => Object.keys(prev).reduce((acc, item) => {
      if (item !== vehicleID) {
        return { ...acc, [item]: prev[item] }
      } else {
        return acc
      }
    }, {}))
  }

  const changeVehicle = (type, id, value) => {
    setChangedVehicles(prev => ({ ...prev, [id]: { ...prev[id], [type]: value } }))
  }

  return (
    <VehiclesEdit
      fetching={vehiclePriceResult.fetching || mutationState.fetching}
      priceErrors={vehiclePriceResult.error?.message}
      errors={errors}
      hasErrors={hasErrors}
      isReturn={existingVehiclesResult.data?.readOneTicket.IsReturn}
      oppositeDirectionVehicles={existingVehiclesResult.data?.readOneTicket.OppositeDirectionTicket?.VehicleSlots || []}
      vehicles={existingVehiclesResult.data?.readOneTicket.VehicleSlots || []}
      changedVehicles={changedVehicles}
      changeVehicle={changeVehicle}
      addVehicle={addVehicle}
      deleteVehicle={deleteVehicle}
      totalPrice={vehiclePriceResult.data?.readMicroactionVehiclePrice?.TotalPrice.Amount || 0}
      vehiclePrices={vehiclePriceResult.data?.readMicroactionVehiclePrice?.Vehicles || []}
      hasChanged={hasChanged}
      submitVehicles={submitVehicles}
      customDiscount={customDiscount}
      setCustomDiscount={setCustomDiscount}
      fee={fee}
      setFee={setFee}
      changePrice={changePrice}
      setChangePrice={setChangePrice}
      isBlocked={isBlocked}
    />
  );
}

VehiclesEditProvider.propTypes = {}

export default VehiclesEditProvider;
