import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles((theme: Theme) => ({
  backLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginTop: '5px',
    marginBottom: '14px',
    color: theme.palette.primary.main,
    textDecoration: "none"
  }
}));
