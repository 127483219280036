import { Box, Chip, Grid, Menu, MenuItem, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useStyles } from '../styles';

import AddButton from './AddButton';
import LineItem from './LineItem';

const Connection = ({
                      label,
                      isCurrentTicket,
                      direction,
                      addOns = [],
                      addOnOptions,
                      addedAddOns,
                      addNewAddOn,
                      removeNewAddOn
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null)

  const submitNewAddOn = selectedAddOnToAdd => {
    addNewAddOn(direction, addOnOptions.find(item => item.ID === selectedAddOnToAdd))
    handleClose()
  }

  const filteredAddOnOptions = useMemo(() => addOnOptions
      .filter(option => {
        const amountExisting = addOns.filter(a => a.AddOnTypeID === option.ID).length
        const amountNewAddOns = Object.values(addedAddOns).filter(a => a.ID === option.ID).length

        let limit = 0
        if (direction === 'away') {
          limit = option.Limit
        } else if (direction === 'return') {
          limit = option.ReturnLimit
        } else {
          limit = option.ReturnLimit !== null ? Math.min(option.Limit, option.ReturnLimit) : option.Limit
        }

        return (amountExisting + amountNewAddOns) < limit
      }),
    [addOnOptions, addOns, addedAddOns, direction]
  )

  const handleAddAddOnClick = (event) => {
    if (filteredAddOnOptions.length === 1) {
      addNewAddOn(direction, filteredAddOnOptions[0])
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  const handleClose = () => setAnchorEl(null);

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} className={classes.border}>
          <Box className={[classes.row, classes.connectionWrapper].join(' ')}>
            <Typography component={'span'} variant={'h3'}>
              {label}:
            </Typography>
            {isCurrentTicket && (
              <Chip
                className={classes.chip}
                component={'span'}
                label={'Dieses Ticket'}
              />
            )}
          </Box>
        </Grid>
        {addOns.map(addOn => {
          if (direction === 'combined' && (!addOn.away || !addOn.return)) {
            return null;
          }

          const id = direction !== 'combined' ? addOn.ID : addOn.away.ID
          const title = direction !== 'combined' ? addOn.Title : addOn.away.Title

          return (
            <LineItem key={id} title={title} />
          )
        })}
        {Object.keys(addedAddOns).map(addOnKey => {
          let price = 0.0;
          switch (direction) {
            case 'away':
              price = addedAddOns[addOnKey].Price.Amount
              break;
            case 'return':
              price = addedAddOns[addOnKey].PriceReturn.Amount
              break;
            case 'combined':
              const awayPrice = addedAddOns[addOnKey].Price.Amount
              const returnPrice = addedAddOns[addOnKey].PriceReturn.Amount
              price = awayPrice + returnPrice
              break;
            default:
          }

          return (
            <LineItem
              key={addOnKey}
              itemKey={addOnKey}
              title={addedAddOns[addOnKey].Title}
              price={price}
              removeAction={itemKey => removeNewAddOn(direction, itemKey)}
            />
          )
        })}
        <Grid item xs={12} className={classes.border}>
          <Grid container className={classes.row} spacing={2}>
            <Grid item xs={12}>
              <AddButton
                addOns={addOns}
                addedAddOns={addedAddOns}
                addOnOptions={filteredAddOnOptions}
                handleAddAddOnClick={handleAddAddOnClick}
                anchorRef={anchorRef}
              />

              <Menu
                id="add-addon-context-menu"
                anchorEl={anchorRef.current}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {filteredAddOnOptions.map(option => (
                  <MenuItem key={option.ID} onClick={() => submitNewAddOn(option.ID)}>{option.Title}</MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Connection.propTypes = {}

export default Connection;
