import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { chipStatusClass, chipStatusLabel, moneyFormatter, splitMoneyString } from '../../shared';
import { useStyles } from '../../styles';

const FeeValue = ({ fee, isRefund, status }) => {
  const classes = useStyles();

  const feeMoneySplit = splitMoneyString(fee);

  if (parseFloat(fee) === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <TableRow sx={{height: '50px'}}>
        <TableCell width={50} style={{ width: 'auto', minWidth: '150px' }}>
          {isRefund ? 'Storno' : 'Umbuchung'}
        </TableCell>
        <TableCell width={25}>
          {moneyFormatter(feeMoneySplit.currency).format(feeMoneySplit.money)}
        </TableCell>
        <TableCell width={25}>
          <Chip
            label={chipStatusLabel('succeeded')}
            className={chipStatusClass('succeeded', classes)}
            component={'span'}
            size="small"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

FeeValue.propTypes = {
  fee: PropTypes.string,
  isRefund: PropTypes.bool,
  status: PropTypes.string
};

export default FeeValue;
