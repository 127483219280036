import React from 'react';
import { useQuery } from 'urql';
import { readTicketHistoryQuery } from '../../../../lib/GraphQLQueries'

import History from './History'

const HistoryProvider = ({ ticketNumber }) => {
  const [historyResult] = useQuery({
    query: readTicketHistoryQuery,
    pause: ticketNumber === '',
    variables: {
      Ticketnumber: ticketNumber
    },
    requestPolicy: 'network-only'
  })

  return (
    <>
      <History
        entries={historyResult.data?.readOneTicket.History || []}
      />
    </>
  );
}

HistoryProvider.propTypes = {}

export default HistoryProvider;
