import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'urql';
import { validateTicketMutation, validateVehicleSlotMutation } from '../../lib/GraphQLQueries'
import { useNotificationPush } from '../Snackbar/SnackbarContext'

import CheckInButton from './CheckInButton';

const CheckInButtonProvider = ({ ticket, readOnly = false, alternativeText, checkinType = ['person'] }) => {
  const { pushSuccess, pushError } = useNotificationPush()
  const [, executeValidateTicket] = useMutation(validateTicketMutation)
  const [, executeValidateVehicleSlot] = useMutation(validateVehicleSlotMutation)

  const validateTicket = () => {
    if (!readOnly) {
      executeValidateTicket({
        Ticketnumber: ticket.TicketNumber,
        Checked: !ticket.Checked
      }).then(res => {
        if (res.error) {
          pushError(res.error.message)
        } else {
          const checked = res.operation.variables.Checked
          pushSuccess(checked
            ? 'Check-In erfolgreich'
            : 'Check-In erfolgreich zurückgenommen'
          )
        }
      })
    }
  }

  const validateVehicleSlot = (id) => {
    const vehicleSlot = ticket.VehicleSlots.find(slot => (ticket.IsReturn ? slot.ReturnID : slot.ID) === id)
    if (!readOnly && vehicleSlot) {
      executeValidateVehicleSlot({
        ID: id,
        Checked: !vehicleSlot.Checked
      })
        .then(res => {
          if (res.error) {
            pushError(res.error.message)
          } else {
            const checked = res.operation.variables.Checked
            pushSuccess(checked
              ? 'Check-In erfolgreich'
              : 'Check-In erfolgreich zurückgenommen'
            )
          }
        })
    }
  }

  return (
    <CheckInButton
      checkinType={checkinType}
      readOnly={readOnly}
      alternativeText={alternativeText}
      ticket={ticket}
      validateTicket={validateTicket}
      validateVehicleSlot={validateVehicleSlot}
    />
  );
}

CheckInButtonProvider.propTypes = {
  ticket: PropTypes.object,
  readOnly: PropTypes.bool
}

export default CheckInButtonProvider;
