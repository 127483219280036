import React from 'react';

export const RESEND_TICKET_INFO = (
  <>
    E-Mail Versand von Bestätigung / Ticket und Rechnungsversand an den Fahrgast mit aktuellen Daten der Tickets.
    Rechnungsversand gilt für Hin- und Rückfahrt, Versand der E-Mail Bestätigungen kann unten ausgewählt werden.
  </>
)

export const CANCELLATION_INFO = (
  <>
    Mit dem Bestätigen werden die angehakten Leistungen storniert und der angegebene Betrag erstattet.
  </>
)

export const CHANGE_TRAIN_CONNECTION_INFO = (
  <>
    <strong>Änderungen gelten für gewählte Fahrtrichtung. </strong>
    Bei Preisänderung wird (Teil-)Rechnung mit Zahlungsdaten automatisch per E-Mail an Kunden verschickt.
    Die Erstattungssumme wird dem Kunden erstattet und <strong>nicht</strong> mit der neuen Rechnung verrechnet.
    Bestätigung/Ticket ist nach Abschluss aller Änderungen je Ticket/Richtung an Kunden zu senden.
  </>
)

export const CHANGE_CABIN_INFO = (
  <>
    <strong>Änderungen gelten für gewählte Fahrtrichtung. </strong>
    Bei Preisänderung wird (Teil-)Rechnung mit Zahlungsdaten automatisch per E-Mail an Kunden verschickt.
    Die Erstattungssumme wird dem Kunden erstattet und <strong>nicht</strong> mit der neuen Rechnung verrechnet.
    Bestätigung/Ticket ist nach Abschluss aller Änderungen je Ticket/Richtung an Kunden zu senden.
  </>
)

export const CHANGE_CABIN_ADD_ONS_INFO = (
  <>
    <strong>Änderungen gelten für angepasste Fahrtrichtung. </strong>
    Bei Preisänderung wird (Teil-)Rechnung mit Zahlungsdaten automatisch per E-Mail an Kunden verschickt.
    Bestätigung/Ticket ist nach Abschluss aller Änderungen je Ticket/Richtung an Kunden zu senden.
  </>
)

export const CHANGE_TICKET_ADD_ONS_INFO = (
  <>
    <strong>Änderungen gelten für Hin- und Rückfahrt. </strong>
    Bei Preisänderung wird (Teil-)Rechnung mit Zahlungsdaten automatisch per E-Mail an Kunden verschickt.
    Bestätigung/Ticket ist nach Abschluss aller Änderungen je Ticket/Richtung an Kunden zu senden.
  </>
)

export const CHANGE_VEHICLES_INFO = (
  <>
    <strong>Änderungen gelten für Hin- und Rückfahrt. </strong>
    Bei Preisänderung wird (Teil-)Rechnung mit Zahlungsdaten automatisch per E-Mail an Kunden verschickt.
    Bestätigung/Ticket ist nach Abschluss aller Änderungen je Ticket/Richtung an Kunden zu senden.
  </>
)

export const PAYBACK_RESUBMIT_INFO = (
  <>
    Beim Speichern wird das aktuelle Entgeld der Hin- und ggf. Rückfahrt an den Payback Provider neu übermittelt.
  </>
)

export const PAYBACK_UPDATE_INFO = (
  <>
    Beim Speichern wird die aktuelle Transaktion des Payback Providers storniert und neu angelegt. Darüber hinaus werden
    im Profil des Kunden hinterlegte Mitgliedschaftsinformationen aktualisiert.
  </>
)

export const PAYBACK_EXCANGERATE_INFO = (
  <>
    Bei Auszahlungen in Fremdwährungen werden hier die aktuellen Wechselkurse berücksichtigt, sodass der dem
    Kunden bei der Buchung angezeigten Auszahlungsbetrag im Folgenden abweicht.
  </>
)

export const BLOCKED_MICROACTION_HEADLINE = 'Aktion aktuell nicht verfügbar'
export const BLOCKED_MICROACTION_INFO = (
  <>
    Um diese Funktion zu verwenden, muss zunächst einmal eine Bestätigungsmail und/oder Rechnung versendet werden.
  </>
)
