import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Notes = ({ value, setCommentValue, readOnly }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id="comment"
          label="Mitteilung"
          variant="filled"
          value={value}
          onChange={e => setCommentValue(e.target.value)}
          className={classes.textfield}
          disabled={readOnly}
          fullWidth
          multiline
          minRows={3}
          helperText={'Diese Mitteilung wird dem Gast zugestellt!'}
        />
      </Grid>
    </Grid>
  );
};

Notes.propTypes = {
  value: PropTypes.string,
  setCommentValue: PropTypes.func,
  readOnly: PropTypes.bool
};

export default Notes;
