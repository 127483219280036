import { Grid, Skeleton, Table, TableBody, TableContainer, Typography } from '@mui/material';
import PropTypes from 'prop-types'
import React from 'react'

import EntityItem from './EntityItem'
import { useStyles } from './styles'
import UnplacedEntityItem from "./UnplacedEntityItem";

const Entities = ({ entityGroup }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer className={classes.container}>
          <Table className={classes.table}>
            <TableBody>
              {entityGroup.IsUnplaced ? (
                <UnplacedEntityItem />
              ) : (
                <>
                  {(entityGroup?.Entities || new Array(6).fill()).map((entity, entityIndex) => (
                    <EntityItem
                      key={entity ? `${entity}` : entityIndex}
                      wagon={entityGroup?.WagonNumber}
                      cabin={entityGroup?.CabinNumber}
                      entityType={entityGroup?.EntityType}
                      entity={entity}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Ganzes Abteil</Typography>
        {entityGroup?.IsCabinBooking === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{entityGroup.IsCabinBooking ? 'Ja' : 'Nein'}</Typography>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Preiskategorie</Typography>
        {entityGroup?.PriceCategory === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{entityGroup.PriceCategory}</Typography>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Erwachsene</Typography>
        {entityGroup?.AdultsAmount === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{entityGroup.AdultsAmount}</Typography>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography component={'div'} variant={'subtitle1'}>Kinder</Typography>
        {entityGroup?.ChildrenAmount === undefined
          ? <Skeleton width={80} />
          : <Typography variant={'body1'}>{entityGroup.ChildrenAmount}</Typography>
        }
      </Grid>
    </Grid>
  )
}

Entities.propTypes = {
  entityGroup: PropTypes.object.isRequired,
}

export default Entities
