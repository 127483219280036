import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 64,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer'
  },
  buttonInner: {
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  details: {
    paddingTop: theme.spacing(1 / 2),
    // paddingLeft: theme.spacing(1 / 2)
  },
  connection: {
    color: '#757575',
    fontSize: 14,
    lineHeight: 19 / 14
  },
  wagon: {
    paddingLeft: theme.spacing(1 / 2),
    color: '#757575',
    fontSize: 14,
    lineHeight: 19 / 14
  },
  entity: {
    color: '#757575',
    fontSize: 14,
    lineHeight: 19 / 14
  },
  icon: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  checkedButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: theme.spacing(3 / 2),
    border: `1px solid ${theme.palette.checked}`,
    color: theme.palette.checked,
  }
}))
