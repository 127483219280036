import React, { useMemo } from 'react';

import AddOns from './AddOns';
// import { useStyles } from './styles';

const AddOnsProvider = ({ ticket, bookingCollectionID }) => {
  const groupedAddOns = useMemo(() => {
    return ticket.AddOns?.reduce((acc, item) => {
      if (item) {
        const matchesGeneralAddOn = typeof bookingCollectionID === 'undefined' && item.BookingCollectionID === '0'
        const matchesTicketAddOn = item.BookingCollectionID === bookingCollectionID

        if (matchesGeneralAddOn || matchesTicketAddOn) {
          acc[item.AddOnTypeID] = {
            ...item,
            Amount: item.Amount + (acc[item.AddOnTypeID]?.Amount || 0)
          }
        }
      }

      return acc
    }, {})
  }, [ticket, bookingCollectionID])

  return (
    <AddOns
      addOns={Object.values(groupedAddOns || {})}
    />
  );
}

AddOnsProvider.propTypes = {}

export default AddOnsProvider;
