import PropTypes from 'prop-types';
import React from 'react';
import Vehicles from './Vehicles';

const VehiclesProvider = ({ ticket }) => {
  return (
    <>
      <Vehicles
        vehicles={ticket.VehicleSlots || []}
      />
    </>
  );
}

VehiclesProvider.propTypes = {
  ticket: PropTypes.object.isRequired,
}

export default VehiclesProvider;
