import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const ServiceCenterNotes = ({ value, setNoteValue, readOnly }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id="notes"
          label="Kundenservice-Notizen"
          variant="filled"
          value={value}
          onChange={e => setNoteValue(e.target.value)}
          className={classes.textfield}
          disabled={readOnly}
          fullWidth
          multiline
          minRows={3}
          helperText={'Diese Notiz ist nur für den Kundenservice einsehbar!'}
        />
      </Grid>
    </Grid>
  );
};

ServiceCenterNotes.propTypes = {
  value: PropTypes.string,
  changeValue: PropTypes.func
}

export default ServiceCenterNotes;
