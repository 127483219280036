import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';
import { personalDataMicroactionMutation } from '../../../../lib/GraphQLQueries';
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'
import { useDialog } from '../CategoryBox';

import PersonalDataEdit from './PersonalDataEdit';

const PersonalDataEditProvider = ({ customer }) => {
  const { pushSuccess, pushError } = useNotificationPush()
  const { onClose } = useDialog()
  const [customerID, setCustomerID] = useState(null)
  const [changedCustomer, setChangedCustomer] = useState({})

  const [state, personalDataMutation] = useMutation(personalDataMicroactionMutation)

  const submit = () => {
    personalDataMutation({
      ...changedCustomer,
      ID: customerID
    })
      .then(res => {
        if (res.error) {
          pushError(res.error.message)
        } else {
          pushSuccess('Persönliche Daten gespeicher')
          onClose()
        }
      })
  }

  useEffect(() => {
    if (customer) {
      setCustomerID(customer.ID)
      setChangedCustomer({
        Firstname: customer.Firstname,
        Lastname: customer.Lastname,
        Phone: customer.Phone,
        Street: customer.Street,
        Zip: customer.Zip,
        City: customer.City,
        Country: customer.Country,
        Email: customer.Email,
        CustomerStatus: customer.CustomerStatus,
        CustomerLocale: customer.CustomerLocale,
      })
    }
  }, [customer])

  const changeCustomer = useCallback((property, value) => {
    setChangedCustomer(prev => ({...prev, [property]: value}))
  }, [setChangedCustomer])

  const disabled = useMemo(() => {
    return !Object.keys(changedCustomer).reduce((acc, item) => acc || changedCustomer[item] !== customer[item], false)
  }, [changedCustomer, customer])

  if (!Object.keys(changedCustomer).length) {
    return null;
  }

  return (
    <PersonalDataEdit
      changedCustomer={changedCustomer}
      submit={submit}
      changeCustomer={changeCustomer}
      disabled={disabled || state.fetching}
      fetching={state.fetching}
    />
  );
}

PersonalDataEditProvider.propTypes = {}

export default PersonalDataEditProvider;
