import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { setCommentMutation } from '../../../../lib/GraphQLQueries';
import { useNotificationPush } from '../../../Snackbar/SnackbarContext';

import Comment from './Comment';

const CommentProvider = ({ ticketNumber, value, readOnly }) => {
  const commentValueRef = useRef(value);
  const [commentValue, setCommentValue] = useState(() => value);
  const [, setComment] = useMutation(setCommentMutation);
  const { pushSuccess, pushError } = useNotificationPush();

  useEffect(() => {
    setCommentValue(value)
  }, [value])

  useEffect(() => {
    if (commentValueRef.current !== undefined && commentValue !== value) {
      commentValueRef.current = commentValue;

      setTimeout(() => {
        if (commentValueRef.current === commentValue) {
          setComment({
            Ticketnumber: ticketNumber,
            Text: commentValue
          }).then(res => {
            if (res.error) {
              pushError(res.error.message);
            } else {
              pushSuccess('Mitteilung gespeichert');
            }
          });
        }
      }, 2500);
    } else {
      commentValueRef.current = value;
    }
  }, [commentValue, pushSuccess, pushError, setComment, ticketNumber, value]);

  return (
    <React.Fragment>
      <Comment
        value={commentValue}
        setCommentValue={setCommentValue}
        readOnly={readOnly}
      />
    </React.Fragment>
  );
};

CommentProvider.propTypes = {
  ticketNumber: PropTypes.string.isRequired,
  value: PropTypes.string,
  readOnly: PropTypes.bool
};

export default CommentProvider;
