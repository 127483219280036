import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  refundRateBox: {
    display: 'flex',
    alignItems: 'center'
  },
  refundRateSelect: {
    width: '100%',
    maxWidth: 120,
    background: '#F2F5F7',
    border: 0,
    outline: 0
  },
  refundRateHelperText: {
    width: '100%',
    maxWidth: 350,
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    fontStyle: 'italic',
    color: '#757575'
  },
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold'
    }
  },
  chip: {
    height: '24px',
    background: '#EDF3FA',
    fontSize: '14px',
    color: theme.palette.primary.main
  },
  chipStatusCheck: {
    color: '#417505',
    background: '#EAFAD7'
  },
  chipStatusDisabled: {
    color: '#757575',
    background: '#EBEBEB'
  },
  chipStatusUncheck: {
    color: '#CC3D4F',
    background: '#FADCE0'
  },
  cancellationServices: {
    // background: 'transparent',
    textTransform: "inherit",
    color: '#CC3D4F',
    fontWeight: 'bold'
  },
  disabledServices: {
    opacity: '50%'

  },
  disabledRow: {
    userSelect: 'none',
    opacity: '50%'
  },
  paperPrice: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 48,
    padding: '20px 30px',
    background: '#EDF3FA'
  },
  paperPriceTitle: {
    fontWeight: 'bold'
  },
  paperPricePriceBlack: {
    fontWeight: 'bold'
  },
  paperPricePrice: {
    color: '#CC3D4F',
    fontWeight: 'bold'
  },
  paperPriceText: {
    paddingTop: 12,
    color: '#757575',
    fontStyle: 'italic'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '@media (max-width:374px)': {
      display: 'block'
    }
  },
  button: {
    width: '50%',
    maxWidth: 280,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '@media (max-width:374px)': {
      width: '100%'
    }
  },
  contentButton: {
    width: 'auto',
    height: '26px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: '0 12px 0 0',
    borderRadius: 30,
    overflow: 'hidden',
    fontSize: 12,
    '@media (max-width:374px)': {
      width: '100%'
    }
  }
}));
