import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress, Grid, Link, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DISCOUNT_ADJUSTMENT_LABEL, REFUND_AMOUNT_LABEL } from "../../constants";
import { CANCELLATION_INFO } from "../../infoText";
import { StripeIcon } from "../../lib/icons/StripeIcon";
import SummaryBox from "../SummaryBox";
import FeeValue from './components/FeeValue';
import ServiceValue from './components/ServiceValue';
import { splitMoneyString } from './shared';
import { useStyles } from './styles';
import { usePermissions } from '../../features/Login/LoginContext'

const ServiceValues = (
  {
    allowCancel,
    ticket,
    serviceValuesChecked,
    serviceValuesCheckable,
    serviceValuesTotalPricesState,
    refundRateState,
    executeCancellation,
    cancellationIsExecuting,
    executeSyncCancelState,
    syncCancelStateTicketIsExecuting,
    closeDialog
  }) => {
  const classes = useStyles();

  const [fetching, setFetching] = useState([]);

  const [checkAll, setCheckAll] = useState(false);

  const { serviceValuesTotalPrices } = serviceValuesTotalPricesState;

  const { canAccess } = usePermissions();

  const {
    refundRate,
    setRefundRate
  } = refundRateState;

  // Define forth direction ticket
  const ticketForth = ticket?.IsReturn
    ? ticket.OppositeDirectionTicket
    : ticket;

  // Define return direction ticket
  const ticketReturn = ticket.IsReturn
    ? ticket
    : ticket.OppositeDirectionTicket;

  /*
   * Check if all checkboxes are checked
   */
  const allChecked = useCallback(() => serviceValuesCheckable.current.length === serviceValuesChecked.current.length, [serviceValuesCheckable, serviceValuesChecked])


  /*
   * Check if a fetching is in progress
   */
  const isFetching = () => (fetching.length > 0 || cancellationIsExecuting || syncCancelStateTicketIsExecuting);

  /*
   * Check if a fee applies
   */
  const ticketHasFeeAmount = (ticket) => {
    if (!ticket || !ticket.Payments) {
      return false;
    }

    for (let i = 0; i < ticket.Payments.length; i++) {
      if (parseInt(ticket.Payments[i].TotalFeePrice, 10) > 0) {
        return true;
      }
    }

    return false;
  };

  /*
   * Reset check all state
   */
  useEffect(() => {
    if (checkAll && allChecked()) {
      setCheckAll(false);
    }
  }, [checkAll, allChecked]);

  /*
   * Sum up the single price responses
   */
  const sumTotalRefund = () => {
    let totalPriceAmount = 0;
    Object.keys(serviceValuesTotalPrices).forEach(ticketId => {
      Object.keys(serviceValuesTotalPrices[ticketId]).forEach(totalPriceKey => {
        totalPriceAmount += serviceValuesTotalPrices[ticketId][totalPriceKey].Refund.Amount;
      });
    });

    return totalPriceAmount;
  };

  /*
   * Sum up the discount adjustment esponses
   */
  const sumTotalDiscountAdjustment = () => {
    let totalDiscountAdjustmentAmount = 0;
    Object.keys(serviceValuesTotalPrices).forEach(ticketId => {
      Object.keys(serviceValuesTotalPrices[ticketId]).forEach(totalPriceKey => {
        totalDiscountAdjustmentAmount += serviceValuesTotalPrices[ticketId][totalPriceKey]?.DiscountAdjustment?.Amount || 0;
      });
    });

    return totalDiscountAdjustmentAmount;
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {allowCancel && <Grid item xs={12}>
          <Typography variant="h2" gutterBottom component="div">
            Prozentsatz der Erstattungen
          </Typography>
          <Box component="div" className={classes.refundRateBox}>
            <TextField
              id="filled-select-currency"
              select
              label="Select"
              className={classes.refundRateSelect}
              value={refundRate}
              onChange={(e) => setRefundRate(e.target.value)}
              disabled={isFetching()}
            >
              <MenuItem value={0}>0%</MenuItem>
              <MenuItem value={5}>5%</MenuItem>
              <MenuItem value={10}>10%</MenuItem>
              <MenuItem value={15}>15%</MenuItem>
              <MenuItem value={20}>20%</MenuItem>
              <MenuItem value={25}>25%</MenuItem>
              <MenuItem value={30}>30%</MenuItem>
              <MenuItem value={35}>35%</MenuItem>
              <MenuItem value={40}>40%</MenuItem>
              <MenuItem value={45}>45%</MenuItem>
              <MenuItem value={50}>50%</MenuItem>
              <MenuItem value={55}>55%</MenuItem>
              <MenuItem value={60}>60%</MenuItem>
              <MenuItem value={65}>65%</MenuItem>
              <MenuItem value={70}>70%</MenuItem>
              <MenuItem value={75}>75%</MenuItem>
              <MenuItem value={80}>80%</MenuItem>
              <MenuItem value={85}>85%</MenuItem>
              <MenuItem value={90}>90%</MenuItem>
              <MenuItem value={95}>95%</MenuItem>
              <MenuItem value={100}>100%</MenuItem>
            </TextField>
            <Typography
              gutterBottom
              component="p"
              className={classes.refundRateHelperText}
            >
              Der voreingestellte Wert entspricht dem Satz laut
              Beförderungsbedingungn bei {ticket.DaysUntilDeparture} Tagen vor Fahrtantritt.
              Bitte FlexService berücksichtigen!
            </Typography>
          </Box>
        </Grid>}

        {allowCancel && (
          <Grid item xs={12} sx={{marginBottom: 1}}>
            <Typography variant="h2" gutterBottom component="div" >
              Stornierungen / Erstattungen
            </Typography>
            <Button
              className={
                !isFetching() && !allChecked() ?
                  classes.cancellationServices : classes.disabledServices
              }
              color={"inherit"}
              variant={'text'}
              disableElevation={true}
              disableRipple={true}
              fullWidth={false}
              startIcon={<DeleteIcon />}
              onClick={() => !allChecked() && !isFetching() && setCheckAll(true)}
            >
              Alles ausser Gebühren stornieren / erstatten ...
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h2" component="div" sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            Hinfahrt
            {!ticket.IsReturn && (
              <Chip
                label={'aktuelles Ticket'}
                className={classes.chip}
                component={'span'}
              />
            )}
            <Tooltip title="Zum Stripe-Kunden">
              <Link
                href={`https://dashboard.stripe.com/customers/${ticket.ReservationStripeCustomerID}`}
                sx={{display: 'inline-flex'}}
                target={'new'}
              >
                <StripeIcon />
              </Link>
            </Tooltip>
            {allowCancel && canAccess('ADMIN') && (<Button
              variant="contained"
              className={classes.contentButton}
              disabled={isFetching() || syncCancelStateTicketIsExecuting}
              onClick={() => executeSyncCancelState(ticketForth.TicketNumber)}
            >
              {<><StripeIcon/> &nbsp;Storno aus Stripe anwenden{syncCancelStateTicketIsExecuting ? <>&nbsp;<CircularProgress size={24} /></> : null}</>}
            </Button>)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="collapsible table" className={classes.table} size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell width={75} />
                  {allowCancel && <TableCell width={75} align="center">
                    Storno
                  </TableCell>}
                  <TableCell width={100}>Typ</TableCell>
                  <TableCell style={{ width: 'auto', minWidth: '250px' }}>
                    Preiskategorie
                  </TableCell>
                  <TableCell width={100}>Preis</TableCell>
                  <TableCell width={75}>Status</TableCell>
                  {allowCancel && <TableCell width={150} align="center">
                    Erstattung %
                  </TableCell>}
                  {allowCancel && <TableCell width={125} align="right">
                    Erstattung
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {ticketForth.Services?.map(service => (
                  <ServiceValue
                    key={service.ID}
                    direction={'forth'}
                    ticketNumber={ticketForth.TicketNumber}
                    type={service.ServiceType}
                    data={service}
                    refundRate={refundRate}
                    checkAll={checkAll}
                    allowCancel={allowCancel}
                    fetchingState={{
                      fetching,
                      setFetching
                    }}
                    cancellationIsExecuting={cancellationIsExecuting}
                  />))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="h2" component="div">
              Gebühren Hinfahrt
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="collapsible table" className={classes.table} size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell width={50} style={{ width: 'auto', minWidth: '150px' }}>Typ</TableCell>
                    <TableCell width={25}>Preis</TableCell>
                    <TableCell width={25}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!ticketHasFeeAmount(ticketForth) &&
                    <TableRow sx={{height: '50px'}}>
                      <TableCell width={100} style={{ width: 'auto', minWidth: '150px' }}>
                        Keine Gebühren vorhanden
                      </TableCell>
                    </TableRow>}
                  {ticketHasFeeAmount(ticketForth) && ticketForth.Payments.map(payment => (
                    <FeeValue
                      fee={payment.TotalFeePrice}
                      isRefund={splitMoneyString(payment.TotalRefundAmount).money !== '0'}
                      status={payment.PaymentStatus}
                    />))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </React.Fragment>

        {ticketReturn !== null && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom component="div">
                Rückfahrt
                {!ticket.OppositeDirectionTicket?.IsReturn && (
                  <Chip
                    label={'aktuelles Ticket'}
                    className={classes.chip}
                    component={'span'}
                  />
                )}
                {allowCancel && canAccess('ADMIN') && (<Button
                  variant="contained"
                  className={classes.contentButton}
                  disabled={isFetching() || syncCancelStateTicketIsExecuting}
                  onClick={() => executeSyncCancelState(ticketReturn.TicketNumber)}
                >
                  {<><StripeIcon/> &nbsp;Storno aus Stripe anwenden{syncCancelStateTicketIsExecuting ? <>&nbsp;<CircularProgress size={24} /></> : null}</>}
                </Button>)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="collapsible table" className={classes.table} size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={75} />
                      {allowCancel && <TableCell width={75} align="center">
                        Storno
                      </TableCell>}
                      <TableCell width={100}>Typ</TableCell>
                      <TableCell style={{ width: 'auto', minWidth: '250px' }}>
                        Preiskategorie
                      </TableCell>
                      <TableCell width={100}>Preis</TableCell>
                      <TableCell width={75}>Status</TableCell>
                      {allowCancel && <TableCell width={150} align="center">
                        Erstattung %
                      </TableCell>}
                      {allowCancel && <TableCell width={125} align="right">
                        Erstattung
                      </TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketReturn?.Services?.map(service => (
                      <ServiceValue
                        key={service.ID}
                        direction={'forth'}
                        ticketNumber={ticketReturn.TicketNumber}
                        type={service.ServiceType}
                        data={service}
                        refundRate={refundRate}
                        checkAll={checkAll}
                        allowCancel={allowCancel}
                        fetchingState={{
                          fetching,
                          setFetching
                        }}
                        cancellationIsExecuting={cancellationIsExecuting}
                      />))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom component="div">
                Gebühren Rückfahrt
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="collapsible table" className={classes.table} size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={50} style={{ width: 'auto', minWidth: '150px' }}>Typ</TableCell>
                      <TableCell width={25}>Status</TableCell>
                      <TableCell width={25}>Preis</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!ticketHasFeeAmount(ticketReturn) &&
                      <TableRow sx={{height: '50px'}}>
                        <TableCell width={100} style={{ width: 'auto', minWidth: '150px' }}>
                          Keine Gebühren vorhanden
                        </TableCell>
                      </TableRow>}
                    {ticketHasFeeAmount(ticketReturn) && ticketReturn.Payments.map(payment => (
                      <FeeValue
                        fee={payment.TotalFeePrice}
                        isRefund={splitMoneyString(payment.TotalRefundAmount).money !== '0'}
                        status={payment.PaymentStatus}
                      />))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </React.Fragment>
        )}

        {serviceValuesChecked.current.length > 0 && (
          <Grid item xs={12}>
            <SummaryBox
              prices={[
                {title: DISCOUNT_ADJUSTMENT_LABEL, amount: sumTotalDiscountAdjustment(), isRefund: true, fetching: isFetching()},
                {title: REFUND_AMOUNT_LABEL, amount: sumTotalRefund(), isRefund: true, fetching: isFetching()}
              ]}
              info={CANCELLATION_INFO}
            />
          </Grid>
        )}

        {allowCancel && (<Grid item xs={12}>
          <Box className={classes.buttonWrapper}>
            <Button
              variant="text"
              className={classes.button}
              onClick={() => {
                if (closeDialog !== null) {
                  closeDialog();
                }
              }}
            >
              Abbrechen
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              disabled={isFetching() || serviceValuesChecked.current.length === 0}
              onClick={executeCancellation}
            >
              {cancellationIsExecuting && <CircularProgress />}
              {!cancellationIsExecuting && 'Bestätigen'}
            </Button>
          </Box>
        </Grid>)}
      </Grid>
    </React.Fragment>
  );
};

ServiceValues.propTypes = {
  allowCancel: PropTypes.bool,
  ticket: PropTypes.object,
  serviceValuesChecked: PropTypes.object,
  serviceValuesCheckable: PropTypes.object,
  serviceValuesTotalPricesState: PropTypes.object,
  refundRateState: PropTypes.object,
  cancellationIsExecuting: PropTypes.bool,
  closeDialog: PropTypes.func
};

export default ServiceValues;
