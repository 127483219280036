import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Box, Chip } from "@mui/material";
import React, { useMemo } from 'react';

import { useStyles } from './styles';

const TicketNumberChip = ({ ticket, chip = true, additionalInfo }) => {
  const classes = useStyles();

  const chipClass = useMemo(() => {
    switch (ticket.TicketChipStatus) {
      case 'cancelled':
        return classes.cancelled
      case 'ticket':
      case 'released':
        return classes.ticket
      case 'partialCheckin':
        return classes.singleCheck
      case 'checkinCompleted':
        return classes.doubleCheck
      default:
        return classes.confirm
    }
  }, [ticket.TicketChipStatus, classes])

  const chipIcon = useMemo(() => {
    switch (ticket.TicketChipStatus) {
      case 'cancelled':
      case 'released':
        return <CloseOutlinedIcon className={classes.icon} />
      case 'ticket':
        return <ConfirmationNumberOutlinedIcon className={classes.icon} />
      case 'partialCheckin':
        return <DoneOutlinedIcon className={classes.icon} />
      case 'checkinCompleted':
        return <DoneAllOutlinedIcon className={classes.icon} />
      default:
        return <CircleOutlinedIcon className={classes.icon} />
    }
  }, [ticket.TicketChipStatus, classes])

  const content = useMemo(() => (
    <Box className={[
      chip ? classes.box : [classes.noChipBox].join(' ')
    ]}>
      <div style={!ticket?.IsReturn ? {fontWeight: 'bold'} : null}>{ticket?.TicketNumber}</div>
      {chipIcon}
    </Box>
  ), [chip, chipIcon, classes.box, classes.noChipBox, ticket?.IsReturn, ticket?.TicketNumber])

  if (!chip) {
    return (
      <Box className={chipClass} sx={{background: 'yellow', height: '100%', padding: '3px 11px'}}>
        {content}
        {additionalInfo}
      </Box>
    )
  }

  return (
    <>
      <Chip
        className={chipClass}
        sx={{
          cursor: 'pointer',
          ...(chip ? {} : {
            width: '100%',
            height: '100%'
          })
        }}
        label={content}
      />
    </>
  );
}

TicketNumberChip.propTypes = {}

export default TicketNumberChip;
