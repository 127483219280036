import { Box, Skeleton, Typography } from "@mui/material";
import { useQuery } from "urql";
import { readOneTicketQrCodeQuery } from "../../../../lib/GraphQLQueries"

type PropTypes = {
  ticketNumber: string
}

export const QrCode = ({ticketNumber}: PropTypes) => {
  const [ticket] = useQuery({
    query: readOneTicketQrCodeQuery,
    pause: !ticketNumber,
    variables: {
      Ticketnumber: ticketNumber
    },
  })

  if (!ticket.data?.readOneTicket?.QrCode) {
    return (
      <Skeleton width={320} height={320} />
    )
  }

  return (
    <div style={{maxWidth: 320}}>
      <img src={ticket.data?.readOneTicket?.QrCode || ''} alt={'Ticket QR Code'} />

      <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>

      <Typography>
        Dieser QR-Code ist nur für interne Test-Zwecke und darf nicht an Kunden weiter gegeben werden.
      </Typography>
      </Box>
    </div>
  )
}
