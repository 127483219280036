import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const VehicleDetail = ({ title, value }) => {
  return (
    <>
      <Typography variant={'subtitle1'}>
        {title}
      </Typography>
      <Typography variant={'body1'}>
        {value}
      </Typography>
    </>
  );
}

VehicleDetail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default VehicleDetail;
