import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { INVOICE_AMOUNT_LABEL, REFUND_AMOUNT_LABEL } from "../../../../constants";
import { CHANGE_CABIN_INFO } from "../../../../infoText";
import SummaryBox from "../../../SummaryBox";
import Cabin from './components/Cabin';

import { useStyles } from './styles';

const CabinEdit = ({ onClose,
                     ticket,
                     entityTypes,
                     changedCabins,
                     changeCabin,
                     addCabin,
                     deleteCabin,
                     hasErrors,
                     priceErrors,
                     priceFetching,
                     priceToPay,
                     priceToRefund,
                     coupons,
                     fee,
                     setFee,
                     customDiscount,
                     setCustomDiscount,
                     additionalRefundAmount,
                     setAdditionalRefundAmount,
                     changePrice,
                     setChangePrice,
                     submitChanges,
                     mutationFetchin,
                     maximumTrainCabinPersons,
                     hasChanges,
                     isBlocked
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={'h2'} gutterBottom>
        <strong>
          Buchung anpassen - Abteil / Preiskategorie
        </strong>
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={classes.vehicleTop}>
            {Object.values(changedCabins).map(entityGroup => (
              <Cabin
                initiallyOpen={Object.values(changedCabins).length <= 1}
                maximumTrainCabinPersons={maximumTrainCabinPersons}
                changeCabin={changeCabin}
                deleteCabin={deleteCabin}
                entityGroup={entityGroup}
                entityTypes={entityTypes}
                key={entityGroup.BookingCollectionID}
                isBlocked={isBlocked}
              />
            ))}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.buttonWrapper}>
            <>
              <ControlPointIcon className={classes.pointer} onClick={addCabin} />
              <span className={classes.pointer} onClick={addCabin}>
                {`Plätze / Abteil hinzufügen`}
              </span>
            </>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={'Individueller Rabatt in EUR'}
                variant={'filled'}
                value={customDiscount}
                onChange={e => setCustomDiscount(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={'Gebühr in EUR'}
                variant={'filled'}
                value={fee}
                onChange={e => setFee(e.target.value)}
              />
            </Grid>

            {Object.values(changedCabins).map((entityGroup, requestNumber) => entityGroup.WagonNumber &&  entityGroup.CabinNumber && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={'Wagen ' + entityGroup.WagonNumber + ' | ' + entityGroup.CabinNumber + ' - Erstattung / Kulanz in EUR'}
                  variant={'filled'}
                  value={additionalRefundAmount[requestNumber]?.RefundAmount || ''}
                  onChange={e => setAdditionalRefundAmount(currentState => {
                    const newValue = {[requestNumber]: { RequestNumber: requestNumber, RefundAmount: e.target.value }};
                    const newState = { ...currentState, ...newValue };
                    return newState;
                  })}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={changePrice}
                    onChange={e => setChangePrice(e.target.checked)}
                    color="primary"
                  />
                }
                label={'Aktuellen Preis für Änderungen übernehmen'}
              />
            </Grid>
          </Grid>
        </Grid>

        {!hasErrors && (
          <Grid item xs={12}>
            {priceErrors || priceFetching ? (
              priceFetching ? (
                <CircularProgress />
              ) : (
                <SummaryBox errorMessage={priceErrors.replace('[GraphQL] ', '')} />
              )
            ) : hasChanges && (
              <SummaryBox
                info={CHANGE_CABIN_INFO}
                prices={[
                  {title: REFUND_AMOUNT_LABEL, amount: priceToRefund, isRefund: true},
                  ...coupons.map(coupon => (
                    {title: coupon.Title, amount: coupon.Price.Amount, isRefund: false}
                  )),
                  {title: INVOICE_AMOUNT_LABEL, amount: priceToPay, isRefund: false},
                ]}
              />
            )}
          </Grid>
        )}
      </Grid>
      <DialogActions className={classes.dialogActions}>
        <Button variant={'text'} onClick={onClose}>Abbrechen</Button>
        <Button disabled={!hasChanges || mutationFetchin || priceFetching || hasErrors || typeof priceErrors === 'string'} onClick={submitChanges}>
          {priceFetching || mutationFetchin
            ? <CircularProgress />
            :'Bestätigen'
          }
        </Button>
      </DialogActions>
    </>
  );
}

CabinEdit.propTypes = {}

export default CabinEdit;
