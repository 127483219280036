import PropTypes from 'prop-types';
import React from 'react';

import Entities from './Entities';

const EntitiesProvider = ({ entityGroup }) => {
  console.log(entityGroup)

  if (entityGroup?.IsUnplaced) {

  }

  return (
    <>
      <Entities
        entityGroup={entityGroup}
      />
    </>
  );
}

EntitiesProvider.propTypes = {
  entityGroup: PropTypes.object.isRequired,
};

export default EntitiesProvider;
