import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import TicketItem from './components/TicketItem'

const TicketList = ({ ticketList = [], emptyString }) => {
  if (!ticketList) {
    return null
  }

  if (!ticketList.length) {
    return (
      <Typography variant={'body2'}>{emptyString}</Typography>
    )
  }

  return ticketList.map(item => {
    if (!item) {
      return null
    }

    return (
      <TicketItem key={item.TicketNumber || item} ticket={item} />
    )
  })
}

TicketList.propTypes = {
  ticketList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  ).isRequired,
}

export default TicketList
