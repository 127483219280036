import React, { useState } from 'react';
import Cabin from './Cabin';
import CabinSwitcher from './CabinSwitcher';

const CabinsProvider = ({ ticket, mobile = false }) => {
  const [currentCabin, setCurrentCabin] = useState(0)

  return (
    <>
      {ticket?.EntityGroups?.length > 1 && (
        <CabinSwitcher
          currentCabin={currentCabin}
          setCurrentCabin={setCurrentCabin}
          entityGroups={ticket?.EntityGroups || []}
        />
      )}

      <Cabin
        mobile={mobile}
        ticket={ticket}
        cabinIndex={currentCabin}
        entityGroups={ticket?.EntityGroups || []}
      />
    </>
  );
}

CabinsProvider.propTypes = {}

export default CabinsProvider;
