import { Grid, Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './styles'

const History = ({ entries = [] }) => {
  const classes = useStyles()
  return (
    <Grid container>
      {entries.map(entry => (
        <Grid item xs={12} key={entry.ID}>
          <Typography component={'div'} variant={'h5'} className={classes.date}>{entry.Timestamp.format('DD.MM.YYYY, HH:mm')}</Typography>
          <Typography component={'div'} variant={'h5'} className={classes.message}>{entry.Message}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}

History.propTypes = {}

export default History;
