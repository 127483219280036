import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  category: {
    paddingBottom: theme.spacing(3 / 2),
    color: '#4a4a4a'
  },
  button: {
    fontSize: 14,
    lineHeight: 19 / 14
  }
}));
