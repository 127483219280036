import { Typography } from '@mui/material';
import React from 'react';

const AddOns = ({ addOns }) => {
  if (!addOns.length) {
    return <Typography>Keine Extras gebucht</Typography>
  }

  return (
    <>
      <Typography variant={'subtitle1'}>Gebuchte Extras</Typography>
      {addOns.map(addOn => (
        <Typography variant={'body1'} key={addOn.ID}>
          <strong>{addOn.Amount}</strong> {addOn.Title}
        </Typography>
      ))}
    </>
  );
}

AddOns.propTypes = {}

export default AddOns;
