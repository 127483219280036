import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLogin, usePermissions } from '../../features/Login/LoginContext'
import { NavigationListType } from "../../types/NavigationListType";
import { useStyles } from './styles'

type PropTypes = {
  navigationList: NavigationListType[]
  subNavigationList: NavigationListType[]
}

const LayoutFooter = ({ navigationList, subNavigationList }: PropTypes) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { isLoggedIn } = useLogin() || {}
  const { canAccess } = usePermissions() || {}
  const navigate = useNavigate()
  const path = useLocation()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const index = navigationList.findIndex(item => {
      if (item.link === '/') {
        return path.pathname === '/';
      }

      return path.pathname.startsWith(item.link);
    });
    setValue(index >= 0 ? index : 0);
  }, [path, navigationList]);

  if (!isLoggedIn) {
    return null
  }

  return (
    <BottomNavigation
      value={value}
      className={classes.footer}
    >
      {navigationList.map(item => (
        <BottomNavigationAction
          key={item.link}
          onClick={() => navigate(item.link)}
          label={item.title}
          icon={item.icon}
          showLabel
        />
      ))}
      <BottomNavigationAction
        ref={anchorRef}
        label={'Sonstiges'}
        onClick={handleToggle}
        icon={<MoreHorizOutlinedIcon />}
        showLabel
      />
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  {subNavigationList.map(item => (!item.permission || (canAccess && canAccess(item.permission))) && (
                    <MenuItem
                      key={item.link}
                      onClick={() => {
                        setOpen(false)
                        window.scrollTo(0, 0);
                        navigate(item.link)
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </BottomNavigation>
  );
};

export default LayoutFooter;
