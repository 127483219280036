import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Typography } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLogin, useMember } from '../Login/LoginContext'
import { useStyles } from './styles'

const UserContextMenu = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const anchorRef = useRef(null)
  const { member } = useMember()
  const { isLoggedIn } = useLogin() || {}

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  if (!isLoggedIn) {
    return null
  }

  return (
    <div>
      <Box
        onClick={() => setOpen(prev => !prev)}
        ref={anchorRef}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        style={{ cursor: "pointer" }}
      >
        <KeyboardArrowDownIcon fontSize={'small'} color={'primary'} />
        <Typography
          component={'div'}
          variant={'h5'}
          color={'primary'}
          className={classes.member}
          noWrap
        >
          {member?.FirstName} {member?.Surname}
        </Typography>
      </Box>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement={'bottom-end'} style={{ zIndex: 10 }}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper sx={{borderRadius: 1}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <MenuItem onClick={e => {
                    handleClose(e)
                    navigate('/profil/')
                  }}>Passwort ändern</MenuItem>
                  <MenuItem onClick={e => {
                    handleClose(e)
                    window.location.reload()
                  }}>Seite neu laden</MenuItem>
                  <MenuItem onClick={e => {
                    handleClose(e)
                    navigate('/logout/')
                  }}>Abmelden</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

UserContextMenu.propTypes = {};

export default UserContextMenu
