import PropTypes from 'prop-types';
import React from 'react';

import { useTicketNumberUpdate } from '../../TicketDetailsContext'
import MetaData from './MetaData';

const MetaDataProvider = ({ ticket, initialOpen, hasUnplacedBookings }) => {
  const setTicketNumber = useTicketNumberUpdate()

  return (
    <MetaData
      ticket={ticket}
      initialOpen={initialOpen}
      awayTicketLink={
        ticket.IsReturn !== undefined && ticket.IsReturn
          ? () => setTicketNumber(ticket.OppositeDirectionTicket?.TicketNumber)
          : null
      }
      awayTicketNumber={
        (ticket.IsReturn !== undefined && !ticket.IsReturn)
          ? 'Dieses Ticket'
          : ticket.OppositeDirectionTicket?.TicketNumber
      }
      returnTicketLink={
        ticket.IsReturn !== undefined && !ticket.IsReturn
          ? () => setTicketNumber(ticket.OppositeDirectionTicket?.TicketNumber)
          : null
      }
      returnTicketNumber={
        (ticket.IsReturn !== undefined && ticket.IsReturn)
          ? 'Dieses Ticket'
          : ticket.OppositeDirectionTicket?.TicketNumber
      }
      hasUnplacedBookings={hasUnplacedBookings}
    />
  );
}

MetaDataProvider.propTypes = {
  ticket: PropTypes.object.isRequired
}

export default MetaDataProvider;
