import moment from 'moment';
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { setWakeUpTimeMutation } from '../../../../lib/GraphQLQueries'
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'

import WakeUpTime from './WakeUpTime'

const WakeUpTimeProvider = ({ ticket, bookingCollectionID }) => {
  const [state, saveWakeUpTime] = useMutation(setWakeUpTimeMutation)
  const { pushSuccess, pushError } = useNotificationPush()
  const [wakeupTimeState, setWakeupTimeState] = useState(null)
  const wakeupTimeRef = useRef()

  const wakeUpTime = useMemo(() => {
    const currentCabin = ticket.EntityGroups.find(item => item.BookingCollectionID === bookingCollectionID)
    return currentCabin.WakeUpTime ? moment(currentCabin.WakeUpTime) : null
  }, [ticket, bookingCollectionID])

  useEffect(() => {
    setWakeupTimeState(wakeUpTime)
    wakeupTimeRef.current = wakeUpTime
  }, [wakeUpTime])

  useEffect(() => {
    if (wakeupTimeRef.current !== undefined && wakeupTimeState?.format('HH:mm') !== wakeUpTime?.format('HH:mm')) {
      wakeupTimeRef.current = wakeupTimeState
      setTimeout(() => {
        const newTime = wakeupTimeState?.format('HH:mm')
        const nextDay = moment(ticket.StartDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
        if (wakeupTimeRef.current === wakeupTimeState) {
          saveWakeUpTime({
            Ticketnumber: ticket.TicketNumber,
            BookingCollectionID: bookingCollectionID,
            Time: wakeupTimeState ? `${nextDay} ${newTime}:00` : ''
          }).then(res => {
            if (res.error) {
              pushError(res.error.message)
            } else {
              pushSuccess('Weckzeit gespeichert')
            }
          })
        }
      }, 2000)
    } else {
      wakeupTimeRef.current = wakeupTimeState
    }
  }, [wakeupTimeState, pushSuccess, pushError, saveWakeUpTime, ticket, wakeUpTime, bookingCollectionID])

  return (
    <>
      <WakeUpTime
        time={wakeupTimeState}
        handleTimeChange={setWakeupTimeState}
        disabled={state.fetching || !ticket.CanEdit}
      />
    </>
  );
}

WakeUpTimeProvider.propTypes = {
  ticket: PropTypes.object.isRequired,
  wakeUpTime: PropTypes.object
}

export default WakeUpTimeProvider;
