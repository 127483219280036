import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

const CategoryDialog = ({ open, onClose, dialogSize, children, enableEasyClosing }) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth={dialogSize} fullWidth open={open} onClose={onClose} className={classes.dialog}>
      <Box className={classes.dialogHeader}>
        <IconButton
          disabled={!enableEasyClosing}
          onClick={onClose}
          size="large"
          className={!enableEasyClosing ? classes.disabledPointer : ''}
          style={!enableEasyClosing ? {
            pointerEvents: 'auto',
            cursor: 'not-allowed'
          } : {}}
        ><CloseIcon /></IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        {open && children}
      </DialogContent>
    </Dialog>
  );
}

CategoryDialog.propTypes = {}

export default CategoryDialog;
