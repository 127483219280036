import { Grid, Typography } from '@mui/material';
import React from 'react';

import { useStyles } from '../../styles';

const ContactDetails = ({ email, phone, street, zip, city, country, customerStatus }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>E-Mail</Typography>
        <Typography variant={'body1'}>
          <a className={classes.link} target={'_blank'} rel="noreferrer" href={`mailto:${email}`}>{email}</a>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>Telefon</Typography>
        <Typography variant={'body1'}>{phone}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>PLZ / Ort</Typography>
        <Typography variant={'body1'}>{zip} {city}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>Straße</Typography>
        <Typography variant={'body1'}>{street}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>Land</Typography>
        <Typography variant={'body1'}>{country}</Typography>
      </Grid>
      {/*{customerStatus && (*/}
        <Grid item xs={6}>
          <Typography variant={'subtitle1'}>Kundenstatus</Typography>
          <Typography variant={'body1'}>{customerStatus}</Typography>
        </Grid>
      {/*)}*/}
    </>
  );
}

ContactDetails.propTypes = {}

export default ContactDetails;
