import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3 / 2)
  },
  boxContent: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: 'none'
  },
  boxContentNoPadding: {
    borderRadius: theme.spacing(2),
    borderColor: 'transparent',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: 'none'
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: 'calc(100% - 26px)',
      margin: '13px',
      borderRadius: theme.spacing(2),
    }
  },
  dialogHeader: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    textAlign: 'right'
  },
  dialogContent: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  }
}))
