const hostname = window.location.hostname
let currentHost = ''
switch (hostname) {
  // live
  case 'dashboard.nachtexpress.de':
  case 'dashboard.bte-autoreisezug.de':
    currentHost = 'https://tickets.rdc-deutschland.de'
    break
  // staging
  case 'dashboard.staging.nachtexpress.de':
  case 'dashboard.staging.bte-autoreisezug.de':
    currentHost = 'https://tickets.staging.rdc-deutschland.de'
    break
  // staging2
  case 'dashboard.staging2.nachtexpress.de':
  case 'dashboard.test.bte-autoreisezug.de':
    currentHost = 'https://tickets.test.rdc-deutschland.de'
    break
  // dev
  default:
    currentHost = 'http://tickets.rdc-deutschland.docker:8085'
}

let currentWebsocketHost = ''
switch (hostname) {
  // live
  case 'dashboard.nachtexpress.de':
  case 'dashboard.bte-autoreisezug.de':
    currentWebsocketHost = 'wss://ws.rdc-deutschland.de'
    break
  // staging
  case 'dashboard.staging.nachtexpress.de':
  case 'dashboard.staging.bte-autoreisezug.de':
    currentWebsocketHost = 'wss://ws.staging.rdc-deutschland.de'
    break
  // staging2
  case 'dashboard.staging2.nachtexpress.de':
  case 'dashboard.test.bte-autoreisezug.de':
    currentWebsocketHost = 'wss://ws.test.rdc-deutschland.de'
    break
  // dev
  default:
    currentWebsocketHost = 'ws://socket.rdc-deutschland.docker:8085'
}

export const CURRENT_HOST = currentHost
export const CURRENT_WEBSOCKET_HOST = currentWebsocketHost

export const GRAPHQL_ENDPOINT = `${currentHost}/dashboard-app`;

export const DASHBOARDAPP_VIEW_TICKET_CONTACT_DATA = 'DASHBOARDAPP_VIEW_TICKET_CONTACT_DATA';
export const DASHBOARDAPP_EDIT_ALL_TICKETS = 'DASHBOARDAPP_EDIT_ALL_TICKETS';
export const DASHBOARDAPP_VIEW_ALL_TICKETS = 'DASHBOARDAPP_VIEW_ALL_TICKETS';
export const DASHBOARDAPP_RESEND_TICKETS = 'DASHBOARDAPP_RESEND_TICKETS';
export const DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS = 'DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS'
export const DASHBOARDAPP_EDIT_CONNECTION_TICKETS = 'DASHBOARDAPP_EDIT_CONNECTION_TICKETS';
export const DASHBOARDAPP_VIEW_CONNECTION_TICKETS = 'DASHBOARDAPP_VIEW_CONNECTION_TICKETS';
export const DASHBOARDAPP_REBOOK = 'DASHBOARDAPP_REBOOK';
export const DASHBOARDAPP_CANCEL = 'DASHBOARDAPP_CANCEL';
export const DASHBOARDAPP_SWITCH_TICKET_ENTITIES = 'DASHBOARDAPP_SWITCH_TICKET_ENTITIES';
export const DASHBOARDAPP_MOVE_TICKET = 'DASHBOARDAPP_MOVE_TICKET';
export const DASHBOARDAPP_MOVE_TICKET_AND_ENTITY = 'DASHBOARDAPP_MOVE_TICKET_AND_ENTITY';
export const DASHBOARDAPP_EDIT_ALL_TRAINS = 'DASHBOARDAPP_EDIT_ALL_TRAINS';
export const DASHBOARDAPP_VIEW_ALL_TRAINS = 'DASHBOARDAPP_VIEW_ALL_TRAINS';
export const DASHBOARDAPP_VIEW_CONNECTION_TRAINS = 'DASHBOARDAPP_VIEW_CONNECTION_TRAINS';
export const DASHBOARDAPP_VIEW_STATISTIC = 'DASHBOARDAPP_VIEW_STATISTIC';
export const DASHBOARDAPP_TOTAL_TICKET_EXPORT = 'DASHBOARDAPP_TOTAL_TICKET_EXPORT';
export const DASHBOARDAPP_VIEW_FEEDBACK = 'DASHBOARDAPP_VIEW_FEEDBACK';
export const DASHBOARDAPP_VIEW_CABINCONSULTING = 'DASHBOARDAPP_VIEW_CABINCONSULTING';
export const DASHBOARDAPP_VIEW_TRAINOVERVIEW = 'DASHBOARDAPP_VIEW_TRAINOVERVIEW';
export const DASHBOARDAPP_VIEW_PRICECATEGORY = 'DASHBOARDAPP_VIEW_PRICECATEGORY';
export const DASHBOARDAPP_MICROACTION_ADDONS = 'DASHBOARDAPP_MICROACTION_ADDONS';
export const DASHBOARDAPP_MICROACTION_VEHICLES = 'DASHBOARDAPP_MICROACTION_VEHICLES';
export const DASHBOARDAPP_MICROACTION_CABINS = 'DASHBOARDAPP_MICROACTION_CABINS';
export const DASHBOARDAPP_MICROACTION_PERSONALDATA = 'DASHBOARDAPP_MICROACTION_PERSONALDATA';
export const DASHBOARDAPP_MICROACTION_TRAINCONNECTION = 'DASHBOARDAPP_MICROACTION_TRAINCONNECTION';
export const DASHBOARDAPP_BLOCK_CABINS = 'DASHBOARDAPP_BLOCK_CABINS';
export const DASHBOARDAPP_BLOCK_WAGONS = 'DASHBOARDAPP_BLOCK_WAGONS';
export const DASHBOARDAPP_ADD_WAGONS = 'DASHBOARDAPP_ADD_WAGONS';
export const DASHBOARDAPP_VIEW_BLOCKED_CABINS = 'DASHBOARDAPP_VIEW_BLOCKED_CABINS';
export const DASHBOARDAPP_CREATE_VEHICLE_OVERRULE_CAPACITY = 'DASHBOARDAPP_CREATE_VEHICLE_OVERRULE_CAPACITY';
export const DASHBOARDAPP_VIEW_VEHICLE_OVERRULE_CAPACITY = 'DASHBOARDAPP_VIEW_VEHICLE_OVERRULE_CAPACITY';
export const DASHBOARDAPP_BLOCK_VEHICLES = 'DASHBOARDAPP_BLOCK_VEHICLES';
export const DASHBOARDAPP_VIEW_BLOCKED_VEHICLES = 'DASHBOARDAPP_VIEW_BLOCKED_VEHICLES';
export const DASHBOARDAPP_VIEW_NOTE = 'DASHBOARDAPP_VIEW_NOTE';
export const DASHBOARDAPP_EDIT_NOTE = 'DASHBOARDAPP_EDIT_NOTE';
export const DASHBOARDAPP_VIEW_SERVICECENTERNOTE = 'DASHBOARDAPP_VIEW_SERVICECENTERNOTE';
export const DASHBOARDAPP_EDIT_SERVICECENTERNOTE = 'DASHBOARDAPP_EDIT_SERVICECENTERNOTE';
export const DASHBOARDAPP_VIEW_COMMENT = 'DASHBOARDAPP_VIEW_COMMENT';
export const DASHBOARDAPP_EDIT_COMMENT = 'DASHBOARDAPP_EDIT_COMMENT';
export const DASHBOARDAPP_SEND_MESSAGE = 'DASHBOARDAPP_SEND_MESSAGE';
export const DASHBOARDAPP_SEND_FEEDBACK = 'DASHBOARDAPP_SEND_FEEDBACK';
export const DASHBOARDAPP_TRANSFORM_CONFIRMATION_TYPE = 'DASHBOARDAPP_TRANSFORM_CONFIRMATION_TYPE';
export const DASHBOARDAPP_VIEW_HISTORY = 'DASHBOARDAPP_VIEW_HISTORY';
export const DASHBOARDAPP_USE_CLIPBOARD = 'DASHBOARDAPP_USE_CLIPBOARD';
export const DASHBOARDAPP_VIEW_PAYBACKS = 'DASHBOARDAPP_VIEW_PAYBACKS';
export const DASHBOARDAPP_EDIT_PAYBACKS = 'DASHBOARDAPP_EDIT_PAYBACKS';
export const DASHBOARDAPP_TCS_EXPORT = 'DASHBOARDAPP_TCS_EXPORT';
export const DASHBOARDAPP_FEEDBACK_EXPORT = 'DASHBOARDAPP_FEEDBACK_EXPORT';


export const ADD_ON_MIRCOACTION_OPEN_KEY = 'addOnMircoOpen';
export const CANCELATION_OPEN_KEY = 'cancelationOpen';
export const QR_CODE_OPEN_KEY = 'qrCodeOpen';
export const CABINREBOOK_OPEN_KEY = 'cabinrebookOpen';
export const PERSONALDATA_MIRCOACTION_OPEN_KEY = 'personalMircoOpen';
export const VEHICLE_MIRCOACTION_OPEN_KEY = 'vehicleMircoOpen';

// box ids will be used to block the box dialog using the BlockedMicroaction ticket property
export const BOX_ID_PAYBACKS = 'paybacks'
export const BOX_ID_TRAINCONNECTION = 'trainConnection'
export const BOX_ID_TICKETADDONS = 'ticketAddOns'
export const BOX_ID_CONTACTDATA = 'contactData'
export const BOX_ID_CABINS = 'cabins'
export const BOX_ID_VEHICLES = 'vehicles'
export const BOX_ID_ENTITIES = 'entities'
export const BOX_ID_COLLECTIONADDONS = 'collectionAddOns'

export const DATEPICKER_BACKEND_FORMAT = 'YYYY-MM-DD';
export const DATEPICKER_FRONTEND_FORMAT = 'DD.MM.YYYY';

export const VEHICLE_TYPES = {
  Car: {
    title: 'Auto'
  },
  Motorcycle: {
    title: 'Motorrad'
  },
  QuadTrike: {
    title: 'Quad / Trike'
  },
  MotorcycleWithSidecar: {
    title: 'Motorrad m. Beiwagen'
  }
};

export const ROOF_BOX_LABEL = 'Dachbox'
export const BIKE_RACK_LABEL = 'Fahrradheckträger'

export const VEHICLE_CATEGORY_MAPPING = {
  'FKM': 'Motorrad',
  'FKU': 'Kat. 6 bis 10',
  'FKO1': 'Kat. 1',
  'FKO2': 'Kat. 2',
  'FKO3': 'Kat. 3',
  'FKO4': 'Kat. 4',
  'FKO5': 'Kat. 5',
}

export const ROOF_LUGGAGE_TYPES = {
  'none': 'Ohne',
  'box': 'Dachbox',
  'rack-empty': 'Dachträger leer',
  'rack-loaded': 'Dachträger beladen'
};

export const CUSTOMER_STATUS_OPTIONS = [
  {key: 'new', title: 'Neukunde'},
  {key: 'regular', title: 'Stammkunde'},
  {key: 'complain', title: 'Beschwerdekunde'},
]

export const INVOICE_AMOUNT_LABEL = 'Rechnungssumme'
export const REFUND_AMOUNT_LABEL = 'Erstattungssumme'
export const DISCOUNT_ADJUSTMENT_LABEL = 'Rabattkorrektur'

export const LOCALE_MAPPING = {
  'de_DE': 'Deutsch',
  'en_GB': 'Englisch',
}

export const SHOW_PAYMENTLINE_ITEMS = false;

// https://bitbucket.org/eformation/www.nachtexpress.de
// Make sure this matches with htdocs/app/src/DashboardApp/GraphQL/Queries/ReadLastTicketsQueryCreator.php:OFFSET_BATCH_SIZE
export const INFINITE_SCROLLER_SKIPS = 5;

export const BREAKFAST_ADDON_TYPE_IDS = ['moXHl8rAWA', '4GkgwgKl14']
export const DRINK_COMPONENT_IDS = ['4e59ekEnl4', '4e8jkvkw7Z']
export const BREAKFAST_COMPONENT_IDS = ['Zqag2Xk7oZ', 'mDq16XDa0B']
