import React from 'react';

/*
 * Generic status handle
 */
const statusIsCancelled = (status) =>
  ['cancelled', 'canceled'].includes(status);

/*
 * Display a content cancelled
 */
const populateCancelView = (content, status) => {
  if (statusIsCancelled(status)) {
    return <del>{content}</del>;
  }

  return content;
};

/*
 * Chip status class handler
 */
const chipStatusClass = (status, classes) => {
  switch (status) {
    case 'succeeded':
      return classes.chipStatusCheck;
    case 'canceled':
    case 'cancelled':
      return classes.chipStatusDisabled;
    default:
      return classes.chipStatusUncheck;
  }
};

/*
 * Chip status readable title / label
 */
const chipStatusLabel = status => {
  switch (status) {
    case 'succeeded':
      return 'Bezahlt';
    case 'canceled':
    case 'cancelled':
      return 'Storniert';
    default:
      return 'Offen';
  }
};

/*
 * Beautify object content checks
 */
const objectIsEmpty = object => Object.keys(object).length === 0;

/*
 * Split concatted money string from the backend for proper display
 */
const splitMoneyString = moneyString => ({
    money: moneyString?.split(' ')[0] || '',
    currency: moneyString?.split(' ')[1] || ''
  });

/*
 * Format money-strings
 * HINT: In case of a multi-language setup, replace the 'de-DE' with the current locale
 */
const moneyFormatter = (currency) => Intl.NumberFormat('de-DE', { style: 'currency', currency });

/*
 * Check if a booking should be displayed
 * We only want to handle main bookings for cabins and non-cabin bookings as well as all other service types
 */
const shouldBeHandled = (type, data) => {
  return true;
  // return type !== 'Booking' || (type === 'Booking' && (data.IsCabinBooking === '1' && data.IsMainForCabin === '1')) || (type === 'Booking' && data.IsCabinBooking === '0')
}

export {
  statusIsCancelled,
  populateCancelView,
  chipStatusClass,
  objectIsEmpty,
  chipStatusLabel,
  moneyFormatter,
  splitMoneyString,
  shouldBeHandled
};
