import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import { grey } from "@mui/material/colors";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  chipStatusClass,
  chipStatusLabel,
  moneyFormatter,
  objectIsEmpty,
  populateCancelView,
  splitMoneyString,
  statusIsCancelled
} from '../../shared';
import { useStyles } from '../../styles';
import PaymentLineItem from '../PaymentLineItem/PaymentLineItem';

const ServiceValue = (
    {
      type, data, refundRate, allowCancel, cancelPrice,
      checkedState, fetching, cancellationIsExecuting
    }
  ) => {
  const classes = useStyles();

  const [collapsed, setCollapsed] = useState(true);

  const { checked, setChecked } = checkedState;
  const moneySplitted = splitMoneyString(data.TotalPrice);
  const moneySplittedRefundPrice = splitMoneyString(data.RefundPrice);

  return (
    <React.Fragment>
      <TableRow
        className={statusIsCancelled(data.PaymentStatus) ? classes.disabledRow : null}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        {allowCancel && <TableCell align="center" padding="checkbox">
          {!statusIsCancelled(data.PaymentStatus) && (
            <Checkbox
              color="primary"
              checked={checked}
              disabled={(checked && fetching) || cancellationIsExecuting}
              inputProps={{
                'aria-label': 'select',
              }}
              onChange={() => setChecked(!checked)}
            />
          )}
        </TableCell>}
        <TableCell>
          {populateCancelView(
            data.Title,
            data.PaymentStatus
          )}
        </TableCell>
        <TableCell>
          {type === 'Booking' && populateCancelView(
            data.PriceCategory.Title,
            data.PaymentStatus
          )}
        </TableCell>
        <TableCell>
          {populateCancelView(
            moneyFormatter(moneySplitted.currency).format(moneySplitted.money),
            data.PaymentStatus
          )}
        </TableCell>
        <TableCell>
          <Chip
            label={chipStatusLabel(data.PaymentStatus)}
            className={chipStatusClass(data.PaymentStatus, classes)}
            component={'span'}
            size="small"
          />
        </TableCell>
        {allowCancel && <TableCell align="center">
          {checkedState.checked ? `${refundRate} %` : null}
        </TableCell>}
        {allowCancel && <TableCell align="right">
          {checkedState.checked && fetching && objectIsEmpty(cancelPrice) &&
            <CircularProgress />}
          {checkedState.checked && !fetching && !objectIsEmpty(cancelPrice) &&
            moneyFormatter(cancelPrice.Refund.Currency).format(cancelPrice.Refund.Amount)}
          {data.PaymentStatus === 'cancelled' && !checkedState.checked && moneyFormatter(moneySplittedRefundPrice.currency).format(moneySplittedRefundPrice.money)}
        </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{
          ...{paddingBottom: 0, paddingTop: 0 },
          ...(collapsed ? {borderBottom: 0} : {})
        }} colSpan={6}>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, background: grey[200], padding: 2, borderRadius: 1 }}>
              <Typography variant="h3" gutterBottom component="div">
                Rechnungen
              </Typography>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Typ</TableCell>
                    <TableCell>Re.Datum</TableCell>
                    <TableCell>Preis</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.PaymentLineItems.map(
                      (paymentLineItem, index) =>
                        (<PaymentLineItem
                          key={paymentLineItem.ID}
                          data={paymentLineItem}
                          isFirstPaymentLineItem={index === 0}
                        />)
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

ServiceValue.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  refundRate: PropTypes.number,
  allowCancel: PropTypes.bool,
  cancelPrice: PropTypes.object,
  checkedState: PropTypes.object,
  fetching: PropTypes.bool,
  cancellationIsExecuting: PropTypes.bool
};

export default ServiceValue;
