import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  link: {
    position: 'relative',
    display: 'block',
    paddingTop: 14,
    paddingRight: theme.spacing(3),
    paddingBottom: 13,
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(2),

    cursor: 'pointer',
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 20 / 14,
    letterSpacing: 0,
    textDecoration: 'none',

    '&.active': {
      background: theme.palette.background.default,

      '& > .arrow': {
        display: 'block'
      }
    },
    '& > .MuiTypography-h2': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 'bold'
    }
  },
}));
