import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&.MuiContainer-root': {
      minHeight: '100vh',
      paddingTop: theme.spacing(3),
      paddingRight: 12,
      paddingBottom: theme.spacing(15),
      paddingLeft: 12,
      '@media (min-width:1200px)': {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      }
    }
  },
  header: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
  },
  member: {
    fontWeight: '400 !important'
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));
