import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import {
  BOX_ID_COLLECTIONADDONS,
  BOX_ID_ENTITIES,
  DASHBOARDAPP_MICROACTION_ADDONS,
  DASHBOARDAPP_MOVE_TICKET,
  DASHBOARDAPP_MOVE_TICKET_AND_ENTITY
} from '../../../../constants'
import { usePermissions } from '../../../Login/LoginContext'
import AddOns from '../AddOns';
import AddOnsEdit from '../AddOnsEdit';
import Breakfast from '../Breakfast';
import CategoryBox from '../CategoryBox';
import Entities from '../Entities';
import MoveCollection from '../MoveCollection';
import WakeUpTime from "../WakeUpTime";

const Cabin = ({ticket, cabinIndex, entityGroups, mobile}) => {
  const { canAccess } = usePermissions() || {}

  const currentCabin = useMemo(() => entityGroups[cabinIndex], [entityGroups, cabinIndex])

  if (!currentCabin) {
    return null;
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12}>
            <CategoryBox
              noPadding
              initialOpen
              smallFont
              boxID={BOX_ID_ENTITIES}
              title={'Plätze'}
              dialogSize={'sm'}
              dialogContent={(canAccess(DASHBOARDAPP_MOVE_TICKET) || canAccess(DASHBOARDAPP_MOVE_TICKET_AND_ENTITY)) && (
                <MoveCollection
                  ticket={ticket}
                  collectionID={currentCabin.BookingCollectionID}
                  sameEntity
                />
              )}
            >
              <Entities
                entityGroup={currentCabin}
              />
            </CategoryBox>
          </Grid>

          <Grid item xs={12}>
            <CategoryBox
              noPadding
              initialOpen={!mobile}
              smallFont
              boxID={BOX_ID_COLLECTIONADDONS}
              title={'Extras (Abteil)'}
              dialogSize={'sm'}
              dialogContent={canAccess(DASHBOARDAPP_MICROACTION_ADDONS) && (
                <AddOnsEdit
                  requestNumber={currentCabin.RequestNumber}
                />
              )}
            >
              <AddOns
                ticket={ticket}
                bookingCollectionID={currentCabin.BookingCollectionID}
              />
            </CategoryBox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CategoryBox
              noPadding
              smallFont
              initialOpen={mobile}
              title={'Frühstück & Wecken'}
            >
              <Breakfast
                ticket={ticket}
                bookingCollectionID={currentCabin.BookingCollectionID}
              />

              <WakeUpTime
                ticket={ticket}
                bookingCollectionID={currentCabin.BookingCollectionID}
              />
            </CategoryBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Cabin.propTypes = {}

export default Cabin;
