import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { useStyles } from '../../styles';

const MetaData = ({ ticket, awayTicketLink, awayTicketNumber, returnTicketLink, returnTicketNumber, initialOpen = false, hasUnplacedBookings }) => {
  const [boxOpen, setBoxOpen] = useState(initialOpen)
  const classes = useStyles();

  useEffect(() => {
    setBoxOpen(initialOpen)
  }, [initialOpen])

  const toggleBox = useCallback(() => {
    setBoxOpen(prev => !prev)
  }, [setBoxOpen])

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'}>Ticket-Status</Typography>
              <Typography
                variant={'body1'}
                color={hasUnplacedBookings ? 'error' : 'default'}
                sx={hasUnplacedBookings ? {fontWeight: 'bold'} : {}}
              >
                {ticket.Status}
              </Typography>
            </Grid>
            {boxOpen && ticket.CanView && (
              <>
                {awayTicketNumber && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant={'subtitle1'}>Hinfahrt</Typography>
                    <Typography variant={'body1'} onClick={awayTicketLink} className={awayTicketLink && classes.link}>
                      {awayTicketNumber}
                    </Typography>
                  </Grid>
                )}
                {returnTicketNumber && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant={'subtitle1'}>Rückfahrt</Typography>
                    <Typography variant={'body1'} onClick={returnTicketLink} className={returnTicketLink && classes.link}>
                      {returnTicketNumber}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <Typography variant={'subtitle1'}>E-Mail Versand</Typography>
                  <Typography variant={'body1'}>
                    {
                      ticket?.ConfirmationEmailType === 'confirmation'
                        ? 'Bestätigung'
                        : 'Ticket'
                    }
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} style={{textAlign: 'right'}}>
          <IconButton size={'small'} onClick={toggleBox}>
            {boxOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

MetaData.propTypes = {
  ticket: PropTypes.object.isRequired
}

export default MetaData;
