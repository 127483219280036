import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "urql";
import { searchTicketSummariesQuery, searchTrainQuery } from "../../lib/GraphQLQueries";
import { useTicketNumber } from "../TicketDetails/TicketDetailsContext";
import MetaSearch from "./MetaSearch";
import MetaSearchResults from "./MetaSearchResults";

const MetaSearchProvider = () => {
  const ticketNumber = useTicketNumber()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const timeoutRef = useRef(0)
  const searchTextRef = useRef()
  const inputRef = useRef()

  const [ticketSearchResult, executeTicketSearchQuery] = useQuery({
    query: searchTicketSummariesQuery,
    pause: true,
    variables: {
      Query: searchText,
    }
  })

  const [searchTrainResult, executeTrainSearchQuery] = useQuery({
    query: searchTrainQuery,
    pause: true,
    requestPolicy: "network-only",
    variables: {
      SearchQuery: searchText,
    }
  })

  useEffect(() => {
    searchTextRef.current = searchText
    if (searchText) {
      setTimeout(() => {
        if (searchTextRef.current === searchText) {
          executeTicketSearchQuery()
          executeTrainSearchQuery()
          timeoutRef.current = 1000
        }
      }, timeoutRef.current)
    }
  }, [searchText, executeTrainSearchQuery, executeTicketSearchQuery])

  useEffect(() => {
    setOpen(true)
  }, [searchText])

  useEffect(() => {
    const onKeyDown = e => {
      if (e.key === 'Escape') {
        setOpen(false)
      }
    }
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keyup', onKeyDown);
  }, []);

  useEffect(() => {
    setOpen(false)
  }, [location, ticketNumber])

  return (
    <>
      <MetaSearch
        searchText={searchText}
        setSearchText={setSearchText}
        open={open}
        onFocus={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputRef={inputRef}
      />

      <MetaSearchResults
        open={open}
        onClose={() => setOpen(false)}
        searchText={searchText}
        tickets={ticketSearchResult.data?.readTicketSummaries}
        ticketsFetching={ticketSearchResult.fetching}
        trains={searchTrainResult.data?.readSearchTrainConnections}
        trainsFetching={searchTrainResult.fetching}
      />
    </>
  )
}

export default MetaSearchProvider;
