import { CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import uuid from 'react-uuid';
import { useMutation, useQuery } from 'urql';
import {
  cabinMicroactionMutation,
  readMicroactionCabinPriceQuery,
  readMicroActionEntityTypesQuery,
  readMicroactionMaximumPersons
} from '../../../../lib/GraphQLQueries';
import { useNotificationPush } from '../../../Snackbar/SnackbarContext';
import { useDialog } from '../CategoryBox';
import CabinEdit from './CabinEdit';

const CabinEditProvider = ({ ticket, boxID }) => {
  const [changedCabins, setChangedCabins] = useState({})
  const [fee, setFee] = useState('')
  const [customDiscount, setCustomDiscount] = useState('')
  const [additionalRefundAmount, setAdditionalRefundAmount] = useState({})
  const [changePrice, setChangePrice] = useState(true)
  const { onClose, setEnableEasyClosing } = useDialog()
  const { pushSuccess, pushError } = useNotificationPush()
  const [maximumTrainCabinPersonsResult] = useQuery({
    query: readMicroactionMaximumPersons,
    variables: {
      TicketNumber: ticket.TicketNumber
    },
    pause: !ticket
  })
  const [mutationState, executeCabinMutation] = useMutation(cabinMicroactionMutation)

  const refAdditionalRefund = useRef(additionalRefundAmount);

  const [entityTypesResult] = useQuery({
    query: readMicroActionEntityTypesQuery,
    variables: {
      TicketNumber: ticket.TicketNumber
    },
    pause: !ticket
  })

  const isBlocked = useMemo(() => boxID && ticket?.BlockedMicroactions?.includes(boxID), [ticket, boxID])

  const entityRequests = useMemo(() => {
    return Object.keys(changedCabins).reduce((acc, key) => {
      return [
        ...acc,
        {
          "ID": changedCabins[key].IsExisting ? changedCabins[key].BookingCollectionID : null,
          "Type": changedCabins[key].EntityTypeID,
          "AmountAdults": changedCabins[key].AdultsAmount,
          "AmountChildren": changedCabins[key].ChildrenAmount,
          "IsCabinBooking": changedCabins[key].CabinBooking === 'Cabin',
          "PriceCategory": changedCabins[key].PriceCategoryID,
        }
      ]
    }, [])
  }, [changedCabins])

  const hasErrors = useMemo(() => {
    return Object.values(changedCabins).reduce((acc, item) => {
      return acc
        || item.EntityTypeID === '' || typeof item.EntityTypeID === 'undefined'
        || item.CabinBooking === '' || typeof item.CabinBooking === 'undefined'
        || item.PriceCategoryID === '' || typeof item.PriceCategoryID === 'undefined'
    }, false)
  }, [changedCabins])

  const [priceResult] = useQuery({
    query: readMicroactionCabinPriceQuery,
    variables: {
      input: {
        TicketNumber: ticket.TicketNumber,
        EntityRequests: entityRequests,
        Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
        AdditionalRefundAmount: Object.values(additionalRefundAmount).map(refundElement => ({
          RequestNumber: refundElement.RequestNumber,
          RefundAmount: refundElement.RefundAmount.replace(',', '.') * 100
        })),
        Coupons: parseInt(customDiscount) > 0 ? [{
          "CouponType": "CustomDiscount",
          "Payload": parseInt(customDiscount, 10)
        }] : null,
        ChangePrice: changePrice
      }
    },
    pause: hasErrors || !entityRequests || entityRequests.length === 0
  })

  const changedProperties = useMemo(() => {
    return Object.keys(changedCabins).reduce((acc, requestNumber) => {
      return [
        ...acc,
        ...Object.keys(changedCabins[requestNumber]).reduce((acc2, property) => {
          const propertyChanges = !['Original', 'IsExisting'].includes(property) && changedCabins[requestNumber][property] !== changedCabins[requestNumber].Original[property]

          if (!propertyChanges) {
            return acc2
          }

          return [
            ...acc2,
            property
          ]
        }, [])
      ]
    }, [])
  }, [changedCabins])

  const hasChanges = useMemo(() => {
    return changedProperties.length > 0 || refAdditionalRefund.current !== additionalRefundAmount
  }, [changedProperties, additionalRefundAmount])

  useEffect(() => {
    setEnableEasyClosing(!hasChanges)
  }, [hasChanges, setEnableEasyClosing])


  useEffect(() => {
    setChangedCabins(
      (ticket.EntityGroups || [])
        .reduce((acc, item) => {
          const entry = {
            TicketNumber: item.TicketNumber,
            WagonNumber: item.WagonNumber,
            CabinNumber: item.CabinNumber,
            BookingCollectionID: item.BookingCollectionID,
            EntityTypeID: item.EntityTypeID,
            AdultsAmount: item.AdultsAmount,
            ChildrenAmount: item.ChildrenAmount,
            CabinBooking: item.IsCabinBooking ? 'Cabin' : 'Single',
            PriceCategoryID: item.PriceCategoryID?.toString() || '',
          }

          return {
            ...acc,
            [item.BookingCollectionID]: {
              ...entry,
              Original: {
                ...entry,
                EntityType: item.EntityType,
                IsCabinBooking: item.IsCabinBooking,
                PriceCategory: item.PriceCategory,
              },
              IsExisting: true
            }
          }
        }, {})
    )
  }, [ticket])

  const entityTypes = useMemo(() => entityTypesResult.data?.readMicroactionEntityTypes || [], [entityTypesResult.data])

  const addCabin = () => {
    const id = uuid()
    setChangedCabins(prev => ({
      ...prev,
      [`${id}`]: {
        BookingCollectionID: id,
        TicketNumber: ticket.TicketNumber,
        Original: {},
        AdultsAmount: null,
        ChildrenAmount: null,
        CabinBooking: '',
        PriceCategoryID: ''
      }
    }))
  }

  const deleteCabin = (cabinID) => {
    setChangedCabins(prev => Object.keys(prev).reduce((acc, item) => {
      if (item !== cabinID) {
        return { ...acc, [item]: prev[item] }
      } else {
        return acc
      }
    }, {}))
  }

  const changeCabin = (property, id, value) => {
    setChangedCabins(prev => {
      const newCabin = {
        ...prev,
        [id]: {
          ...prev[id],
          [property]: value,
        }
      }

      const bookingTypes = (entityTypes.find(item => item.ID === newCabin[id].EntityTypeID)?.BookingOptions || []).map(item => item.Code)

      let adultsAmount = newCabin[id].AdultsAmount
      if (property === 'CabinBooking' && newCabin[id].CabinBooking !== 'Cabin') {
        if (newCabin[id].AdultsAmount < newCabin[id].Original.AdultsAmount) {
          adultsAmount = newCabin[id].Original.AdultsAmount
        }
      }

      let childrenAmount = newCabin[id].ChildrenAmount
      if (property === 'CabinBooking' && newCabin[id].CabinBooking !== 'Cabin') {
        if (newCabin[id].ChildrenAmount < newCabin[id].Original.ChildrenAmount) {
          childrenAmount = newCabin[id].Original.ChildrenAmount
        }
      }

      return {
        ...newCabin,
        [id]: {
          ...newCabin[id],
          AdultsAmount: adultsAmount,
          ChildrenAmount: childrenAmount,
          CabinBooking: bookingTypes.includes(newCabin[id].CabinBooking) ? newCabin[id].CabinBooking : ''
        }
      }
    })
  }

  const submitChanges = () => {
    executeCabinMutation({
      input: {
        TicketNumber: ticket.TicketNumber,
        EntityRequests: entityRequests,
        Fee: parseInt(fee) > 0 ? parseInt(fee) : 0,
        AdditionalRefundAmount: Object.values(additionalRefundAmount).map(refundElement => ({
          RequestNumber: refundElement.RequestNumber,
          RefundAmount: refundElement.RefundAmount.replace(',', '.') * 100
        })),
        Coupons: parseInt(customDiscount) > 0 ? [{
          "CouponType": "CustomDiscount",
          "Payload": parseInt(customDiscount, 10)
        }] : null,
        ChangePrice: changePrice
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Änderungen erfolgreich übernommen')
        onClose()
      }
    })
  }

  if (!maximumTrainCabinPersonsResult.data || !entityTypesResult.data) {
    return <CircularProgress />
  }

  return (
    <CabinEdit
      onClose={onClose}
      ticket={ticket}
      changedCabins={changedCabins}
      changeCabin={changeCabin}
      addCabin={addCabin}
      deleteCabin={deleteCabin}
      entityTypes={entityTypes}
      submitChanges={submitChanges}
      hasErrors={hasErrors}
      mutationFetchin={mutationState.fetching}
      priceErrors={priceResult.error?.message}
      priceFetching={priceResult.fetching || priceResult.stale}
      priceToPay={priceResult.data?.readMicroactionCabinPrice?.Price.Amount}
      priceToRefund={priceResult.data?.readMicroactionCabinPrice?.Refund.Amount}
      coupons={priceResult.data?.readMicroactionCabinPrice?.CouponValue || []}
      fee={fee}
      setFee={setFee}
      customDiscount={customDiscount}
      setCustomDiscount={setCustomDiscount}
      additionalRefundAmount={additionalRefundAmount}
      setAdditionalRefundAmount={setAdditionalRefundAmount}
      changePrice={changePrice}
      setChangePrice={setChangePrice}
      maximumTrainCabinPersons={maximumTrainCabinPersonsResult.data?.readMicroactionMaximumPersons || 0}
      hasChanges={hasChanges}
      isBlocked={isBlocked}
    />
  );
}

CabinEditProvider.propTypes = {}

export default CabinEditProvider;
