import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from "react-router-dom";

import { useStyles } from './styles'

type PropTypes = {
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  submitLogin: (event: React.FormEvent) => void
}

const Login = ({ email, setEmail, password, setPassword, submitLogin }: PropTypes) => {
  const classes = useStyles()

  return (
    <>
      <Grid container sx={{justify: 'center', alignItems: 'center'}}>
        <Grid item sm={12}>
          <Typography variant="h1" component="h1" style={{marginTop: '10px'}}>
            Anmelden
          </Typography>
          <form className={classes.root} method={'post'} noValidate autoComplete="off" onSubmit={submitLogin}>
            <FormControl className={classes.formControl}>
              <TextField
                variant={'filled'}
                value={email}
                className={classes.input}
                label="Email"
                onChange={e => {setEmail(e.target.value)}}
                fullWidth
                autoFocus
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant={'filled'}
                value={password}
                className={classes.input}
                type={'password'}
                label="Passwort"
                fullWidth
                onChange={e => {setPassword(e.target.value)}}
              />
            </FormControl>

            <Link to={'/password-reset/'} className={classes.resetLink}>Ich habe mein Passwort vergessen</Link>
            <FormControl className={classes.formControl}>
              <Button
                className={classes.input}
                type={'submit'}
              >
                Anmelden
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

Login.propTypes = {}

export default Login;
