import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { setNoteMutation } from '../../../../lib/GraphQLQueries'
import { useNotificationPush } from '../../../Snackbar/SnackbarContext'

import Notes from './Notes';

const NotesProvider = ({ ticketNumber, value, readOnly }) => {
  const noteValueRef = useRef(value)
  const [noteValue, setNoteValue] = useState(() => value)
  const [, setNote] = useMutation(setNoteMutation)
  const { pushSuccess, pushError } = useNotificationPush()

  useEffect(() => {
    setNoteValue(value)
  }, [value])

  useEffect(() => {
    if (noteValueRef.current !== undefined && noteValue !== value) {
      noteValueRef.current = noteValue
      setTimeout(() => {
        if (noteValueRef.current === noteValue) {
          setNote({
            Ticketnumber: ticketNumber,
            Text: noteValue
          }).then(res => {
            if (res.error) {
              pushError(res.error.message)
            } else {
              pushSuccess('Notizen gespeichert')
            }
          })
        }
      }, 2500)
    } else {
      noteValueRef.current = noteValue
    }
  }, [noteValue, pushSuccess, pushError, ticketNumber, value, setNote])

  // useEffect(() => {
  //   if (noteValueRef.current === noteValue) {
  //     setNoteValue(value)
  //   }
  // }, [noteValue, value])

  return (
    <>
      <Notes
        value={noteValue}
        setNoteValue={setNoteValue}
        readOnly={readOnly}
      />
    </>
  );
}

NotesProvider.propTypes = {
  ticketNumber: PropTypes.string.isRequired,
  value: PropTypes.string
}

export default NotesProvider;
