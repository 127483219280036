import { CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useStyles } from "./styles";

type CabinType = {
  key: string
  value: React.ReactNode
  icon: React.ReactNode
}

type PropTypes = {
  fetching: boolean
  cabins: CabinType[]
  selectedCabin?: any
  setSelectedCabin: any
}

export const TicketMoveSelect = ({fetching, cabins, selectedCabin, setSelectedCabin}: PropTypes) => {
  const classes = useStyles()

  let previousWagon: any = undefined;

  return fetching ? (
    <CircularProgress />
  ) : (
    <FormControl variant="filled" className={classes.select}>
      <InputLabel id="new-entities-label">Neue Plätze</InputLabel>
      <Select
        labelId="new-entities-label"
        id="new-entities"
        value={selectedCabin}
        onChange={e => setSelectedCabin(e.target.value)}
        // className={classes.select}
      >
        {cabins.map(entry => {
          const returnEntry = [
            <MenuItem key={entry.key} value={entry.key}>
              {entry.icon}
              {entry.value}
            </MenuItem>,
          ];
          const currentWagon = entry.key.split('-')[0]
          if (previousWagon !== undefined && previousWagon !== currentWagon) {
            returnEntry.unshift(<Divider />)
          }
          previousWagon = currentWagon
          return returnEntry
        })}
      </Select>
    </FormControl>
  )
}
