import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  noChipBox: {
    display: "flex",
    alignItems: "start",
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    gap: 8,
  },
  icon: {
    fontSize: 18
  },
  confirm: {
    color: '#262626',
    background: '#EAEDF0'
  },
  ticket: {
    color: '#005286',
    background: '#D7ECFA'
  },
  cancelled: {
    color: '#7c2929',
    background: '#eed6d6'
  },
  singleCheck: {
    color: '#0C753D',
    background: '#E1FAEC'
  },
  doubleCheck: {
    color: '#417505',
    background: '#EAFAD7'
  },
}));
