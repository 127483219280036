import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid, Hidden, Paper, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS, DASHBOARDAPP_RESEND_TICKETS } from "../../constants";
import CheckInButton from '../CheckInButton';
import { usePermissions } from "../Login/LoginContext";
import TicketDetailsContextMenu from '../TicketDetailsContextMenu';
import MetaData from './components/MetaData';
import ResendTicketEmail from "./components/ResendTicketEmail";
import { useStyles } from './styles';
import DesktopView from './view/DesktopView';
import MobileView from './view/MobileView';

const TicketActionsContext = createContext();

export const useTicketActions = () => useContext(TicketActionsContext)

const TicketDetails = ({ ticket, handleBack, switchToRebookedTicket, reloadTicket }) => {
  const classes = useStyles()
  const { canAccess } = usePermissions() || {}
  const [ticketActionOptions, setTicketActionOptions] = useState({})
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const hasUnplacedBookings = useMemo(() => {
    return (ticket.EntityGroups || []).findIndex(group => group.IsUnplaced) >= 0
  }, [ticket.EntityGroups])

  if (!ticket) {
    return null;
  }

  const bannerClasses = [classes.banner]

  if (ticket.Checked) {
    if(!ticket.IsCancelled) {
      bannerClasses.push(classes.bannerActive)
    }
  }

  if (ticket.IsCancelled) {
    bannerClasses.push(classes.bannerCancelled)
  }

  if (ticket.CanEdit && canAccess && canAccess(DASHBOARDAPP_RESEND_TICKETS)) {
    bannerClasses.push(classes.bannerHigher)
  }

  const handleSettingsClick = (event) => {
    if (ticket.CanEdit) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleResendEmailClick = (event) => {
    if (ticket.CanEdit) {
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isRebooked = ticket.RebookedTicket?.TicketNumber
  const isToday = ticket.StartDate === moment().format('YYYY-MM-DD')
    || ticket.StartDate === moment().subtract(1, 'day').format('YYYY-MM-DD')
  const departureTime = ticket.StartDate ? moment(ticket.StartDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''
  const arrivalTime = ticket.StartDate ? moment(ticket.StartDate, 'YYYY-MM-DD').add(1, 'day').format('DD.MM.YYYY') : ''
  const wakeUpTime = ticket.WakeUpTime ? moment(ticket.WakeUpTime, 'YYYY-MM-DD HH:mm:ss') : null

  return (
    <TicketActionsContext.Provider value={{ticketActionOptions, setTicketActionOptions}}>
      <Grid container className={bannerClasses.join(' ')}>
        <Grid item>
          <div>
            {ticket.TicketNumber}
          </div>
          {ticket.CanEdit && (
            <Hidden smUp>
              <Box sx={{marginLeft: '-2px', paddingTop: '5px'}}>
                <ResendTicketEmail
                  ticket={ticket}
                  reloadTicket={reloadTicket}
                />
              </Box>
            </Hidden>
          )}
        </Grid>
        {ticket.CanEdit && (
          <Hidden smDown>
            <Grid item className={classes.resendEmailIcon}>
              {!hasUnplacedBookings && (
                <Grid container onClick={handleResendEmailClick}>
                  <ResendTicketEmail
                    ticket={ticket}
                    reloadTicket={reloadTicket}
                  />
                </Grid>
              )}
            </Grid>
          </Hidden>
        )}
        {ticket.CanEdit && (
          <Grid item className={classes.editIcon}>
            <Grid container ref={anchorRef} onClick={handleSettingsClick}>
                <SettingsIcon />
            </Grid>
          </Grid>
        )}
        <Grid item className={classes.closeIcon}>
          <Grid container onClick={handleBack}>
            <CloseIcon />
          </Grid>
        </Grid>
      </Grid>

      <TicketDetailsContextMenu
        isToday={isToday}
        ticket={ticket}
        open={Boolean(anchorEl)}
        handleClose={handleClose}
        anchorRef={anchorRef}
      />
      <Grid container>
        <Grid item className={classes.main}>
          <MetaData
            ticket={ticket}
            initialOpen={!isMobile}
            hasUnplacedBookings={hasUnplacedBookings}
          />

          <Box className={classes.boxArea}>
            <Hidden mdUp>
              <MobileView
                isRebooked={isRebooked}
                ticket={ticket}
                isToday={isToday}
                departureTime={departureTime}
                arrivalTime={arrivalTime}
                wakeUpTime={wakeUpTime}
              />
            </Hidden>
            <Hidden mdDown>
              <DesktopView
                isRebooked={isRebooked}
                ticket={ticket}
                isToday={isToday}
                departureTime={departureTime}
                arrivalTime={arrivalTime}
                wakeUpTime={wakeUpTime}
                addBottomPadding={canAccess(DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS)}
              />
            </Hidden>
          </Box>

        {/*  {!ticket.CanView && ticket.CanView !== null && (*/}
        {/*    <Typography variant={'body2'}>Zum Lesen dieses Tickets fehlen leider die Berechtigungen.</Typography>*/}
        {/*  )}*/}
        </Grid>
      </Grid>
      <Hidden lgUp={(!canAccess(DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS) && !isToday)}>
        <Paper elevation={6} square sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          maxHeight: ticket.CanEdit && canAccess && (canAccess(DASHBOARDAPP_RESEND_TICKETS) || canAccess(DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS))
            ? 'calc(100% - 90px)'
            : 'calc(100% - 64px)',
          overflow: 'auto',
          borderRadius: 0,
          zIndex: 1
        }}>
        {ticket.CanView !== false && (
          <>
            {ticket.CanEdit && !isRebooked && ticket.EntityGroups && ticket.EntityGroups.length > 0 && (
              <Grid container className={classes.bottomActions}>
                <Grid item xs={9} md={4}>
                  <CheckInButton
                    ticket={ticket}
                    checkinType={['person', 'vehicle']}
                    size={'large'}
                    readOnly={!ticket.CanEdit || (!canAccess(DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS) && !isToday)}
                    alternativeText={(!canAccess(DASHBOARDAPP_CHECKIN_TICKETS_AFTERWARDS) && !isToday) ? 'Check In nur am Fahrtag möglich' : ''}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        </Paper>
      </Hidden>
    </TicketActionsContext.Provider>
  );
}

TicketDetails.propTypes = {
  ticket: PropTypes.object,
  handleBack: PropTypes.func
}

export default TicketDetails;
