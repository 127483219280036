import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  row: {
    padding: `${theme.spacing(3)} 0`,
  },
  border: {
    borderBottom: `1px solid #CCCCCC`,
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  price: {
    textAlign: 'right'
  },
  pointer: {
    cursor: 'pointer',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '& span': {
      paddingLeft: theme.spacing(1)
    }
  },
  priceString: {
    paddingLeft: theme.spacing(1)
  },
  formControl: {
    minWidth: 120,
  },
  summaryBox: {
    borderRadius: theme.spacing(2),
    background: '#EDF3FA',
    padding: theme.spacing(4),
  },
  summaryBoxWarning: {
    borderRadius: theme.spacing(2),
    background: '#f8e8b5',
    color: '#3f3927',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    marginBottom: theme.spacing(2)
  },
  summaryBoxError: {
    borderRadius: theme.spacing(2),
    background: '#efb4b4',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  summaryPriceHeadline: {
    display: 'flex',
    fontWeight: 'bold',
    flexWrap: 'wrap'
  },
  summaryPrice: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    textAlign: 'right'
  },
  dialogActions: {
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingLeft: 0,
  },
  connectionWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    height: '24px',
    marginLeft: theme.spacing(2),
    background: '#D7EDFA',
    fontSize: '14px',
    color: theme.palette.primary.main
  },
  vehicleTop: {
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[400]}`
  },
  vehicleDivider: {
    padding: `${theme.spacing(2)} 0`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  infoLight: {
    color: '#757575'
  }
}));
