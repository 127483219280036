import { Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, Grid, Typography } from '@mui/material';
import TextField from "@mui/material/TextField";
import React from 'react';
import { INVOICE_AMOUNT_LABEL } from "../../../../constants";
import { CHANGE_CABIN_ADD_ONS_INFO, CHANGE_TICKET_ADD_ONS_INFO } from "../../../../infoText";
import SummaryBox from "../../../SummaryBox";

import { useDialog } from '../CategoryBox';
import Connection from './components/Connection';
import { useStyles } from './styles';

const AddOnsEdit = ({
                      currentDirection,
                      awayAddOns,
                      returnAddOns,
                      combinedAddOns,
                      addOnOptions,
                      addedAwayAddOns,
                      addedReturnAddOns,
                      addedCombinedAddOns,
                      addNewAddOn,
                      removeNewAddOn,
                      submitNewAddOns,
                      newAddOnTotalPrice,
                      hasNewItems,
                      hasReturnConnection,
                      fetching,
                      isTicketAddOns,
                      customDiscount,
                      setCustomDiscount,
                      fee,
                      setFee,
                      changePrice,
                      setChangePrice,
}) => {
  const classes = useStyles();
  const { onClose } = useDialog();

  return (
    <>
      <Typography variant={'h2'} gutterBottom>
        <strong>
          Buchung anpassen - Extras
        </strong>
      </Typography>

      <Grid container spacing={4}>
        <Connection
          addNewAddOn={addNewAddOn}
          removeNewAddOn={removeNewAddOn}
          addOnOptions={addOnOptions.filter(item => !item.HideAway && (!item.GroupJourneys || !hasReturnConnection))}
          label={'Hinfahrt'}
          direction={'away'}
          addOns={awayAddOns}
          addedAddOns={addedAwayAddOns}
          isCurrentTicket={currentDirection === 'away'}
        />

        {hasReturnConnection && (
          <>
            <Connection
              addNewAddOn={addNewAddOn}
              removeNewAddOn={removeNewAddOn}
              addOnOptions={addOnOptions.filter(item => !item.HideReturn && !item.GroupJourneys)}
              label={'Rückfahrt'}
              direction={'return'}
              addOns={returnAddOns}
              addedAddOns={addedReturnAddOns}
              isCurrentTicket={currentDirection === 'return'}
            />

            <Connection
              addNewAddOn={addNewAddOn}
              removeNewAddOn={removeNewAddOn}
              addOnOptions={addOnOptions.filter(item => item.GroupJourneys && !item.HideAway && !item.HideReturn)}
              label={'Kombiniert'}
              direction={'combined'}
              addOns={combinedAddOns}
              addedAddOns={addedCombinedAddOns}
            />
          </>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={'Individueller Rabatt in EUR'}
                variant={'filled'}
                value={customDiscount}
                onChange={e => setCustomDiscount(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={'Gebühr in EUR'}
                variant={'filled'}
                value={fee}
                onChange={e => setFee(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={changePrice}
                    onChange={e => setChangePrice(e.target.checked)}
                    color="primary"
                  />
                }
                label={'Aktuellen Preis für Änderungen übernehmen'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SummaryBox
            prices={[
              {title: INVOICE_AMOUNT_LABEL, amount: newAddOnTotalPrice}
            ]}
            info={isTicketAddOns ? CHANGE_TICKET_ADD_ONS_INFO : CHANGE_CABIN_ADD_ONS_INFO}
          />
        </Grid>
      </Grid>
      <DialogActions className={classes.dialogActions}>
        <Button variant={'text'} onClick={onClose}>Abbrechen</Button>
        <Button
          onClick={submitNewAddOns}
          disabled={newAddOnTotalPrice === undefined || !hasNewItems || fetching}
        >
          {fetching ? <CircularProgress /> : 'Bestätigen'}
        </Button>
      </DialogActions>
    </>
  );
}

AddOnsEdit.propTypes = {}

export default AddOnsEdit;
