import { WarningAmberOutlined } from "@mui/icons-material";
import { Chip } from '@mui/material';
import React, { useMemo } from 'react';
import { DASHBOARDAPP_RESEND_TICKETS } from '../../../../constants'
import { usePermissions } from '../../../Login/LoginContext'
import { useStyles } from "../../styles";

import ResendTicketEmailDialog from './ResendTicketEmailDialog'

const ResendTicketEmail = ({ dialogOpen, setDialogOpen, sendAwayConfirmation, setSendAwayConfirmation, sendReturnConfirmation, setSendReturnConfirmation, confirmResendTicketEmail, emailPending, pendingInvoiceItems, fetching, hasOppositeDirectionTicket }) => {
  const classes = useStyles()
  const { canAccess } = usePermissions() || {};

  const buttonLabel = useMemo(() => {
    if (pendingInvoiceItems.length > 0) {
      return 'Rechnung senden';
    }

    return 'Bestätigung senden'
  }, [pendingInvoiceItems])

  return (
    <>
      {canAccess(DASHBOARDAPP_RESEND_TICKETS) && (
        <Chip
          label={buttonLabel}
          component={'span'}
          size="small"
          clickable
          icon={(emailPending || pendingInvoiceItems.length > 0) ? <WarningAmberOutlined color={'error'} /> : <></>}
          onClick={() => setDialogOpen(true)}
          className={[
            classes.resendEmailChip,
            ...((emailPending || pendingInvoiceItems.length > 0) ? [classes.resendEmailChipWarning] : [])
          ].join(' ')}
        />
      )}
      {canAccess(DASHBOARDAPP_RESEND_TICKETS) && (
        <ResendTicketEmailDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          sendAwayConfirmation={sendAwayConfirmation}
          setSendAwayConfirmation={setSendAwayConfirmation}
          sendReturnConfirmation={sendReturnConfirmation}
          setSendReturnConfirmation={setSendReturnConfirmation}
          confirmResendTicketEmail={confirmResendTicketEmail}
          pendingInvoiceItems={pendingInvoiceItems}
          fetching={fetching}
          hasOppositeDirectionTicket={hasOppositeDirectionTicket}
        />
        )}
    </>
  )
}

ResendTicketEmail.propTypes = {}

export default ResendTicketEmail;
