import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationListType } from "../../types/NavigationListType";

import { useStyles } from '../stylesDesktop';

type PropTypes = {
  item: NavigationListType
}

const LayoutDesktopNavItem = ({ item }: PropTypes) => {
  const classes = useStyles();
  const location = useLocation()

  const isActiveLink = useMemo(() => {
    if (item.link.length === 1) {
      return location.pathname === item.link
    }

    return location.pathname.startsWith(item.link)
  }, [location, item])

  return (
    <ListItem key={item.link}>
      <Link
        to={item.link}
        className={[classes.link, isActiveLink ? 'active' : ''].join(' ')}
      >
        <ListItemButton disableTouchRipple className={classes.button}>
          <ListItemIcon className={classes.icon}>
            {item.icon}
          </ListItemIcon>
          <ListItemText className={classes.text}>
            {item.desktopTitle || item.title}
          </ListItemText>
        </ListItemButton>
      </Link>
    </ListItem>
  )
}

export default LayoutDesktopNavItem;
