import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from "react";
import theme from "../theme";

type PropTypes = {
  children: React.ReactNode
}

export const Theme = ({ children }: PropTypes) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
)
