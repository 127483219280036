import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 96,
    zIndex: 1299,
    '& .MuiBottomNavigationAction-label': {
      color: theme.palette.primary.main,
      fontSize: 14,
      lineHeight: 19 / 14
    },
    '& .MuiSvgIcon-root': {
      width: 40,
      height: 40,
      padding: theme.spacing(1),
      borderRadius: 12,
      fill: theme.palette.primary.main
    },
    '& .Mui-selected .MuiSvgIcon-root': {
      backgroundColor: theme.palette.primary.main,
      fill: 'white'
    }
  }
}));
