import { Undo } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { BIKE_RACK_LABEL, ROOF_BOX_LABEL, VEHICLE_TYPES } from '../../../../../constants';
import { useStyles } from '../styles';

const Vehicle = ({vehicleID, vehicle, changedVehicle, changedVehicles, changeVehicle, deleteVehicle, index, errors, setEdited, isBlocked}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(() => !changedVehicle.IsExisting)

  return (
    <div className={classes.vehicleDivider}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton onClick={() => setOpen(prev => !prev)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span>
              {`${index + 1}. ${VEHICLE_TYPES[vehicle?.Type]?.title || 'Fahrzeug'} ${vehicle ? `(${vehicle?.LicensePlate})` : ''}`}
            </span>
            {!changedVehicle.IsExisting && (
              <IconButton sx={{marginLeft: 'auto'}} onClick={() => deleteVehicle(vehicleID)}>
                <Undo />
              </IconButton>
            )}
          </Box>
        </Grid>
        {open && (
          <>
            <Grid item xs={12} sm={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id={`vehicle-type-input-label`}>{'Fahrzeugkategorie'}</InputLabel>
                <Select
                  labelId={`vehicle-type-input-label`}
                  value={changedVehicle.Type || ''}
                  disabled={isBlocked && changedVehicle.IsExisting}
                  onChange={e => {
                    setEdited(true);
                    changeVehicle('Type' , vehicle?.ID || vehicleID, e.target.value)
                  }}
                >
                  <MenuItem value={'Car'}>{'Auto'}</MenuItem>
                  <MenuItem value={'Motorcycle'}>{'Motorrad'}</MenuItem>
                  <MenuItem value={'QuadTrike'}>{'Quad / Trike'}</MenuItem>
                  <MenuItem value={'MotorcycleWithSidecar'}>{'Motorrad mit Beiwagen'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                variant={'filled'}
                label={'Amtliches Kennzeichen'}
                value={changedVehicle.LicensePlate || ''}
                onChange={e => {
                  setEdited(true);
                  changeVehicle('LicensePlate' , vehicle?.ID || vehicleID, e.target.value)
                }}
                error={errors?.LicensePlate !== true}
                helperText={<>{errors?.LicensePlate}</>}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant={'filled'}
                label={'Hersteller'}
                value={changedVehicle.Manufacturer || ''}
                onChange={e => {
                  setEdited(true);
                  changeVehicle('Manufacturer' , vehicle?.ID || vehicleID, e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant={'filled'}
                label={'Modell'}
                value={changedVehicle.VehicleModel || ''}
                onChange={e => {
                  setEdited(true);
                  changeVehicle('VehicleModel' , vehicle?.ID || vehicleID, e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant={'filled'}
                label={'Fahrzeuglänge (cm)'}
                value={changedVehicle.Length || ''}
                disabled={isBlocked && changedVehicle.IsExisting}
                onChange={e => {
                  setEdited(true);
                  changeVehicle('Length' , vehicle?.ID || vehicleID, e.target.value)
                }}
                error={errors?.Length !== true}
                helperText={<>{errors?.Length}</>}
              />
            </Grid>

            {changedVehicle.Type !== 'Motorcycle' && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant={'filled'}
                  label={'Fahrzeughöhe (cm)'}
                  value={changedVehicle.Height || ''}
                  disabled={isBlocked && changedVehicle.IsExisting}
                  onChange={e => {
                    setEdited(true);
                    changeVehicle('Height' , vehicle?.ID || vehicleID, e.target.value)
                  }}
                  error={errors?.Height !== true}
                  helperText={<>{errors?.Height}</>}
                />
              </Grid>
            )}

            {changedVehicle.Type !== 'Motorcycle' && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant={'filled'}
                  label={'Dachbreite (cm)'}
                  value={changedVehicle.RoofWidth || ''}
                  disabled={isBlocked && changedVehicle.IsExisting}
                  onChange={e => {
                    setEdited(true);
                    changeVehicle('RoofWidth' , vehicle?.ID || vehicleID, e.target.value)
                  }}
                  error={errors?.RoofWidth !== true}
                  helperText={<>{errors?.RoofWidth}</>}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant={'filled'}
                label={'Leergewicht (kg)'}
                value={changedVehicle.EmptyWeight || ''}
                disabled={isBlocked && changedVehicle.IsExisting}
                onChange={e => {
                  setEdited(true);
                  changeVehicle('EmptyWeight' , vehicle?.ID || vehicleID, e.target.value)
                }}
                error={errors?.EmptyWeight !== true}
                helperText={<>{errors?.EmptyWeight}</>}
              />
            </Grid>

            {changedVehicle.Type !== 'Motorcycle' && (
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      checked={changedVehicle.RoofLuggage === 'box'}
                      disabled={isBlocked && changedVehicle.IsExisting}
                      onChange={e => {
                        setEdited(true);
                        changeVehicle('RoofLuggage' , vehicle?.ID || vehicleID, e.target.checked ? 'box' : 'none')
                      }}
                    />
                  } label={ROOF_BOX_LABEL} />
                </FormGroup>
              </Grid>
            )}

            {changedVehicle.Type !== 'Motorcycle' && (
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      checked={changedVehicle.HasBikeRack}
                      disabled={isBlocked && changedVehicle.IsExisting}
                      onChange={e => {
                        setEdited(true);
                        changeVehicle('HasBikeRack' , vehicle?.ID || vehicleID, e.target.checked)
                      }}
                    />
                  } label={BIKE_RACK_LABEL} />
                </FormGroup>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}

Vehicle.propTypes = {}

export default Vehicle;
