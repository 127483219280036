import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';

const ReleaseDialog = ({ open, setOpen, confirmTicketRelease, markAsReleased }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="ticket-release"
      aria-describedby="ticket-release-description"
    >
      <DialogContent>
        <Typography variant={'h2'} gutterBottom>
          Fahrgast nicht erschienen
        </Typography>
        <DialogContentText id="ticket-release-description">
          {markAsReleased ? (
            <>
              Bitte bestätigen Sie, dass der Fahrgast nicht erschienen ist. Die Plätze bleiben für den Fahrgast reserviert.
            </>
          ):(
            <>
              Sollen die reservierten Plätze freigegeben werden? Sie können anschließend von anderen
              Kunden gebucht werden. Der Eigentümer dieses Tickets verliert das Anrecht auf seine Plätze.
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          size={'small'}
          color={'primary'}
        >
          Abbrechen
        </Button>
        <Button
          onClick={confirmTicketRelease}
          variant={'outlined'}
          size={'small'}
          color="primary"
        >
          {markAsReleased ? 'Fahrgast nicht erschienen' : 'Plätze freigeben'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReleaseDialog.propTypes = {}

export default ReleaseDialog
