import { Backdrop, Box, CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TicketList from "../TicketList";
import { useStyles } from "./styles";

const MetaSearchResults = ({ open, onClose, searchText, tickets, trains, ticketsFetching, trainsFetching }) => {
  const classes = useStyles()

  return (
    <Backdrop open={open} onClick={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          paddingTop: 8,
          paddingRight: 12,
          paddingBottom: 2,
          paddingLeft: 23,
          background: 'white',
          boxShadow: '1px 1px 2px #ccc',
          overflowY: 'auto',
          maxHeight: '100%'
        }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: 64,
          background: 'white',
        }}>

        </Box>
        <Typography variant={'h3'} gutterBottom>Suchergebnisse</Typography>

        {searchText && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
            overflowX: 'scroll',
            paddingTop: 1
          }}>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Typography variant={'h5'} gutterBottom>Tickets</Typography>
                {ticketsFetching && (
                  <CircularProgress />
                )}
                {!ticketsFetching && tickets && tickets.length > 0 && (
                  <div>
                    <TicketList
                      ticketList={tickets || []}
                      emptyString={tickets && tickets.length === 0 ? 'Keine Suchergebnisse gefunden' : null}
                    />
                  </div>
                )}
              </Grid>

              <Grid item lg={6}>
                <Typography variant={'h5'} gutterBottom>Züge</Typography>
                {trainsFetching && (
                  <CircularProgress />
                )}
                {!trainsFetching && trains && trains.length > 0 && (
                  <div>
                    {trains?.map(trainConnection => (
                      <Link
                        to={`/zuege/${trainConnection?.ID}/`}
                        className={classes.link}
                      >
                        <Typography component={'div'} variant={'h2'}>
                          {trainConnection.TrainTitle}
                        </Typography>
                        <div>
                          {
                            trainConnection
                              ? `${trainConnection.DepartureStation} - ${trainConnection.ArrivalStation}`
                              : <Skeleton />
                          }
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
                {!trainsFetching && trains && trains.length === 0 && (
                  <Typography variant={'body2'}>{'Keine Suchergebnisse gefunden'}</Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Backdrop>
  )
}

export default MetaSearchResults;
